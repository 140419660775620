import { OPEN_SIDEBAR, CLOSE_SIDEBAR } from "../Utils/constants";

const initialState = false;

const Sidebar  = (state = initialState, {type, payload}) => {
    switch(type) {
        case OPEN_SIDEBAR : {
            return true;
        }
        case CLOSE_SIDEBAR : {
            return false;
        }
        default : {
            return state;
        }
    }
}

export default Sidebar;