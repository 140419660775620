import React, { useCallback, useState, useRef } from "react";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import { BsCalendarDate } from "react-icons/bs";
import { AiOutlineCaretDown } from "react-icons/ai";
import {
  getAllRoomType,
  getbookingRates,
  getRatePlan,
} from "../../core/Services/Data/EaseRoom";
import { DownloadTableExcel } from "react-export-table-to-excel";

import { useEffect } from "react";
const Rates = ({ user }) => {
  // console.log("user", user)
  const [states, setStates] = useState({
    roomType: "",
  });
  const [rates, setRates] = useState([]);
  const tableRef = useRef(null);
  const [roomTypes, setRoomTypes] = useState([]);
  // const roomTypes = [
  //   {
  //     id: 1,
  //     name: "2BHK Graden view apartment",
  //   },
  //   {
  //     id: 2,
  //     name: "1BHK Apartment",
  //   }
  // ];

  const reportTypes = [
    {
      id: 1,
      name: "Not Allocated Dates",
    },
    {
      id: 2,
      name: "MLOs",
    },
    {
      id: 3,
      name: "Rates Health",
    },
  ];

  const [filters, setFilters] = useState({
    startDate: new Date(),
    endDate: new Date(),
    roomType: 0,
    reportType: 1,
  });

  const [openDateDropdown, setOpenDateDropdown] = useState(false);
  const [openRoomTypeDropdown, setOpenRoomTypeDropdown] = useState(false);
  const [openReportTypeDropdown, setOpenReportTypeDropdown] = useState(false);
  const dateRangeHandler = useCallback(
    (event) => {
      setFilters({
        ...filters,
        startDate: event.selection.startDate,
        endDate: event.selection.endDate,
      });
      setOpenDateDropdown(false);
    },
    [filters]
  );

  const dateDropdownHandler = useCallback(() => {
    setOpenDateDropdown(!openDateDropdown);
    setOpenRoomTypeDropdown(false);
    setOpenReportTypeDropdown(false);
  }, [openDateDropdown]);

  const roomTypeDropdownHandler = useCallback(() => {
    setOpenRoomTypeDropdown(!openRoomTypeDropdown);
    setOpenDateDropdown(false);
    setOpenReportTypeDropdown(false);
  }, [openRoomTypeDropdown]);

  const reportTypeDropdownHandler = useCallback(() => {
    setOpenReportTypeDropdown(!openReportTypeDropdown);
    setOpenDateDropdown(false);
    setOpenRoomTypeDropdown(false);
  }, [openReportTypeDropdown]);

  useEffect(() => {
    const payload = {
      Header: {
        Username: user?.live?.easeRoom?.email,
        Password: user?.live?.easeRoom?.password,
      },
      HotelId: user?.live?.easeRoom?.secret,
    };
    getAllRoomType(payload).then(({ data }) => {
      console.log(data);
      const roomtype = [];
      data.EaseRoomType.map((ele) => {
        roomtype.push({ id: ele.RoomTypeId, name: ele.RoomTypeName });
      });
      setRoomTypes([...roomtype]);
    });

    const payload1 = {
      startDate: moment(filters.startDate).format("YYYY-MM-DD"),
      endDate: moment(filters.endDate).add(1,"days").format("YYYY-MM-DD"),
    };
    console.log(filters.startDate, filters.endDate);

    getbookingRates(payload1).then(async ({ data }) => {
      console.log(data, "bookingRates");
      console.log(filters.roomType);
      const rooms1 = [];
      data.result.forEach((rooms) => {
        console.log(rooms);
        rooms?.data.forEach((room) => {
          if (room._id == filters.roomType) {
            rooms1.push(room);
          }
        });
      });

      setRates(rooms1);
    });

    // console.log("filters Date of all", filters);
  }, [filters]);
  console.log(filters);
  const dateRangeContainerRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (
      dateRangeContainerRef.current &&
      !dateRangeContainerRef.current.contains(event.target)
    ) {
      // Clicked outside the date range picker, update state or perform actions
      console.log("Clicked outside");
      setOpenDateDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);



  return (
    <>
      <section className="card br-8 filters-section">
        <div className="filters">
          <div className="date filter">
            <label>Select Date*</label>
            <div
              className={openDateDropdown ? "dropdown active" : "dropdown"}
              onClick={() => setOpenDateDropdown(true)}
              ref={dateRangeContainerRef}
              style={{ cursor: "pointer" }}
            >
              <p>
                <span>
                  {moment(filters.startDate).format("DD MMMM Y")} -{" "}
                  {moment(filters.endDate).format("DD MMMM Y")}
                </span>
                <button>
                  <BsCalendarDate size={16} />
                </button>
              </p>
              <DateRangePicker
                className="date-range"
                ranges={[{ ...filters, key: "selection" }]}
                onChange={dateRangeHandler}
                // showMonthAndYearPickers={false}
              />
            </div>
          </div>
          <div className="filter">
            <label>Room Type</label>
            <div
              className={
                openRoomTypeDropdown
                  ? "dropdown active cursor-pointer"
                  : "dropdown cursor-pointer"
              }
              onClick={roomTypeDropdownHandler}
            >
              <p>
                <span>{states.roomType ? states.roomType.name : ""}</span>
                {/* <span>{roomTypes[filters.roomType - 1].name}</span> */}
                <button>
                  <AiOutlineCaretDown size={16} />
                </button>
              </p>
              <ul>
                {roomTypes.map((room) => (
                  <li key={room.id}>
                    <button
                      className={room.id === filters.roomType ? "active" : ""}
                      onClick={(e) => {
                        // console.log("rooom", room);
                        e.preventDefault();
                        const state = { ...states };
                        state.roomType = room;
                        console.log(states);
                        setStates({ ...state });
                        setFilters({ ...filters, roomType: room.id });
                        setOpenRoomTypeDropdown(false);
                      }}
                    >
                      <div>
                        <img alt="" src="/Assets/Images/Reports/room.png" />
                      </div>
                      <span>{room.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* <div className="filter">
            <label>Report Type</label>
            <div
              className={
                openReportTypeDropdown ? "dropdown active" : "dropdown"
              }
            >
              <p>
                <span>{reportTypes[filters.reportType - 1].name}</span>
                <button onClick={reportTypeDropdownHandler}>
                  <AiOutlineCaretDown size={16} />
                </button>
              </p>
              <ul>
                {reportTypes.map((report) => (
                  <li key={report.id}>
                    <button
                      className={
                        report.id === filters.reportType ? "active" : ""
                      }
                    >
                      <div>
                        <img alt="" src="/Assets/Images/Reports/report.png" />
                      </div>
                      <span>{report.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div> */}
        </div>
        {"" + filters.startDate === "" + filters.endDate ? (
          <p className="no-data">
            {/* No results are available for the selected date */}
            Select date range to get the report
          </p>
        ) : (
          <div className="filters-actions">
            <button
              onClick={() => {
                window.location.reload();
              }}
            >
              Reset All
            </button>
            <DownloadTableExcel
              filename="users table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <button className="active">Download</button>
            </DownloadTableExcel>
          </div>
        )}
      </section>
      {"" + filters.startDate !== "" + filters.endDate && (
        <section className="card br-8">
          <div className="card-header">
            <span>Result</span>
            <p>
              {moment(filters.startDate).format("DD MMMM Y")} -{" "}
              {moment(filters.endDate).format("DD MMMM Y")}
            </p>
          </div>
          <div className="table">
            <table ref={tableRef} className="reports-table">
              <thead>
                <tr>
                  <th>Room Type</th>
                  <th>Date</th>
                  <th>Single</th>
                  <th>Double</th>
                  <th>Triple</th>
                  <th>Quad</th>
                  <th>Extra Adult Charge</th>
                  <th>Extra Child (0,6 years)</th>
                  <th>Extra Child (7-12 years)</th>
                </tr>
              </thead>
              <tbody>
                {rates &&
                  rates.map((el) => {
                
                    let a = roomTypes.find((e) => e.id == el._id);
                 

                    return (
                      <>
                        <tr>
                          <td>{a?.name}</td>
                          <td>{moment(el?.date).format("DD-MMM-YYYY")}</td>
                      
                          <td>{el?.single}</td>
                          <td>{el?.double}</td>
                          <td>{el?.triple}</td>
                          <td>{el?.quad}</td>
                          <td>{el?.extraAdult}</td>
                          <td>{el?.extraChild}</td>
                          <td>{el?.extraInfant}</td>
                        </tr>
                      </>
                    );
                  })}

                {/* <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </section>
      )}
    </>
  );
};

export default Rates;
