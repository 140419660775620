import httpservice from "../HttpService/httpService";

import { toast } from "react-toastify";

export async function getmanagerBookingPush(limit, secret) {
  try {
    const { data: booking } = await httpservice.get(
      `easeroom/getManagerBooking?limit=${limit}&hotelCode=${secret}`
    );
    // console.log("---->",booking)
    return booking;
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}

export async function channelMannagerBooking(data) {
  return httpservice.post("easeroom/managerBookingpush", { ...data });
}
export async function cancelManagerBooking(data) {
  return httpservice.post("easeroom/cancelBooking", { ...data });
}

export async function getAllRateplanEaseRoom(user) {
  try {
    console.log("user", user.live);
    const { data: Rateplan } = await httpservice.post("easeroom/Rateplan",
      {
        Header: {
          Username: user?.live?.easeRoom?.email,
          Password: user?.live?.easeRoom?.password
        },
        HotelId: user?.live?.easeRoom?.secret
      }
    )
    // console.log("rateplan", Rateplan);
    return Rateplan
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}

export async function getAllBookingFromOta(hotelCode) {
  try {
    //  dummy -> 4857, 14292, 15191
    const { data: BookingList } = await httpservice.get(`easeroom/getNewBookingfromOta?hotelCode=${hotelCode}`)
    return BookingList
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}

export async function getSingleBookingFromOta(id) {
  try {
    const { data: BookingList } = await httpservice.get(`easeroom/getSingleNewBookingfromOta/${id}`)
    return BookingList
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}
export async function getSingleoffline(id, hotelCode) {
  try {
    const { data } = await httpservice.get(`/easeroom/getsingleOffline/${id}?hotelCode=${hotelCode}`)
    return data
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}
export function updateRates(payload) {
  return httpservice.post("easeroom/UpdateRates", payload);
}

export function UpdateInventory(payload) {
  return httpservice.post("easeroom/UpdateInventory", payload);
}

export function getAllRoomType(payload) {
  return httpservice.post("easeroom/Roomtype", payload);
}

export async function getAllInventory(payload) {
  try {
    const inventory = httpservice.get("easeroom/allInventory?" + `startDate=${payload.startDate}&endDate=${payload.endDate}`)
    return inventory
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}

export async function getBookingfromAllOta(payload) {
  try {
    const booking = httpservice.get("/getBookingfromAllOta?" + `startDate=${payload.startDate}&endDate=${payload.endDate}`)
    return booking
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}

export async function getbookingRates(payload) {
  try {
    const rates = httpservice.get("easeroom/getUpdatedRates?" + `startDate=${payload.startDate}&endDate=${payload.endDate}`)
    return rates
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}

export async function getRatePlan(payload) {
  try {
    const plan = httpservice.post("easeroom/Rateplan", payload)
    return plan
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}

//no show update - booking.com
export async function updateNoShow(payload) {
  try {
    const noShow = httpservice.post("easeroom/noshowupdate", payload)
    return noShow
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}


// editbooking page Ritik
export async function editOfflineBooking(id, payload) {
  try {
    return await httpservice.post(`easeroom/modifiedBooking/${id}`, payload)
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}

// new rates
export async function getAllRates(payload) {
  try {
    const rates = httpservice.get(`easeroom/getAllRates?startDate=${payload.startDate}&endDate=${payload.endDate}`)
    return rates
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}

// new single update inventory
export async function updateSingleInventory(payload) {
  try {
    const inventories = httpservice.post(`easeroom/Updatesingleinventory`, payload)
    return inventories
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}

// new single update rate
export async function updateSingleRate(payload) {
  try {
    const rates = httpservice.post(`easeroom/UpdateSingleRate`, payload)
    return rates
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}