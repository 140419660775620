import { toast } from "react-toastify";
import httpservice from "../HttpService/httpService";

export async function getNotifications() {
  try {
    const notifications = await httpservice.get("/utils/notifications");
    // console.log("notifications===>M", notifications);
    return notifications;
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}
export async function updateNotifications() {
  try {
    const markAsRead = await httpservice.put("/utils/updatenotifications");
    console.log("markAsRead===>M", markAsRead);
    return markAsRead;
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}
