import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import InputGroup from "../../Pages/profile/inputgroup.jsx";
import './profile.css';
import { FaPlusCircle, FaUser } from "react-icons/fa";
import {
  getMeuser,
  getAddressViaPincode,
  getUserandUpdate,
} from "../../core/Services/User/login/login";
import Uploady from "@rpldy/uploady";
import UploadButton from "@rpldy/upload-button";
import axios from "axios";

import ChangePasswordCard from "../changePassword/changePassword";

const EditProfile = () => {
  const [preformData, setPreformData] = useState();
  const [changeAble, setChangeAble] = React.useState(false);
  const [id, setId] = useState();
  const [image, setImage] = useState("");
  const [imgUrl, setImageUrl] = useState("");
  const [defaultImg, setDefaultImg] = useState(<FaUser />);
  const navigate = useNavigate();
  const [changePassword, setchangePassword] = useState(false)


  const [hotel, setHotel] = React.useState({
    propertyCode: "",
    hotelName: "",
    typeofprop: "",
    firstName: "",
    lastName: "",
    roomsNo: "",
    since: "",
    phone: "",
    email: "",
    submittedBy: "",
    lattitude: "",
    longitude: "",
    locality: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    imgUrl: ""
  });
  const [listOfYear, setListOfYears] = useState([]);

  useEffect(() => {
    getMeuser().then((res) => {
      setPreformData(res);
      if (res) {
        setHotel({
          propertyCode: res.code,
          hotelName: res.hotelName,
          typeofprop: res.typeofprop,
          firstName: res.firstName,
          lastName: res.lastName,
          hotelStarRating: res.rating,
          roomsNo: res.roomsNo,
          since: res.since,
          phone: res.phone,
          email: res.email,
          password: res.password,
          confirmPassword: res.confirmPassword,
          submittedBy: res.submittedBy,
          lattitude: res.latitude,
          longitude: res.longitude,
          locality: res.locality,
          address: res.address,
          city: res.city,
          state: res.state,
          pincode: res.pincode,
          country: res.country,
          imgUrl: res.imgUrl
        });
        setChangeAble(true);
        setId(res._id);
      }
    });

    const arr = [];
    const currentYear = new Date().getFullYear();
    for (let index = 0; index < 100; index++) {
      arr.push(currentYear - index);
    }
    setListOfYears(arr);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let imgUrl = hotel.imgUrl;

    if (image) {
      const formData = new FormData();
      formData.set("file", image);
      const { data } = await axios.post(
        "https://api.thezonzo.com/api/v1/fileUpload",
        formData
      );
      imgUrl = data.fileUrl;
    }

    hotel.imgUrl = imgUrl;

    console.log("Hotel jhete ===?", hotel);
    getUserandUpdate(id, hotel).then((res) => {
      if(res.error) {
        toast.error("Something went wrong! Please try again.");
      } else {
        toast.success("Successfully Updated!");
        // window.location.href = "/profile?section=property";
      }
      // window.location.href = "/profile?section=property";
      navigate("/profile/property");
    });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { currentTarget: input } = e;
    const demoHotel = { ...hotel };
    demoHotel[input.name] = input.value;
    setHotel({ ...demoHotel });
    if (input.name == "pincode") {
      if (input.value.length == 6) {
        getAddressViaPincode(input.value)
          .then((res) => {
            console.log("checking here", res);
            const { results } = res.data;
            if (results.length > 0) {
              results[0].address_components.map((ele) => {
                if (
                  ele.types.includes("administrative_area_level_1") ||
                  ele.types.includes("locality")
                ) {
                  demoHotel.state = ele.long_name;
                }
                if (
                  ele.types.includes("administrative_area_level_3") ||
                  ele.types.includes("sublocality") ||
                  ele.types.includes("locality")
                ) {
                  demoHotel.city = ele.long_name;
                }
                if (ele.types.includes("country")) {
                  demoHotel.country = ele.long_name;
                }
                if (ele.types.includes("locality")) {
                  demoHotel.locality = ele.long_name;
                }
              });
              demoHotel.address = results[0].formatted_address;
              setHotel({ ...demoHotel });
            }
          })
          .catch((err) => console.log("Error", err));
      }
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleButton = (e) => {
    setchangePassword(true)
    console.log(changePassword)
  }

  return (
    <form className="edit-profile-form">
    <h1 className="edit-profile-heading">Edit Profile</h1>
     <div className= "edit-profile-property">
     <div className="edit-profile-details-grid">
          <h3 className="edit-profile-subheading">Property Details</h3>
        </div>
      <div className="edit-profile-grid">
        <InputGroup
          label="Property Code"
          name="propertyCode"
          placeHolder="property code"
          type="text"
          disabled={true}
          value={hotel.propertyCode}
        />
        <InputGroup
          label="Property Name"
          name="hotelName"
          placeHolder="property name"
          type="text"
          disabled={true}
          value={hotel.hotelName}
          setData={handleChange}
        />
        <div className="edit-profile-select">
          <label htmlFor="propertyType" className="edit-profile-label">
            Property Type
          </label>
          <select
            className="edit-profile-select-input"
            id="propertyType"
            name="typeofprop"
            placeholder="Property Type"
            defaultValue="hotel"
            value={hotel.typeofprop}
            onChange={(e) => {
              handleChange(e);
            }}
          >
            <option value="">Select Type</option>
            <option value="hotel">Hotel</option>
            <option value="homeStay">Home Stay</option>
            <option value="villa">Villa</option>
            <option value="apartment">Apartment</option>
            <option value="resort">Resort</option>
            <option value="guestHouse">Guest House</option>
            <option value="hostel">Hostel</option>
          </select>
        </div>
        <label htmlFor="uploadImage">
          <input
            type="file"
            id="uploadImage"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />


          <div style={{ position: "relative", display: "inline-block", marginLeft: 80 }}>
            {image || hotel.imgUrl ? (
              <img
                src={image ? URL.createObjectURL(image) : hotel.imgUrl}
                alt="Profile"
                className="edit-profile-img"
              />
            ) : (
              <FaUser className="edit-profile-default-img" />
            )}

            <FaPlusCircle style={{ position: "absolute", bottom: 0, right: 0 }} />
          </div>
        </label>
        {/* <img
          src={hotel.imgUrl ? hotel.imgUrl : "/Assets/Images/Header/riverwood.png"}
          alt=""
          className="edit-profile-img"
        /> */}
        <InputGroup
          label="Total Rooms"
          name="roomsNo"
          placeHolder="total rooms"
          type="number"
          value={hotel.roomsNo}
          setData={handleChange}
        />
      </div>
      </div>
      <div className="edit-profile-details">
        <div className="edit-profile-details-grid">
          <h3 className="edit-profile-subheading">Personal Details</h3>
        </div>

        <div className="edit-profile-details-grid">
          <InputGroup
            label="First Name"
            name="firstName"
            placeHolder="First Name"
            type="text"
            disabled={true}
            value={hotel.firstName}
            setData={handleChange}
          />

          <InputGroup
            label="Last Name"
            name="lastName"
            placeHolder="Last name"
            type="text"
            value={hotel.lastName}
            setData={handleChange}
          />

          <InputGroup
            label="Phone No."
            name="phone"
            placeHolder="Phone Number"
            type="text"
            value={hotel.phone}
            setData={handleChange}
          />

          <InputGroup
            label="E-mail"
            name="email"
            placeHolder="Email"
            type="email"
            value={hotel.email}
            setData={handleChange}
          />
          {/* <div className="edit-profile-select">
            <label htmlFor="since" className="edit-profile-label">
              Booking Since
            </label>
            <select
              className="edit-profile-select-input"
              id="since"
              name="since"
              placeholder="Property Type"
              defaultValue="1"
              value={hotel.since}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <option value={0}>Booking Since</option>
              {listOfYear.map((ele) => {
                return (
                  <option className="edit-profile-select-option" value={ele} key={ele}>
                    {ele}
                  </option>
                );
              })}
            </select>
          </div> */}
        </div>
      </div>

      <div className="edit-profile-address">
        <div className="edit-profile-address-grid">
          <h3 className="edit-profile-subheading">Address Details</h3>
        </div>

        <div className="edit-profile-address-grid">
          {/* <InputGroup
            label="Lattitude"
            name="lattitude"
            placeHolder="Lattitude"
            type="number"
            value={hotel.lattitude}
            setData={handleChange}
          />
          <InputGroup
            label="Longitude"
            name="longitude"
            placeHolder="Longitude"
            type="number"
            value={hotel.longitude}
            setData={handleChange}
          /> */}
          <InputGroup
            label="Pincode"
            name="pincode"
            placeHolder="Pincode"
            type="number"
            value={hotel.pincode}
            setData={handleChange}
          />


          {/* <div className="mt-6 flex flex-col gap-2 ">
            <label className="block text-sm font-bold text-[#370D6F]">
              Upload Hotel Image
            </label>
            <input
              type="file"
              className="text-sm cursor-pointer"
              onChange={handleImageChange}
            />
          </div> */}


          <InputGroup
            label="Address"
            name="address"
            className="col-span-2"
            placeHolder="Address"
            type="text"
            value={hotel.address}
            setData={handleChange}
          />
          <InputGroup
            label="Locality"
            name="locality"
            placeHolder="Locality"
            type="text"
            value={hotel.locality}
            setData={handleChange}
          />
          <InputGroup
            label="City"
            name="city"
            placeHolder="City"
            type="text"
            value={hotel.city}
            setData={handleChange}
          />
          <InputGroup
            label="State"
            name="state"
            placeHolder="State"
            type="text"
            value={hotel.state}
            setData={handleChange}
          />
          <InputGroup
            label="Country"
            name="country"
            placeHolder="Country"
            type="text"
            value={hotel.country}
            setData={handleChange}
          />
        </div>
      </div>

      <div className="edit-profile-button-container">
        <Link to="/profile">
          <button
            className="edit-profile-submit-btn"
            type="button"
            onClick={(e) => handleSubmit(e)}
          >
            Save and Continue
          </button>
        </Link>
        <button
          className="edit-profile-change-password-btn"
          
          type="button"
          onClick={handleButton}
        >Change Password</button>
      </div>
      {changePassword && <ChangePasswordCard setchangePassword={setchangePassword} changePassword={changePassword} />}
    </form>
  );
};

export default EditProfile;
