import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { IoLogOut } from "react-icons/io5";
import InputGrp from "../../Components/common/inputGRP";
import './profile.css';
import { getMeuser } from "../../core/Services/User/login/login";
import { Link, useParams } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaHotel } from "react-icons/fa";
import InputGroup from './inputgroup';


const UserProfile = () => {

  const navigate = useNavigate();
  const [preformData, setPreformData] = useState();
  const [hotel, setHotel] = React.useState({});
  const [selectedSection, setSelectedSection] = useState('property');
  const { section } = useParams();
  const location = useLocation();

  const [loading] = React.useState(false);
  const logout = () => {
    localStorage.clear();
    navigate("/", { replace: true });
    window.location.reload();
    // window.location.replace("/login")
  };

  useEffect(() => {
    if (section) {
      setSelectedSection(section);
    }
    if (section) {
      setSelectedSection(section);
    } else {
      const pathname = location.pathname;
      const sections = pathname.split('/').filter(segment => segment !== '');
      const selectedSection = sections.length > 1 ? sections[1] : 'property';
      setSelectedSection(selectedSection);
    }


    getMeuser().then((res) => {
      setPreformData(res);
      if (res) {
        setHotel({
          propertyCode: res.code,
          hotelName: res.hotelName,
          typeofprop: res.typeofprop,
          firstName: res.firstName,
          lastName: res.lastName,
          hotelStarRating: res.rating,
          roomsNo: res.roomsNo,
          since: res.since,
          phone: res.phone,
          email: res.email,
          submittedBy: res.submittedBy,
          lattitude: res.latitude,
          longitude: res.longitude,
          locality: res.locality,
          address: res.address,
          city: res.city,
          state: res.state,
          pincode: res.pincode,
          country: res.country,
          imgUrl: res.imgUrl
        });
      }
    });
  }, [section, location]);


  const handleSectionClick = (section) => {
    setSelectedSection(section);
    navigate(`/profile/${section}`);
  };


  return (
    <>
      <div className="relative bg-gradient-to-r from-[#9134F8_35%] to-[#7318FC]  w-full  shadow-lg border-2 border-gray-200" style={{ margin: "0 -2rem", padding: "0", width: "calc(100% + 4rem)" }}>
        <div className="w-full h-40 object-cover -mt-8 " />

        <div style={{ position: "relative", display: "flex", justifyContent: "end", marginRight: 0 }}>
          {hotel.imgUrl ? (

            <img src={hotel.imgUrl} alt="Profile Photo" className="rounded-full absolute bottom-0 left-32 transform -translate-x-2 translate-y-1/2 -mb-16 border-4 border-solid  border-white  bg-gray-200 w-44 h-44" />
          ) : (
            <FaUser className="rounded-full absolute bottom-0 left-28 transform -translate-x-2 translate-y-1/2 -mb-16 border-4 border-solid  border-white  bg-gray-200 w-44 h-44" />
          )}

        </div>

        <div className="relative ml-80 text-white" >
          <h3 className="font-bold text-3xl" >{hotel.hotelName}</h3>
          <p className="text-lg pb-4">{hotel.address} | {hotel.phone}</p>
        </div>
      </div>


      <div className="flex">
        <div className="w-1/5 h-full bg-[#F4F6FF] rounded-xl p-4 mt-28 ml-12">

          <ul className="mt-10 ml-8">
            <li className={`cursor-pointer flex items-center mb-8 text-xl text-[#526D82] font-bold ${selectedSection === 'property' ? 'active' : ''}`} onClick={() => handleSectionClick('property')}>
            <FaHotel className={`mr-2 ${selectedSection === 'property' ? 'text-purple-600' : 'text-[#526D82]'}`} />
              {selectedSection === 'property' && <div className="indicator"></div>}
              <span className={`${selectedSection === 'property' ? 'text-purple-600' : 'text-[#526D82]'}`}>Property Detail</span>
            </li>
            <li className={`cursor-pointer flex items-center mb-8 text-xl text-[#526D82] font-bold ${selectedSection === 'address' ? 'active' : ''}`} onClick={() => handleSectionClick('address')}>
              <FaMapMarkerAlt className={`mr-2 ${selectedSection === 'address' ? 'text-purple-600' : 'text-[#526D82]'}`} />
              {selectedSection === 'address' && <div className="indicator"></div>}
              <span className={`${selectedSection === 'address' ? 'text-purple-600' : 'text-[#526D82]'}`}>  Address Detail
              </span>
            </li>
            <li className={`cursor-pointer flex items-center mb-8 text-xl text-[#526D82] font-bold ${selectedSection === 'personal' ? 'active' : ''}`} onClick={() => handleSectionClick('personal')}>
            <FaUser className={`mr-2 ${selectedSection === 'personal' ? 'text-purple-600' : 'text-[#526D82]'}`} />
              {selectedSection === 'personal' && <div className="indicator"></div>}
              <span className={`${selectedSection === 'personal' ? 'text-purple-600' : 'text-[#526D82]'}`}>Personal Detail</span>
            </li>


            <li className="cursor-pointer flex items-center mt-32 text-xl text-[#6B46C1] font-bold"
              button
              onClick={logout}
            >
              {loading ? (
                <React.Fragment></React.Fragment>
              ) : (
                <IoLogOut className="mr-2" />
              )}
              Logout
            </li>
          </ul>
        </div>

        {/* Profile details */}
        <div className="w-3/5 p-8 mx-24">
          {selectedSection === 'property' && (
            <div className="bg-white p-4 mt-28 ">

              <h3 className="mb-4 text-[#526D82] text-center text-2xl font-bold">Property Details</h3>

              <div class="grid grid-cols-2 gap-6 pr-4 ml-14 mt-10">
                <InputGroup
                  label="Hotel Name"
                  value={hotel.hotelName}
                  disabled={true}
                  readOnly={true}
                  placeHolder="-"
                  className="mr-38"
                />
                <InputGroup
                  label="Property Code"
                  value={hotel.propertyCode}
                  disabled={true}
                  readOnly={true}
                  placeHolder="-"
                  className="ml-36"
                />

                <InputGroup
                  label="Type of Property"
                  value={hotel.typeofprop}
                  disabled={true}
                  readOnly={true}
                  className="mr-38"
                />

                <InputGroup
                  label="Total Rooms"
                  value={hotel.roomsNo}
                  disabled={true}
                  readOnly={true}
                  placeHolder="-"
                  className="ml-36"
                />

              </div>
            </div>

          )}

          {selectedSection === 'address' && (
            <div className="bg-white p-4 mt-20">
              <h3 className="mb-4 text-center text-[#526D82] text-2xl font-bold">Address Details</h3>

              <div class="grid grid-cols-2 gap-4 pr-4 ml-14 mt-10">
                <InputGroup
                  label="Address"
                  value={hotel.address}
                  disabled={true}
                  readOnly={true}
                  placeHolder="-"
                  className="mr-38"
                />
                <InputGroup
                  label="Pincode"
                  value={hotel.pincode}
                  disabled={true}
                  readOnly={true}
                  placeHolder="-"
                  className="ml-36"
                />

                <InputGroup
                  label="Locality"
                  value={hotel.locality}
                  disabled={true}
                  readOnly={true}
                  placeHolder="-"
                  className="mr-38"
                />
                <InputGroup
                  label="City"
                  value={hotel.city}
                  disabled={true}
                  readOnly={true}
                  placeHolder="-"
                  className="ml-36"
                />
                <InputGroup
                  label="State"
                  value={hotel.state}
                  disabled={true}
                  readOnly={true}
                  placeHolder="-"
                  className="mr-38"
                />
                <InputGroup
                  label="Country"
                  value={hotel.country}
                  disabled={true}
                  readOnly={true}
                  placeHolder="-"
                  className="ml-36"
                />
              </div>
            </div>
          )}

          {selectedSection === 'personal' && (
            <div className="bg-white p-4 mt-28 ">

              <h3 className="mb-4 text-[#526D82] text-center text-2xl font-bold">Personal Details</h3>

              <div class=" grid grid-cols-2 gap-6 pr-4 ml-14 mt-10"  >
             
                <InputGroup
                  label="First Name"
                  value={hotel.firstName}
                  disabled={true}
                  readOnly={true}
                  placeHolder="-"
                  className="mr-38"
                />
                <InputGroup
                  label="Last Name"
                  value={hotel.lastName}
                  disabled={true}
                  readOnly={true}
                  placeHolder="-"
                  className="ml-36"
                />

                <InputGroup
                  label="Phone"
                  value={hotel.phone}
                  disabled={true}
                  readOnly={true}
                  placeHolder="-"
                  className="mr-38"
                />

                <InputGroup
                  label="E-mail"
                  value={hotel.email}
                  disabled={true}
                  readOnly={true}
                  placeHolder="-"
                  className="ml-36"
                />


              </div>

            </div>
          )}
        </div>
      </div >


      <div className="edit-button ">

        <Link to="/editProfile" className="text-right col-span-1">
          <button
            className="mb-3 px-4 py-2 bg-[#370d6f] text-[#ffffff] text-base  border-2 border-solid border-[#ffffff] text-md rounded-lg shadow-lg hover:bg-[#8F00FF] duration-200 ease-in"
            type="button"
          >
            Edit Profile
          </button>
        </Link>
      </div>

    </>
  );
};

export default UserProfile;