import React, { useRef, useEffect, useState, useCallback } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import Outlinedbluebutton from "../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import { getmanagerBookingPush } from "../../core/Services/Data/EaseRoom";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import moment from "moment";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import zonzo from '../../Assests/images/zonzologof3.png';
import {
  cancelManagerBooking,
  getAllInventory,
  UpdateInventory,
} from "../../core/Services/Data/EaseRoom";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { hover } from "@testing-library/user-event/dist/hover";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AllBookings = ({ user }) => {
  const gridRef = useRef(null);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [cancelData, setCancelData] = useState({});
  const navigate = useNavigate();
  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };
  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const Imagerenderer = (props) => {
    // console.log("=====>",props)
    return <img src={zonzo}
      className="h-5 w-5 mt-2"
      alt="photo"
    />;
  };

  const StylesText = (props) => {
    return <div> {props.data.GuestName.toUpperCase()}</div>;
  };
  const CancelledButton = ({ onClick }) => {
    return (
      <Button
        sx={{
          background:
            "linear-gradient(90deg, rgba(37,3,124,1) 0%, rgba(6,6,157,1) 16%, rgba(179,0,255,1) 100%)",
        }}
        variant="contained"
        onClick={onClick}
      >
        Cancel
      </Button>
    );
  };

  const handleModify = (id) => {
    console.log(id);
    navigate(`/bookings/editbooking/${id}`);
  };
  const [columnDefs] = useState([
    {
      headerName: "Channel",
      field: "",
      suppressMenu: true,
      suppressSorting: true,
      resizable: true,
      //   floatingFilter: false,
      cellRenderer: Imagerenderer,
    },
    {
      headerName: "Room Type",
      suppressMenu: true,
      field: "RoomTypeName",
      resizable: true,
    },
    {
      headerName: "Guest name",
      suppressMenu: true,
      field: "GuestName",
      resizable: true,
      cellRenderer: StylesText,
    },
    {
      headerName: "Booking Date",
      suppressMenu: true,
      field: "BookingDate",
      resizable: true,
      valueGetter: (e) => {
        return moment(e.data.BookingDate).format("DD/MM/YY");
      },
      // sort: "desc",
    },
    {
      headerName: "Check In - Check Out",
      suppressMenu: true,
      field: "Checkin",
      resizable: true,
      valueGetter: (e) => {
        // console.log("check", e.data.Checkin)
        const start = new Date(e.data.Checkin);
        const end = new Date(e.data.Checkout);
        const final =
          moment(start).format("DD/MM/YY") +
          " - " +
          moment(end).format("DD/MM/YY");
        // return(
        //  `${start.toLocaleDateString('en-US', {
        //     year: 'numeric',
        //     month: 'long',
        //     day: 'numeric',
        // })} - ${end.toLocaleDateString('en-US', {
        //     year: 'numeric',
        //     month: 'long',
        //     day: 'numeric',
        // })}`
        // )
        return final;
      },
    },
    {
      headerName: "Status",
      resizable: true,
      field: "BookingStatus",
      suppressFilter: true,
      valueGetter: (e) => {
        // console.log("status", e.data.BookingStatus)
        if (e.data.BookingStatus == "Booked") {
          return "Confirmed";
        } else {
          return e.data.BookingStatus;
        }
      },
    },
    {
      headerName: "Action",
      resizable: true,
      cellRenderer: (params, e) => {
        // e.stopPropagation()
        let bool = isDateInPast(params?.data?.Checkin);
        console.log(bool);
        const btnStyle = {
          btnEnable: {
            background:
              "linear-gradient(90deg, rgba(37,3,124,1) 0%, rgba(6,6,157,1) 16%, rgba(179,0,255,1) 100%)",
            fontSize: "0.6rem",
            marginRight: "10px",
          },
          btnDisbled: {
            background: "#D3D3D3",
            fontSize: "0.6rem",
            marginRight: "10px",
            cursor: "not-allowed",
            disabled: "true",
            boxShadow: "none",
          },
        };
        const bool1 = params?.data?.BookingStatus === "Cancelled" || bool;
        // console.log(bool1);

        return (
          <>
            <Button
              style={bool1 === true ? btnStyle.btnDisbled : btnStyle.btnEnable}
              variant="contained"
              onClick={
                bool1 === false
                  ? () => {
                    handleModify(params.data._id);
                  }
                  : null
              }
            >
              Edit
            </Button>

            <Button
              style={bool1 === true ? btnStyle.btnDisbled : btnStyle.btnEnable}
              variant="contained"
              onClick={
                bool1 === false
                  ? () => {
                    setOpen(true);
                    setCancelData(params.data);
                    // cancelBooking(params.data);
                  }
                  : null
              }
            >
              Cancel
            </Button>
          </>
        );
      },
    },
  ]);

  function isDateInPast(date) {
    console.log(date);
    const currentDate = new Date();
    let diff = moment(date).diff(currentDate, "days");
    console.log(diff, "diff");
    if (diff <= -1) {
      return true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    getmanagerBookingPush("", user?.live?.easeRoom?.secret).then((res) => {
      setData(res.data);
      console.log("Data", data);
    });
  }, []);

  const cancelBooking = async (booking) => {
    console.log(booking);

    cancelManagerBooking({ bookingId: booking._id })
      .then(async (res) => {
        console.log(res);
        setChecked(!checked);
        setOpen(false);
        toast.success("Booking Cancelled Successfully");
        navigate('/bookings/Allbookings')
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  return (
    <>
      <section className="bookings card">
        <div className="card-header">
          <span className="font-semibold text-base">All Offline Booking</span>
          <Outlinedbluebutton download={onBtnExport} />
        </div>
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="flex flex-col gap-6 items-center">
              <h3 className="text-lg ">
                Are you sure you want to cancel this Booking?
              </h3>
              <div className="flex gap-4 justify-end w-full">
                <Button
                  variant="contained"
                  sx={{ color: "white" }}
                  fullWidth
                  color="secondary"
                  onClick={() => {
                    cancelBooking(cancelData);
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  sx={{ color: "white" }}
                  fullWidth
                  onClick={() => setOpen(false)}
                >
                  No
                </Button>
              </div>
            </div>
          </Box>
        </Modal>

        <div className="mt-5 ">
          <div
            className="ag-theme-alpine"
            style={{ height: 400, width: "100%" }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={data}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              pagination={true}
              paginationPageSize={10}
              rowSelection="multiple"
              onRowDoubleClicked={(e) => {
                console.log("Id:", e.data._id);
                navigate("/bookings/Allbookings/offline/" + e.data._id);
              }}
            ></AgGridReact>
          </div>
        </div>
      </section>
      {/* Delete Popup */}
    </>
  );
};

export default AllBookings;
