import React from "react";
import { connect } from "react-redux";
import AllRoutes from "./Components/AllRoutes";
import Sidebar from "./Components/Sidebar/Sidebar";
import Header from "./Components/Header/Header";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getMeuser } from "./core/Services/User/login/login";

const Root = ({ isActive }) => {
  const location = useLocation();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [path, setPath] = useState(location.pathname);
  const [user, setUser] = useState();

  useEffect(() => {
    window.scroll(0, 0);
    setPath(location.pathname);
    getMeuser().then((res) => {
      setUser(res);
    });
  }, [location]);

  // console.log("APLICATION VERSION 101");

  return (
    <div
      className={
        path !== "/" && path !== "/ota" && path !== "/ota/verification-success"
          ? isActive
            ? "container active"
            : "container"
          : ""
      }
    >
      <Sidebar currentTabIndex={currentTabIndex} />
      <ToastContainer />
      <div className="main-content">
        <Header mainuser={user} />
        <div className="content">
          <AllRoutes user={user} setCurrentTabIndex={setCurrentTabIndex} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isActive: state.sidebar,
});

export default connect(mapStateToProps, null)(Root);
