import React, { useCallback, useState, useRef } from "react";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import { BsCalendarDate } from "react-icons/bs";
import { AiOutlineCaretDown } from "react-icons/ai";
import { DownloadTableExcel } from "react-export-table-to-excel";
import {
  getAllRoomType,
  getBookingfromAllOta,
} from "../../core/Services/Data/EaseRoom";
import { useEffect } from "react";
const Bookings = ({user}) => {
  const [booking, setBooking] = useState([]);
  const tableRef = useRef(null);
  const [roomTypes, setRoomTypes] = useState([]);
  const [selectedRoomType, setSelectedRoomType] = useState(null);
  const [states, setStates] = useState({
    roomType: "",
  });
  const [filters, setFilters] = useState({
    startDate: new Date(),
    endDate: new Date(),
    roomType: 1,
    reportType: 1,
  });

  const [openDateDropdown, setOpenDateDropdown] = useState(false);
  const [openRoomTypeDropdown, setOpenRoomTypeDropdown] = useState(false);
  const [openReportTypeDropdown, setOpenReportTypeDropdown] = useState(false);
  const dateRangeHandler = useCallback(
    (event) => {
      setFilters({
        ...filters,
        startDate: event.selection.startDate,
        endDate: event.selection.endDate,
      });
      setOpenDateDropdown(false);
    },
    [filters]
  );

  const filterRooms = (e, room) => {
    console.log("rooom", room);
    e.preventDefault();
    const state = { ...states };
    state.roomType = room;
    console.log(states);
    setStates({ ...state });
    setOpenRoomTypeDropdown(false);
    console.log(states.roomType);
    // console.log("room id :", states.roomType.id);
    // setBooking(booking.filter(el => el.GIRoomTypeCode == states.roomType.id)) 
    setSelectedRoomType(room); 
  };

  const dateDropdownHandler = useCallback(() => {
    setOpenDateDropdown(!openDateDropdown);
    setOpenRoomTypeDropdown(false);
    setOpenReportTypeDropdown(false);
  }, [openDateDropdown]);

  const roomTypeDropdownHandler = useCallback(() => {
    setOpenRoomTypeDropdown(!openRoomTypeDropdown);
    setOpenDateDropdown(false);
    setOpenReportTypeDropdown(false);
  }, [openRoomTypeDropdown]);

  const reportTypeDropdownHandler = useCallback(() => {
    setOpenReportTypeDropdown(!openReportTypeDropdown);
    setOpenDateDropdown(false);
    setOpenRoomTypeDropdown(false);
  }, [openReportTypeDropdown]);

  useEffect(() => {
    const payload = {
      startDate: moment(filters.startDate).format("YYYY-MM-DD"),
      endDate: moment(filters.endDate).format("YYYY-MM-DD"),
    };

    if (selectedRoomType) {
      payload.roomType = selectedRoomType.id;
    }
    getBookingfromAllOta(payload).then(({ data }) => {
      setBooking(data.result);
      console.log("data", data.result);
    });
  }, [filters, selectedRoomType]);

  useEffect(() => {
    const payload = {
      Header: {
        Username: user?.live?.easeRoom?.email,
        Password: user?.live?.easeRoom?.password,
      },
      HotelId: user?.live?.easeRoom?.secret,
    };
    getAllRoomType(payload).then(({ data }) => {
      const roomtype = [];
      data.EaseRoomType.map((ele) => {
        roomtype.push({ id: ele.RoomTypeId, name: ele.RoomTypeName });
      });

      // console.log("AARAY Of ROOM", roomtype);

      setRoomTypes([...roomtype]);
    });

  
    // console.log("filters Date of all", filters);
  }, [filters]);
  
  const dateRangeContainerRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (
      dateRangeContainerRef.current &&
      !dateRangeContainerRef.current.contains(event.target)
    ) {
      // Clicked outside the date range picker, update state or perform actions
      console.log("Clicked outside");
      setOpenDateDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <section className="card br-8 filters-section">
        <div className="filters">
          <div className="date filter">
            <label>Select Date*</label>
            <div
              className={openDateDropdown ? "dropdown active" : "dropdown"}
              onClick={() => setOpenDateDropdown(true)}
              ref={dateRangeContainerRef}
              style={{ cursor: "pointer" }}
            >
              <p>
                <span>
                  {moment(filters.startDate).format("DD MMMM Y")} -{" "}
                  {moment(filters.endDate).format("DD MMMM Y")}
                </span>
                <button>
                  <BsCalendarDate size={16} />
                </button>
              </p>
              <DateRangePicker
                className="date-range"
                ranges={[{ ...filters, key: "selection" }]}
                onChange={dateRangeHandler}
                // showMonthAndYearPickers={false}
              />
            </div>
          </div>
          <div className="filter">
            <label>Room Type</label>
            <div
              className={
                openRoomTypeDropdown
                  ? "dropdown active cursor-pointer"
                  : "dropdown cursor-pointer"
              }
              onClick={roomTypeDropdownHandler}
              style={{ position: "relative" }}
            >
              <p>
                <span>{states.roomType ? states.roomType.name : ""}</span>
                {/* <span>{roomTypes[filters.roomType - 1].name}</span> */}
                <button>
                  <AiOutlineCaretDown size={16} />
                </button>
              </p>
              <ul>
                {roomTypes.map((room) => (
                  <li key={room.id}>
                    <button
                      className={room.id === filters.roomType ? "active" : ""}
                      onClick={(e) => filterRooms(e, room) }
                    >
                      <div>
                        <img alt="" src="/Assets/Images/Reports/room.png" />
                      </div>
                      <span>{room.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* <div className="filter">
            <label>Report Type</label>
            <div
              className={
                openReportTypeDropdown ? "dropdown active" : "dropdown"
              }
            >
              <p>
                <span>{reportTypes[filters.reportType - 1].name}</span>
                <button onClick={reportTypeDropdownHandler}>
                  <AiOutlineCaretDown size={16} />
                </button>
              </p>
              <ul>
                {reportTypes.map((report) => (
                  <li key={report.id}>
                    <button
                      className={
                        report.id === filters.reportType ? "active" : ""
                      }
                    >
                      <div>
                        <img alt="" src="/Assets/Images/Reports/report.png" />
                      </div>
                      <span>{report.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div> */}
        </div>
        {"" + filters.startDate === "" + filters.endDate ? (
          <p className="no-data">
            {/* No results are available for the selected date */}
            Select date range to get the report
          </p>
        ) : (
          <div className="filters-actions" style={{position : "absolute", top : 230, right : 160}}>
            <button
              onClick={() => {
                window.location.reload();
              }}
            >
              Reset All
            </button>
            <DownloadTableExcel
              filename="users table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <button className="active">Download</button>
            </DownloadTableExcel>
          </div>
        )}
      </section>
      {"" + filters.startDate !== "" + filters.endDate && (
        <section className="card br-8">
          <div className="card-header">
            <span>Result</span>
            <p>
              {moment(filters.startDate).format("DD MMMM Y")} -{" "}
              {moment(filters.endDate).format("DD MMMM Y")}
            </p>
          </div>
          <div className="table">
            <table ref={tableRef} className="reports-table">
              <thead>
                <tr>
                  <th>Booking ID</th>
                  <th>Check-In</th>
                  <th>Check-Out</th>
                  <th>Booked On</th>
                  <th>Customer Name</th>
                  <th>Status</th>
                  <th>Booking source</th>
                </tr>
              </thead>
              <tbody>
                {booking &&
                  booking.filter((el) => {
                    const withinDateRange = moment(el.Checkin || el.timeSpan.start).isSameOrAfter(moment(filters.startDate)) &&
                    moment(el.Checkout || el.timeSpan.end).isSameOrBefore(moment(filters.endDate));

                    const matchingRoomType = !selectedRoomType || el.GIRoomTypeCode == selectedRoomType.id;
                    return withinDateRange && matchingRoomType;
                  })
                  .map((el) => {
                    return (
                      <>
                        <tr>
                          <td>{el.BookingId}</td>
                          <td>
                            {el.Checkin
                              ? moment(el.Checkin).format("DD-MMM-YYYY")
                              : moment(el.timeSpan.start).format("DD-MMM-YYYY")}
                          </td>
                          <td>
                            {el.Checkout
                              ? moment(el.Checkout).format("DD-MMM-YYYY")
                              : moment(el.timeSpan.end).format("DD-MMM-YYYY")}
                          </td>
                          <td>{moment(el.BookingDate).format("DD-MMM-YYYY")}</td>
                          <td>{el.GuestName ? el.GuestName : el.customer}</td>
                          <td>
                            {el.BookingStatus
                              ? el.BookingStatus == "Booked"
                                ? "Confirmed"
                                : "Cancelled"
                              : el.status}
                          </td>
                          <td>
                            {el.BookingSource ? el.BookingSource : "Offline"}
                          </td>
                        </tr>
                      </>
                    );
                  })}

                {/* <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>      */}
              </tbody>
            </table>
          </div>
        </section>
      )}
    </>
  );
};

export default Bookings;
