import React, { useState, useEffect } from "react";
import styles from "./active.module.css";
import AccountCircleRounded from "@material-ui/icons/AccountCircleRounded";
import { toast } from "react-toastify";
import moment from "moment";
import { addSupport, getAllsupport } from "../../../core/Services/Data/support";
import { ContactSupportOutlined } from "@material-ui/icons";
const Active = () => {
  const [data, setData] = useState();
  const [form, setForm] = useState({
    ticketNum: 0,
    title: "",
    description: "",
  });
  const [formerror, setFormerror] = useState({
    // ticketNum : 0,
    title: false,
    description: false,
  });
  const validate = () => {
    let value = true;
    let err = { title: false, description: false };
    setFormerror({ ...err });
    if (form.title === "") {
      value = false;
      err.title = "Title cannot be empty";
    }
    if (form.description == "") {
      value = false;
      err.description = "Description cannot be empty";
    }
    setFormerror({ ...err });
    return value;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("form", form)
    if (validate()) {
      console.log("form", form);

      addSupport(form).then((res) => {
        toast.success(res.data.message);
        // getAllsupport()
        gettingAllsupport();
        document.getElementById("title").value = "";
        document.getElementById("desc").value = "";
        // console.log("FORM@", form)
        // console.log("REs akash", res.data);
      });
    }
  };

  useEffect(() => {
    gettingAllsupport();
  }, []);
  const gettingAllsupport = () => {
    getAllsupport().then((res) => {
      setData(res.data);
    });
  };
  console.log("DATA", data && data[0]);

  return (
    <div className={styles.container}>
      <div>
        {data &&
          data
            .filter((e) => e.status == "active")
            .map((el) => {
              return (
                <div className={styles.tickets}>
                  {/* dfkjbvhuih */}
                  <div>
                    <h1>{el.title}</h1>
                    <h2 readonly>{"Ticket No - " + el.ticketNum}</h2>
                    <h2>{moment(el.createdAt).format("DD-MMM-YYYY")}</h2>
                  </div>
                  <p>{el.description}</p>
                </div>
              );
            })}
      </div>
      <p className={styles.v1}></p>
      <div>
        <div className={styles.rightSide}>
          <AccountCircleRounded /> <h1 className="font-bold text-indigo-900 text-xl tracking-wide">Raise a ticket</h1>
        </div>
        <form action="">
          <div className={styles.form}>
            <div className="flex w-[100%]">
              <div className="w-[50%] ">
                <h1 className="font-bold text-indigo-900 tracking-wide text-md">Ticket Number</h1>
                {console.log("dattaa", data && data[0])}
                <input
                  class=" border-solid border-slate-300"
                  type="text"
                  readOnly
                  value={data && data[0]?.ticketNum ? parseInt(data[0].ticketNum) + 1 : 500}
                />
              </div>
              <div className="w-[50%] ">
                <h1 className="font-bold text-indigo-900 tracking-wide text-md">Ticket Title</h1>
                <input
                  type="text"
                  id="title"
                  onChange={(e) => {
                    setForm({
                      ...form,
                      ticketNum: data && data[0]?.ticketNum ? parseInt(data[0].ticketNum) + 1 : 500,
                      title: e.target.value,
                    });
                  }}
                />
                <p style={{ color: "red" }}>{formerror.title ? formerror.title : null}</p>
              </div>
            </div>
            <div className="mt-8">
              <h1 className="font-bold text-indigo-900 tracking-wide text-md">Description</h1>
              <textarea
                type="text"
                id="desc"
                rows="5"
                onChange={(e) => {
                  setForm({ ...form, description: e.target.value });
                }}
              />
              <p style={{ color: "red" }}>{formerror.description ? formerror.description : null}</p>
            </div>
            <div className="w-[90%] text-center mt-6">
              <button className=" mb-3 px-6 py-2 bg-[#8F00FF] text-[#ffffff] text-md rounded-lg shadow-lg hover:bg-[#B660CD] duration-200 ease-in"
                onClick={(e) => {
                  handleSubmit(e);
                }}>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Active;
