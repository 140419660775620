import httpService from "../HttpService/httpService";
import { toast } from "react-toastify";

export async function addNewRoom(data) {
  return httpService.post("room/addroom", data);
}

export async function getRooms(){
  try {
    return httpService.get("room/getrooms")
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}

