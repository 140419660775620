import React, { useState } from "react";

const DAYS = [
  {
    key: 1,
    label: "MON",
  },
  {
    key: 2,
    label: "TUE",
  },
  {
    key: 3,
    label: "WED",
  },
  {
    key: 4,
    label: "THU",
  },
  {
    key: 5,
    label: "FRI",
  },
  {
    key: 6,
    label: "SAT",
  },
  {
    key: 7,
    label: "SUN",
  },
];

const Toggledays = (props) => {
  const [days, setDays] = useState(props.workingDays);

  const appendDays = (key) => {
    setDays((prevState) => {
      if (!prevState.includes(key) || prevState.length === 0) {
        props.handleWorkingDays([...prevState, key]);
        return [...prevState, key];
      } else {
        props.handleWorkingDays([...prevState].filter((ele) => ele !== key));
        return [...prevState].filter((ele) => ele !== key);
      }
    });
  };
  return (
    <>
      {days && (
        <div className="grid grid-cols-12 gap-0 border-solid">
          <div className="col-span-12 md:col-span-3 my-auto">
            {/* <p className="text-sm md:text-md text-gray-500 font-semibold">Days :</p> */}
          </div>
          <div className="col-span-12 md:col-span-12 ml-12 flex flex-wrap justify-center">
            {DAYS.map((ele) => {
              return (
                <div
                  key={ele.key}
                  className={`my-2  sm:my-0 cursor-pointer font-semibold px-3 py-2 border-2 rounded-md text-sm text-white border-solid 
                         flex flex-wrap justify-center items-center ${
                           days.includes(ele.key)
                             ? "bg-purple-500"
                             : "bg-gray-300"
                         }`}
                  onClick={() => appendDays(ele.key)}
                >
                  {ele.label}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default Toggledays;