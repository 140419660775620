import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputGrp from "../../Components/common/inputGRP";
import { editOfflineBooking, getAllRoomType, getRatePlan, getSingleoffline, } from "../../core/Services/Data/EaseRoom";
import moment from "moment";
import Loading from "react-fullscreen-loading";


// ------------Main Function----------------
function EditBookingForm({ user, setTab, changeData }) {

  const [roomType, setRoomType] = React.useState([]);
  const [mealType, setMealType] = React.useState([]);

  const [stateList, setStateList] = React.useState([]);
  const [cityList, setCityList] = React.useState([]);

  const [checkin, setCheckin] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [bookingDate, setBookingDate] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [bookingSource, setBookingSource] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = React.useState("");
  const [guestName, setGuestName] = useState("");
  const [adultCount, setAdultCount] = useState("");
  const [childCount, setChildCount] = useState("");
  const [roomTypeName, setRoomTypeName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [specialNote, setSpecialNote] = useState("");
  const [totalRooms, setTotalRooms] = useState("");
  const [meal, setMeal] = useState("");
  const [roomTypeCode, setRoomTypeCode] = useState("");
  const [bookingAmount, setBookingAmount] = useState("");
  const [advancePayment, setAdvancePayment] = useState("");
  const [balanceLeft, setBalanceLeft] = useState("");
  const [bookingStatus, setBookingStatus] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  //---------handle Edit Booking Function------------
  const booking = {
    Header: {
      Username: user.live.easeRoom.email,
      Password: user.live.easeRoom.password,
    },

    Username: "Zonzo",
    UserCode: "Zonzo",
    Checkin: checkin,
    Checkout: checkOut,
    BookingAmount: bookingAmount,
    AdvancePayment: advancePayment,
    BalanceLeft: balanceLeft,
    BookingId: bookingId,
    BookingStatus: bookingStatus,
    BookingDate: bookingDate,
    GIRoomTypeCode: roomTypeCode,
    GuestName: guestName,
    TotalRooms: totalRooms,
    RoomTypeName: roomTypeName,
    AdultCount: adultCount,
    ChildCount: childCount,
    GIHotelID: user?.live?.easeRoom?.secret,
    OTAupdate: true,
    Meal: meal,
    Phone: contact,
    Email: email,
    BookingSource: bookingSource,
    SpecialNote: specialNote,
    City: city,
    State: state
  };

  const handleEditBooking = () => {

    console.log(booking);

    editOfflineBooking(id, booking)
      .then(async (resp) => {

        console.log("Response: ", resp);
        navigate("/bookings/Allbookings");
        toast.success("Edited Successfully");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAccessToken = async () => {
    try {
      const response = await fetch("https://www.universal-tutorial.com/api/getaccesstoken", {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "api-token": process.env.REACT_APP_API_TOKEN,
          "user-email": process.env.REACT_APP_USER_EMAIL
        }
      });
      const data = await response.json();
      return data.auth_token;
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };


  const fetchStateAndCities = async (selectedState) => {
    try {
      const accessToken = await fetchAccessToken();
      const cityResponse = await fetch(`https://www.universal-tutorial.com/api/cities/${selectedState}`, {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Authorization": "Bearer " + accessToken,
        }
      });
      if (!cityResponse.ok) {
        throw new Error(`HTTP error! Status: ${cityResponse.status}`);
      }
      const cityData = await cityResponse.json();

      setCityList(cityData);
      // setbooking(prevBooking => ({ ...prevBooking, City: cityData }));
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const fetchState = async () => {
    try {
      const accessToken = await fetchAccessToken();
      const stateResponse = await fetch(`https://www.universal-tutorial.com/api/states/India`, {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Authorization": "Bearer " + accessToken,
        }
      });
      if (!stateResponse.ok) {
        throw new Error(`HTTP error! Status: ${stateResponse.status}`);
      }
      const stateData = await stateResponse.json();

      setStateList(stateData);

      await fetchStateAndCities(state);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  useEffect(() => {
    fetchState();
  }, [state]);

  const handleStateChange = async (e) => {
    const selectedState = e.target.value;
    setState(selectedState)
    await fetchStateAndCities(selectedState);
  };

  useEffect(() => {
    console.log(id);
    getSingleoffline(id, user?.live?.easeRoom?.secret)
      .then((res) => {
        const data = res.data[0];

        setRoomTypeName(data.RoomTypeName);
        setCheckin(data.Checkin);
        setCheckOut(data.Checkout);
        setBookingDate(data.BookingDate);
        setBookingId(data.BookingId);
        setBookingSource(data.BookingSource);
        setCity(data.City);
        setState(data.State);
        setGuestName(data.GuestName);
        setAdultCount(data.AdultCount);
        setChildCount(data.ChildCount);
        setContact(data.Phone);
        setEmail(data.Email);
        setSpecialNote(data.SpecialNote);
        setRoomTypeName(data.RoomTypeName);
        setTotalRooms(data.TotalRooms);
        setMeal(data.Meal);
        setRoomTypeCode(data.GIRoomTypeCode);
        setBookingAmount(data.BookingAmount);
        setAdvancePayment(data.AdvancePayment);
        setBalanceLeft(data.BalanceLeft);
        setBookingStatus(data.BookingStatus);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, user?.live?.easeRoom?.secret]);

  const handleChange = (e) => {
    // e.preventDefault();
    console.log(e);

    const { name, value } = e.target;
    // const demobooking = { ...firstBookingData };
    // console.log("Demo booking===> ", demobooking);

    if (name === "BookingAmount") {
      setBookingAmount(value);
      setBalanceLeft(value);
    } else if (name === "AdvancePayment") {
      const newAdvancePayment = value;
      const diff = bookingAmount - newAdvancePayment;
      setAdvancePayment(newAdvancePayment);
      setBalanceLeft(diff);
    } else {
      console.log("No Change");
    }

    if (
      name !== "AdvancePayment" &&
      name === "BookingAmount" &&
      advancePayment !== ""
    ) {
      const newBookingAmount = value;
      const diff = newBookingAmount - advancePayment;
      setBalanceLeft(diff);
    }
  };

  useEffect(() => {
    const payload = {
      Header: {
        Username: user.live.easeRoom.email,
        Password: user.live.easeRoom.password,
      },
      HotelId: user.live.easeRoom.secret,
    };
    setLoading(true);
    getAllRoomType(payload).then(({ data }) => {
      console.log(data);
      const arr = [];
      data.EaseRoomType.map((ele) => {
        arr.push({ id: ele.RoomTypeId, name: ele.RoomTypeName });
      });
      setRoomType([...arr]);
      setLoading(false);
    });
    setLoading(true);
    getRatePlan(payload).then(({ data }) => {
      console.log(data);

      var nameOccurrences = {};
      var uniqueObjects = data?.RatePlan.filter(function (obj) {
        if (!nameOccurrences[obj.RateplanName]) {
          nameOccurrences[obj.RateplanName] = true;
          return true;
        }
        return false;
      });

      const mel = [];

      uniqueObjects.map((ele) => {
        mel.push({ id: ele.RateplanID, name: ele.RateplanName });
      });
      setMealType([...mel]);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#370D6F" />
      <div className="bg-[#F9F9F9]   ">
        <form className="w-full bg-[#ffffff] shadow-lg px-6 m-auto text-xl pb-4">
          <div>
            <h3 class="mb-2 text-center font-bold text-indigo-900 text-2xl tracking-wide">
              Edit Booking
            </h3>
          </div>
          <div class="my-5">
            <fieldset class=" grid grid-cols-4 gap-x-10 border border-solid border-indigo-600 rounded-md pr-8 py-2 shadow-md">
              <legend class="ml-9 bg-[#9A48D0] rounded-md px-4 py-1 text-white shadow-md">
                <h3 class="text-base tracking-wide">Booking Details</h3>
              </legend>
              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="Check-In">
                  Check In
                </label>
                <input
                  class="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2 w-full"
                  name="Checkin"
                  placeHolder="Check In"
                  type="date"
                  // readOnly
                  // disabled={true}
                  value={checkin}
                  onChange={(e) => {
                    setCheckin(e.target.value);
                  }}
                  minDate={moment(new Date()).format("YYYY-MM-DD")}
                  setData={handleChange}
                />
              </div>

              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="Check-Out">
                  Check Out
                </label>
                <input
                  class="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2 w-full"
                  label="Check Out"
                  name="Checkout"
                  placeHolder="Check Out"
                  type="date"
                  // readOnly
                  // disabled={true}
                  minDate={moment(new Date()).format("YYYY-MM-DD")}
                  value={checkOut}
                  onChange={(e) => {
                    setCheckOut(e.target.value);
                  }}
                  setData={handleChange}
                />
              </div>

              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="Booking-Date">
                  Booking Date
                </label>
                <input
                  class="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2 w-full"
                  label="Booking Date"
                  name="BookingDate"
                  placeHolder="Booking Date"
                  type="date"
                  // readOnly
                  value={bookingDate}
                  onChange={(e) => {
                    setBookingDate(e.target.value);
                  }}
                  setData={handleChange}
                />
              </div>

              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="Booking-Id">
                  Booking Id
                </label>
                <input
                  class="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2 w-full"
                  // readOnly
                  label="Booking Id"
                  name="BookingId"
                  placeHolder="Booking Id"
                  type="text"
                  value={bookingId}
                  onChange={(e) => {
                    setBookingId(e.target.value);
                  }}
                  setData={handleChange}
                />
              </div>

              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="Booking-Source">
                  Booking Source
                </label>
                <input
                  class="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2 w-full"
                  label="Booking Source"
                  name="BookingSource"
                  placeHolder="Booking Source"
                  type="text"
                  value={bookingSource}
                  onChange={(e) => {
                    setBookingSource(e.target.value);
                  }}
                  setData={handleChange}
                />
              </div>
              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm">
                  Country
                </label>
                <input
                  class="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2 w-full"
                  label="Country"
                  name="Country"
                  placeHolder="Country"
                  readOnly
                  type="text"
                  value={'India'}
                  // onChange={(e) => {
                  //   setBookingsource(e.target.value);
                  // }}
                  setData={handleChange}
                />
              </div>

              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="State">
                  State
                </label>
                <select className="border border-slate-300 border-solid rounded-md mt-2 w-[100%] text-sm p-2 "
                  name="State"
                  id="State"
                  value={state}
                  onChange={(e) => { handleStateChange(e); }}
                >
                  <option value={null} selected>
                    Select
                  </option>

                  {stateList.map(state => (
                    <option value={state.state_name}>
                      {state.state_name}
                    </option>
                  ))}
                </select>
              </div>
              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="City">
                  City
                </label>
                <select className="border border-slate-300 border-solid rounded-md mt-2 w-[100%] text-sm p-2 "
                  name="City"
                  id="City"
                  value={city}
                  onChange={(e) => { setCity(e.target.value); }}
                >

                  <option value={null} selected>
                    Select
                  </option>

                  {cityList.map((city, index) => (
                    <option key={index} value={city.city_name}>
                      {city.city_name}
                    </option>
                  ))}

                </select>
              </div>
            </fieldset>
          </div>

          <div class="mb-5">
            <fieldset class=" grid grid-cols-4 gap-x-10 border border-solid border-indigo-600 rounded-md pr-8 py-2 shadow-md">
              <legend class="ml-9 bg-[#9A48D0] rounded-md px-4 py-1 text-white shadow-md">
                <h3 class="text-base tracking-wide">Guest Details</h3>
              </legend>

              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="Guest-Name">
                  Guest Name
                </label>
                <input
                  class="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2 w-full"
                  label="Guest Name"
                  name="GuestName"
                  placeHolder="Guest Name"
                  type="text"
                  value={guestName}
                  onChange={(e) => {
                    setGuestName(e.target.value);
                  }}
                  // onChange={editChange}
                  setData={handleChange}
                />
              </div>

              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="Adult-Count">
                  Adult Count
                </label>
                <input
                  class="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2 w-full"
                  label="Adult Count"
                  name="AdultCount"
                  placeHolder="Adult Count"
                  type="text"
                  value={adultCount}
                  onChange={(e) => {
                    setAdultCount(e.target.value);
                  }}
                  setData={handleChange}
                />
              </div>

              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="Child-Count">
                  Child Count
                </label>
                <input
                  class="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2 w-full"
                  label="Child Count"
                  name="ChildCount"
                  placeHolder="Child Count"
                  type="text"
                  value={childCount}
                  onChange={(e) => {
                    setChildCount(e.target.value);
                  }}
                  setData={handleChange}
                />
              </div>

              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="Contact-Number">
                  Contact Number
                </label>
                <input
                  class="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2 w-full"
                  label="Contact Number"
                  name="ContactNumber"
                  placeHolder="Contact Number"
                  maxLength="12"
                  type="number"
                  value={contact}
                  onChange={(e) => {
                    setContact(e.target.value);
                  }}
                  setData={handleChange}
                />
              </div>
              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="Email">
                  Email
                </label>
                <input
                  class="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2 w-full"
                  label="Email"
                  name="Email"
                  placeHolder="Email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  setData={handleChange}
                />
              </div>

              <div class="flex flex-col m-4 col-span-2 w-full">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="Special-note">
                  Special Note
                </label>
                <textarea
                  class="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2  outline-none "
                  rows="1"
                  name="SpecialNote"
                  placeHolder="Special Note"
                  type="text"
                  value={specialNote}
                  onChange={(e) => {
                    handleChange(e);
                    setSpecialNote(e.target.value);
                  }}
                />
              </div>
            </fieldset>
          </div>

          <div class="mb-5">
            <fieldset class=" grid grid-cols-4 gap-x-10 border border-solid border-indigo-600 rounded-md pr-8 py-2 shadow-md">
              <legend class="ml-9 bg-[#9A48D0] rounded-md px-4 py-1 text-white shadow-md">
                <h3 class="text-base tracking-wide">Room & Payment Details</h3>
              </legend>

              <div className={`text-start w-full m-4 `}>
                {
                  <label htmlFor={"roomType"} className="block text-sm font-bold text-[#370D6F]">
                    {"RoomType"}
                  </label>
                }

                <select
                  className="border border-slate-300 border-solid rounded-md mt-2 w-[100%] text-sm p-2 "
                  name="RoomTypeName"
                  id="RoomTypeName"
                  value={roomTypeName}
                  onChange={(e) => {
                    handleChange(e);
                    setRoomTypeName(e.target.value);
                  }}
                >
                  <option value={null}>Select</option>
                  {roomType &&
                    roomType.map((ele) => (
                      <option value={ele.name}>{ele.name}</option>
                    ))}
                </select>
              </div>

              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="Total-Rooms">
                  Total Rooms
                </label>
                <input
                  class="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2 w-full"
                  label="Total Rooms"
                  name="TotalRooms"
                  placeHolder="Total Rooms"
                  type="text"
                  value={totalRooms}
                  onChange={(e) => {
                    setTotalRooms(e.target.value);
                  }}
                  setData={handleChange}
                />
              </div>

              <div className={`text-start w-full m-4 `}>
                {
                  <label htmlFor={"roomType"} className="block text-sm font-bold text-[#370D6F]">
                    {"Meal plan"}
                  </label>
                }

                <select
                  className="border border-slate-300 border-solid rounded-md mt-2 w-[100%] text-sm p-2 "
                  name="meal"
                  id="roomType"
                  value={meal}
                  onChange={(e) => {
                    handleChange(e);
                    setMeal(e.target.value);
                  }}
                >
                  <option value={null}>Select</option>
                  {mealType &&
                    mealType.map((ele) => (
                      <option value={ele.name}>{ele.name}</option>
                    ))}
                </select>
              </div>

              <InputGrp
                label="GI Hotel ID"
                name="GIHotelID"
                placeHolder="GI Hotel ID"
                type="text"
                value={user.live.easeRoom.secret}
                setData={handleChange}
              />

              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="Booking-Amount">
                  Booking Amount
                </label>
                <input
                  class="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2 w-full"
                  label="Booking Amount"
                  name="BookingAmount"
                  placeHolder="Booking amount"
                  type="number"
                  value={bookingAmount}
                  onChange={(e) => {
                    handleChange(e);
                    setBookingAmount(e.target.value);
                  }}
                  setData={handleChange}
                />
              </div>

              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="Advance-Payment">
                  Advance Payment
                </label>
                <input
                  class="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2 w-full"
                  label="Advance Payment"
                  name="AdvancePayment"
                  placeHolder="Advance Payment"
                  type="number"
                  value={advancePayment}
                  onChange={(e) => {
                    setAdvancePayment(e.target.value);
                    handleChange(e);
                  }}
                  setData={handleChange}
                />
              </div>

              <div class="flex flex-col m-4">
                <label class="text-[#370D6F] font-bold text-sm" htmlFor="Balance-left">
                  Balance Left
                </label>
                <input
                  class="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2 w-full"
                  name="BalanceLeft"
                  placeholder="Balance left"
                  type="number"
                  readOnly
                  value={balanceLeft}
                  onChange={(e) => {
                    setBalanceLeft(e.target.value);
                  }}
                  setData={handleChange}
                />
              </div>

              <div className={`text-start w-full m-4 `}>
                {
                  <label htmlFor={"bookingStatus"} className="block text-sm font-bold text-[#370D6F]">
                    {"Booking Status"}
                  </label>
                }

                <select
                  className="border border-slate-300 border-solid rounded-md mt-2 w-[100%] text-sm p-2 "
                  name="BookingStatus"
                  id="bookingStatus"
                  value={bookingStatus}
                  onChange={(e) => {
                    // handleChange(e);
                    setBookingStatus(e.target.value);
                  }}
                >
                  <option value={null}>Select</option>
                  <option value="Booked">Confirmed</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
            </fieldset>
          </div>

          {/* </div> */}

          <div class="text-center pt-1 mb-5 pb-1">
            <button
              class="mb-3 px-5 py-1 bg-[#8F00FF] text-[#ffffff] text-md rounded-lg shadow-lg hover:bg-[#B660CD] duration-200 ease-in"
              type="button"
              // style={{
              //   background:
              //     "linear-gradient(90deg, rgba(37,3,124,1) 0%, rgba(6,6,157,1) 16%, rgba(179,0,255,1) 100%)",
              // }}
              onClick={(e) => handleEditBooking(e)}
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default EditBookingForm;
