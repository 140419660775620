import React, { useEffect, useState } from "react";
import {
  AnalyticsTable,
  GetRoomTypePercentage,
  GetRatePlanPercentage,
} from "../../core/Services/Data/performance";
// import { Bar } from "react-chartjs-2";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
} from "recharts";
import { getMeuser } from "../../core/Services/User/login/login";
const Analytics = () => {
  const [cond, setCond] = React.useState("month");
  const [tableData, setTableData] = useState([]);
  const [rnsData, setRnsData] = useState(null);
  const [revenueData, setRevenueData] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [ratePlanData, setRatePlanData] = useState(null);
  const [user, setUser] = useState(false);
  const [totals, setTotals] = useState({
    roomNight: 0,
    revenue: 0,
    roomUnitSold: 0,
    planUnitSold: 0,
  });
  const options = [
    { id: "month", name: "This Month" },
    { id: "year", name: "This Year" },
  ];

  useEffect(() => {
    AnalyticsTable(cond).then(async (res) => {
      console.log(res);

      const user = await getMeuser();
      console.log(user, "user");

      setTableData(res);

      // const data = {
      //   labels: res?.result?.map((data) => data?.bookingSource),
      //   datasets: res?.result?.map((data) => {
      //     return {
      //       label: data?.bookingSource,
      //       data: data?.totalNights,
      //       backgroundColor:
      //         data?.bookingSource === "MakeMyTrip"
      //           ? "#8BD3C7"
      //           : data?.bookingSource === "Expedia"
      //           ? "#FDCCE5"
      //           : "#BEB9DB",
      //     };
      //   }),
      // };

      const data = res?.result?.map((data) => {
        return {
          name: data?.bookingSource,
          value: data?.totalNights,
          fill:
            data?.bookingSource === "MakeMyTrip"
              ? "#6F0D6B"
              : data?.bookingSource === "Goibibo"
              ? "#D1D5DB"
              : data?.bookingSource === "Booking.com"
              ? "#C4B5FD"
              : data?.bookingSource === "Agoda"
              ? "#BEF264"
              : data?.bookingSource === "Airbnb"
              ? "#D8B4FE"
              : data?.bookingSource === "Expedia"
              ? "#FCA5A5"
              : data?.bookingSource === "Cleartrip"
              ? "#A5B4FC"
              : data?.bookingSource === "Yatra"
              ? "#370E6F"
              : data?.bookingSource === "Travelguru"
              ? "#64748B"
              : "#000",
        };
      });
      console.log(data);
      const data1 = res?.result?.map((data) => {
        return {
          name: data?.bookingSource,
          uv: data?.totalAmount?.toFixed(2),
          fill:
            data?.bookingSource === "MakeMyTrip"
              ? "#6F0D6B"
              : data?.bookingSource === "Goibibo"
              ? "#D1D5DB"
              : data?.bookingSource === "Booking.com"
              ? "#C4B5FD"
              : data?.bookingSource === "Agoda"
              ? "#BEF264"
              : data?.bookingSource === "Airbnb"
              ? "#D8B4FE"
              : data?.bookingSource === "Expedia"
              ? "#FCA5A5"
              : data?.bookingSource === "Cleartrip"
              ? "#A5B4FC"
              : data?.bookingSource === "Yatra"
              ? "#370E6F"
              : data?.bookingSource === "Travelguru"
              ? "#64748B"
              : "#000",
        };
      });
      let roomNigth = 0;
      let revenue = 0;
      let roomUnitSold = 0;
      let planUnitSold = 0;

      res?.result?.map((data) => {
        roomNigth += data?.totalNights;
        revenue += data?.totalAmount;
        roomUnitSold += data?.roomCount;
        planUnitSold += data?.ratePlanCount;
      });

      setTotals({
        roomNight: roomNigth,
        revenue: revenue,
        roomUnitSold: roomUnitSold,
        planUnitSold: planUnitSold,
      });

      console.log(data);
      setRnsData(data);
      setRevenueData(data1);
    });
    GetRoomTypePercentage(cond).then((res) => {
      console.log(res);
      const data = res?.map((item) => {
        return {
          name: item?.room,
          value: Number(item?.percentage),
        };
      });
      console.log(data);
      setGraphData(data);
    });
    GetRatePlanPercentage(cond).then((res) => {
      console.log(res);
      const data = res?.map((item) => {
        return {
          name: item?.room,
          value: Number(item?.percentage),
        };
      });
      console.log(data,"ratePlan");
      setRatePlanData(data);
    });
  }, [cond]);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884d8"];
  console.log(totals);

  function calculation(arrOfOb, colName) {
    const arrOfVal = arrOfOb.reduce((acc, curr) => {
      return [...acc, curr[colName]];
    }, []);
    const newAr = arrOfVal.sort((a, b) => a - b);
    return newAr;
  }
  const colors = [
    "#FD7F6F",
    "#ff7b7b",
    "#7EB0D5",
    "#B2E061",
    "#BD7EBE",
    "#FFB55A",
    "#FFEE65",
    "#BEB9DB",
    "#FDCCE5",
  ];
  function getColor(val, arr, colName) {
    const ar = calculation(arr, colName);
    return colors[ar.indexOf(val)];
  }

  return (
    <>
      <div
        className="absolute top-0 left-0 w-full bg-[#FBF9FF] h-full"
        style={{ zIndex: "-1" }}
      ></div>
      <div className="p-4 flex flex-col gap-4 w-full">
        <div className="flex justify-end">
          <select
            onChange={(e) => {
              setCond(e.target.value);
            }}
            className="cursor-pointer px-4"
            value={cond}
          >
            {options.map((option) => (
              <option value={option.id} key={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="w-full grid grid-cols-12 gap-4 ">
        <table className="w-full border-collapse border-2 border-slate-800 table-auto col-span-10 ">
          <thead className="bg-[#370D6F]  ">
            <tr className="">
              <th
                className="text-white font-bold py-2 px-2"
                style={{ border: "0.25px solid #000" }}
              >
                OTA list with room night
              </th>
              <th
                className="text-white  py-6 px-2 "
                style={{ border: "0.25px solid #000" }}
              >
                Room Night
              </th>
              {/* <th className="text-white  py-6 px-2 bg-[#FBF9FF] "></th> */}
              <th
                className="text-white font-bold py-2 px-2 "
                style={{ border: "0.25px solid #000" }}
              >
                Revenue
              </th>
              {/* <th className="text-white  py-6 px-2 bg-[#FBF9FF] "></th> */}
              <th
                className="text-white font-bold py-2 px-2 "
                style={{ border: "0.25px solid #000" }}
              >
                Top Room
              </th>
              <th
                className="text-white py-2 px-2 "
                style={{ border: "0.25px solid #000" }}
              >
                Unit Sold
              </th>
              {/* <th className="text-white  py-6 px-2 bg-[#FBF9FF] "></th> */}
              <th className="text-white font-bold py-2 px-2 ">
                Total meal plan
              </th>
              <th className="text-white py-2 px-2 ">Unit Sold</th>
              {/* <th className="text-white  py-6 px-2 bg-[#FBF9FF] "></th> */}
              {/* <th className="text-white font-bold py-2 px-2 ">ARR</th> */}
            </tr>
          </thead>
          <tbody>
            {tableData?.result &&
              tableData?.result?.map((data, i) => {
                return (
                  <tr>
                    <td
                      className={`border border-slate-800 text-center py-3 px-3 `}
                      style={{ border: "0.25px solid #000" }}
                    >
                      {data?.bookingSource}
                    </td>
                    <td
                      className={`border border-slate-800 text-center py-3 px-3 bg-[${getColor(
                        data.totalNights,
                        tableData?.result,
                        "totalNights"
                      )}] `}
                      style={{
                        border: "0.25px solid #000",
                      }}
                    >
                      {data?.totalNights}
                    </td>
                    <td
                      className={`border border-slate-800 text-center py-3 px-3 bg-[${getColor(
                        data.totalAmount,
                        tableData?.result,
                        "totalAmount"
                      )}] `}
                      style={{ border: "0.25px solid #000" }}
                    >
                      {data?.totalAmount?.toFixed(2)}
                    </td>
                    <td
                      className={`border border-slate-800 text-center py-3 px-3  `}
                      style={{ border: "0.25px solid #000" }}
                    >
                      {data?.mostFrequentRoomName}
                    </td>
                    <td
                      className={`border border-slate-800 text-center py-3 px-3 bg-[${getColor(
                        data.roomCount,
                        tableData?.result,
                        "roomCount"
                      )}] `}
                      style={{ border: "0.25px solid #000" }}
                    >
                      {data?.roomCount}
                    </td>
                    <td
                      className={`border border-slate-800 text-center py-3 px-3 ] `}
                      style={{ border: "0.25px solid #000" }}
                    >
                      {data?.mostFrequentRatePlan}
                    </td>
                    <td
                      className={`border border-slate-800 text-center py-3 px-3 bg-[${getColor(
                        data.ratePlanCount,
                        tableData?.result,
                        "ratePlanCount"
                      )}] `}
                      style={{ border: "0.25px solid #000" }}
                    >
                      {data?.ratePlanCount}
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td
                className="text-center p-3 bg-[#F8F2FF] font-semibold "
                style={{ border: "1px solid #370D6F" }}
              >
                Total
              </td>
              <td
                className="text-center p-3 bg-[#F8F2FF]"
                style={{ border: "1px solid #370D6F" }}
              >
                {tableData?.allRoomNight}
              </td>
              <td
                className="text-center p-3 bg-[#F8F2FF]"
                style={{ border: "1px solid #370D6F" }}
              >
                {tableData?.revenue?.toFixed(2)}
              </td>
              <td
                className="text-center p-3 bg-[#F8F2FF]"
                style={{ border: "1px solid #370D6F" }}
              >
                {tableData?.mostFrequentRoomName}
              </td>
              <td
                className="text-center p-3 bg-[#F8F2FF]"
                style={{ border: "1px solid #370D6F" }}
              >
                {tableData?.roomCount}
              </td>
              <td
                className="text-center p-3 bg-[#F8F2FF]"
                style={{ border: "1px solid #370D6F" }}
              >
                {tableData?.mostFrequentRatePlan}
              </td>
              <td
                className="text-center p-3 bg-[#F8F2FF]"
                style={{ border: "1px solid #370D6F" }}
              >
                {tableData?.ratePlanCount}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="w-full h-full border-collapse border-2 border-slate-800 table-auto col-span-2 ">
          <thead className="bg-[#370D6F]  ">
            <tr className="">
              <th
                className="text-white font-bold py-6 px-6"
                style={{ border: "0.25px solid #000" }}
              >
                ARR
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className="text-center p-3 bg-[#F8F2FF] flex items-center justify-center h-full font-inter font-bold text-xl"
                style={{ border: "1px solid #370D6F" }}
              >
                {tableData?.arr?.toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="w-full grid grid-cols-12 gap-4 p-8">
        <div className="w-full flex flex-col gap-2 items-center justify-center p-4 bg-white col-span-7 ">
          <h3 className="text-sm font-semibold ">
            Room Night Sold vs OTA Name
          </h3>
          {rnsData && (
            <ResponsiveContainer width={650} height={325}>
              <BarChart
                width={600}
                height={400}
                data={rnsData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />

                <Legend />

                <Bar dataKey="value" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
        <div className="w-full flex flex-col gap-2 col-span-4 bg-white p-5 flex items-center justify-center">
          <h3 className="text-sm font-semibold ">Top Room VS Unit Sold</h3>
          {graphData && (
            <ResponsiveContainer width={"100%"} height={300}>
              <PieChart width={600} height={400}>
                <Pie
                  data={graphData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  fill="#8884d8"
                  outerRadius={120}
                  // label={({
                  //   cx,
                  //   cy,
                  //   midAngle,
                  //   innerRadius,
                  //   outerRadius,
                  //   value,
                  //   index,
                  // }) => {
                  //   const RADIAN = Math.PI / 180;
                  //   // Calculate the angle for label positioning
                  //   const angle = -midAngle * RADIAN;

                  //   // Calculate the x and y coordinates for label positioning
                  //   const x = cx + (outerRadius + 10) * Math.cos(-angle);
                  //   const y = cy + (outerRadius + 10) * Math.sin(-angle);

                  //   // Customize the label content here
                  //   return (
                  //     <text
                  //       x={x}
                  //       y={y}
                  //       fill={COLORS[index % COLORS.length]}
                  //       textAnchor={x > cx ? "start" : "end"}
                  //       dominantBaseline="central"
                  //       className="text-xs"
                  //     >
                  //       {`${graphData[index].name} - ${value}%`}{" "}
                  //     </text>
                  //   );
                  // }}
                />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
      <div className="w-full grid grid-cols-12 gap-4 p-8">
        <div className="w-full flex items-center justify-center p-4 bg-white col-span-7 flex-col gap-2 ">
          <h3 className="text-sm font-semibold ">Revenue vs OTA Name</h3>
          {rnsData && (
            <ResponsiveContainer width={650} height={325}>
              <BarChart
                width={800}
                height={400}
                data={revenueData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />

                <Legend />

                <Bar dataKey="uv" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
        <div className="w-full col-span-5 flex flex-col gap-2 items-center justify-center ">
        <h3 className="text-sm font-semibold ">Top meal Plan VS Unit Sold</h3>
          {ratePlanData && (
            <ResponsiveContainer width="100%" height={300}>
              <PieChart width={600} height={400}>
                <Pie
                  data={ratePlanData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  fill="#6F0D6B"
                  outerRadius={120}
                />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </>
  );
};

export default Analytics;
