import React, { useRef, useState, useEffect, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import zonzo from '../../Assests/images/zonzologof3.png';
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import { updateNoShow } from "../../core/Services/Data/EaseRoom";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
// import {getAllBookingFromOta} from "../../core/Services/Data/EaseRoom"

const AllBookings = ({ user, data1 }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [gridApi, setGridApi] = useState(null);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [bookId, setBookId] = useState();

  useEffect(() => {
    if (data1) {
      const sortedArray = [...data1]; // Create a copy of the original array
      sortedArray.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort by createdAt in descending order
      setData(sortedArray);
    }
  }, [data1]);

  const navigate = useNavigate();
  const gridRef = useRef(null);

  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };

  //handle no show button operation
  const handleNoShow = () => {
    setOpen(false)

    const noShowPayload = {
      Header: {
        Username: user?.live?.easeRoom?.email,
        Password: user?.live?.easeRoom?.password,
      },
      HotelId: user?.live?.easeRoom?.secret,
      BookingId: bookId,
      BookingSource: "Booking.com"
    }

    console.log("NoShowPayload", noShowPayload);

    updateNoShow(noShowPayload)
      .then((resp) => {
        console.log("No show updated successfully", resp.data);
        toast.success("No Show Updated Successfully");
      })
      .catch(error => {
        console.log(error);
        toast.error(error.message)
      })

  }

  const handleNavigate = (id, type) => {
    if (type === "easeroom") {
      navigate("/bookings/Allbookings/easeroomdetails/" + id);
    }
    if (type === "Yatra") {
      navigate("/bookings/Allbookings/yatradetails/" + id);
    }
    if (type == "offline") {
      navigate("/bookings/Allbookings/offline/" + id);
    }
  };

  const Imagerenderer = (props) => {
    console.log("=====> check", props?.data?.BookingSource?.toLowerCase());

    if (props?.data?.BookingSource?.toLowerCase() === "makemytrip") {
      return (
        <img
          src={"https://s3-symbol-logo.tradingview.com/makemytrip--600.png"}
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "booking.com") {
      return (
        <img
          src={
            "https://logos-world.net/wp-content/uploads/2021/08/Booking-Symbol.png"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "agoda") {
      return (
        <img src={"/Assets/Images/agoda.png"} className="h-8 w-8" alt="photo" />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "goibibo") {
      return (
        <img
          src={
            "https://aniportalimages.s3.amazonaws.com/media/details/Goibibo_IWaL00BWWWW.jpg"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "expedia") {
      return (
        <img
          src={
            "https://logowik.com/content/uploads/images/expedia-new-20233800.logowik.com.webp"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "travelguru") {
      return (
        <img
          src={
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMpVUvmvQ8_tGWhyDTnQX07dzB4Ap5yoh4SFrYPT8b&s"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "easemytrip") {
      return (
        <img
          src={
            "https://upload.wikimedia.org/wikipedia/commons/1/13/Easemytrip.jpg"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "happyeasygo") {
      return (
        <img
          src={
            "https://www.pngkey.com/png/detail/259-2599780_happyeasygo-happyeasygo-logo.png"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "via") {
      return (
        <img
          src={
            "https://www.newzealand.com/assets/Tourism-NZ/Other/c7e312de67/img-1541873987-8187-27595-0D7C344A-D9EF-561E-65E090FFAB7A6362__aWxvdmVrZWxseQo_CropResizeWzQ4MCxudWxsLDkwLCJwbmciXQ.png"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "hobse") {
      return (
        <img
          src={"https://www.hobse.com/images/hobse-logo.png"}
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "hostelworld") {
      return (
        <img
          src={
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmA0D1EFpTrkeIOy_Zy266JA-Cgopae36nBkE7pjws&s"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "cleartrip") {
      return (
        <img
          src={"https://etimg.etb2bimg.com/photo/94049186.cms"}
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "airbnb") {
      return (
        <img
          src={
            "https://www.digital.ink/wp-content/uploads/airbnb_logo_detail.jpg"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.type?.toLowerCase() === "yatra") {
      return (
        <img
          src={
            "https://ik.imgkit.net/3vlqs5axxjf/PCWW/uploadedImages/Articles/Interviews/2022/July/yatra%20logo.png?tr=w-270%2Ch-270%2Cfo-auto"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.type == "offline") {
      return <img src={zonzo} className="h-5 w-5 mt-2" alt="photo" />;
    }
  };

  const [columnDefs] = useState([
    {
      headerName: "Channel",
      suppressMenu: true,
      resizable: true,
      field: "BookingSource",
      // floatingFilter : false,
      cellRenderer: Imagerenderer,
    },
    {
      headerName: "Room Type",
      suppressMenu: true,
      resizable: true,
      field: "RoomTypeName",
    },
    {
      headerName: "Guest Name",
      suppressMenu: true,
      resizable: true,
      field: "GuestName",
    },
    {
      headerName: "Booking Date",
      suppressMenu: true,
      resizable: true,
      field: "BookingDate",
      filter: "agTextColumnFilter",
      valueGetter: (e) => {
        return moment(e.data.BookingDate).format("DD-MMM-YYYY");
      },
    },
    {
      headerName: "Check In - Check Out",
      suppressMenu: true,
      resizable: true,
      field: "Checkin",
      valueGetter: (e) => {
        const start = new Date(e.data.Checkin);
        const end = new Date(e.data.Checkout);
        const final = moment(start).format("DD-MMM-YYYY") + " - " + moment(end).format("DD-MMM-YYYY");
        return final;
      },
    },
    {
      headerName: "Status",
      suppressMenu: true,
      resizable: true,
      field: "BookingStatus",
      valueGetter: (e) => {
        // console.log("status", e.data.BookingStatus)
        if (e.data.BookingStatus == "Booked") {
          return "Confirmed";
        } else {
          return e.data.BookingStatus;
        }
      },
    },
    {
      headerName: "Action",
      suppressMenu: true,
      resizable: true,
      cellRenderer: (e) => {
        const checkinDate = new Date(e.data.Checkin);
        checkinDate.setHours(24, 0, 0, 0);
        const checkoutDate = new Date(e.data.Checkout);
        checkoutDate.setHours(60, 0, 0, 0);
        const currentDate = new Date();

        if (currentDate >= checkinDate && currentDate <= checkoutDate && e.data.BookingSource == "Booking.com" && e.data.BookingStatus == "Booked") {
          return (
            <button style={{ background: "linear-gradient(90deg, #25037c 0%, #06069d 16%, #b300ff 100%)" }}
              className="w-3/4 text-center h-4/5 text-[#ffffff] text-lg rounded-lg"
              onClick={() => { setOpen(true); setBookId(e.data.BookingId) }}
            >
              No Show
            </button>
          )
        } else {
          return null;
        }
      }
    }
  ]);

  console.log("Data--->", data);

  useEffect(() => {
    const today = searchParams.get("today");

    if (today === "true" && gridApi) {
      const colId = "BookingDate"; // Change this to the actual column ID or field
      const filterInstance = gridApi.getFilterInstance(colId);
      filterInstance.setModel({
        type: "equals", // Set the filter type as needed
        filter: moment(new Date()).format("DD-MMM-YYYY"),
      });
      filterInstance.applyModel();
      gridApi.onFilterChanged(); // Apply the filter to the grid
    }

  }, [gridApi]);


  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  return (
    <>
      <div className="mt-5 ">
        <div className="ag-theme-alpine" style={{ height: "600px", width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            rowData={data}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={10}
            overlayNoRowsTemplate={"No Booking To Show"}
            rowSelection="multiple"
            onRowDoubleClicked={(e) => {
              console.log("Id:", e.data._id);
              handleNavigate(e.data._id, e.data.type);
            }}
            onGridReady={onGridReady}
          ></AgGridReact>
        </div>
      </div>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col gap-6 items-center">
            <h3 className="text-lg text-left">
              Are you sure to mark this booking as No-Show.<br /> Confirm your decision.
            </h3>
            <div className="flex gap-4 justify-end w-full">
              <Button
                variant="contained"
                sx={{ color: "white" }}
                fullWidth
                color="secondary"
                onClick={() => { handleNoShow() }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                sx={{ color: "white" }}
                fullWidth
                onClick={() => setOpen(false)}
              >
                No
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AllBookings;
