import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";

const NavbarSupport = () => {
  const path = useLocation().pathname;
  return (
    <div className="card rounded-r-none br-8 reports-navbar col-span-5">
      <div className="card-content">
        <Link
          to="/Support/active"
          className={path.includes("active") ? "active" : ""}
        >
          Active
        </Link>
        <Link
          to="/Support/closed"
          className={path.includes("closed") ? "active" : ""}
        >
          Closed
        </Link>
        
      </div>
    </div>
  );
};

export default NavbarSupport;
