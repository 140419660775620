import React, { useCallback, useEffect, useState } from 'react';
import { changePasswordCall, logout } from '../../core/Services/User/login/login';
import { toast } from "react-toastify";
import { FiEye, FiEyeOff } from 'react-icons/fi';
import {  useNavigate } from 'react-router-dom';

const ChangePasswordCard = ({ setchangePassword, changePassword }) => {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  let navigate = useNavigate()

  const showPasswordHandler = useCallback(() => {
    setShowPassword(!showPassword)
  }, [showPassword])


  const showRepeatPasswordHandler = useCallback(() => {
    setShowRepeatPassword(!showRepeatPassword)
  }, [showRepeatPassword])

  const submitForm = () => {
    if (password === repeatPassword) {
      const confirmation = window.confirm('Are you sure you want to change the password?');

      if (confirmation) {
        // Assuming you have an auth-token stored in authToken variable
        const authToken = 'your-auth-token';

        // Make a POST request to the API
        changePasswordCall(password)
          .then(response => {
            // Handle the response as needed
            console.log(response)
            setchangePassword(false)
            toast.success("Password successfully changed!")
            logout()
            navigate("/")
          })
          .catch(error => {
            console.error('Error:', error);
            toast.error("Unable to change the password")
          });
      }
    } else {
      alert('Passwords do not match!');
    }
  };

  const handleClick = (e) => {
    setchangePassword(false)
  }


  return (
    <>
      <div className='fixed w-screen h-screen bg-slate-900 opacity-30 blur-lg backdrop-blur-[1.5px] top-0 left-0'></div>
      <div id='changePassword' className=" bg-[#ebebeb] border border-gray-200 z-50 rounded-lg drop-shadow-lg shadow-xl fixed  top-[45%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="bg-[#ffffff] mx-auto my-auto p-8 rounded-md h-80  w-96">
          <div className=' flex'>
            <h2 className="text-2xl font-semibold mb-4">Change Password</h2>
            <button onClick={handleClick} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-sm font-medium text-gray-600">Password</label>
            <div className='password-input'>
              <input
                id="password"
                name="password"
                placeholder="Enter new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 p-2 w-full border rounded-md"
                type={showPassword ? 'text' : 'password'} required
              />
              {showPassword ? <FiEye size={16} onClick={showPasswordHandler} /> : <FiEyeOff size={16} onClick={showPasswordHandler} />}
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="repeatPassword" className="block text-sm font-medium text-gray-600">Confirm Password</label>
            <div className='password-input'>

              <input
                type={showRepeatPassword ? 'text' : 'password'} required
                id="repeatPassword"
                name="repeatPassword"
                placeholder="Confirm new password"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
                className="mt-1 p-2 w-full border rounded-md"
              />
              {showRepeatPassword ? <FiEye size={16} onClick={showRepeatPasswordHandler} /> : <FiEyeOff size={16} onClick={showRepeatPasswordHandler} />}
            </div>
          </div>
          <div className='w-full flex mt-8'>
            <button
              type="button"
              onClick={submitForm}
              className="bg-purple-500 text-white mx-auto items-center p-2 w-9/12 rounded-md"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePasswordCard;
