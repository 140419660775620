import httpService from "../HttpService/httpService";

const fileUpload = (File) => {
  let formData = new FormData();
  formData.append("file", File);
  formData.append("name", File.name);
  //   console.log(formData, File);
  return httpService.post("fileUpload", formData);
};

export default fileUpload;
