import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { DateRangePicker } from "react-date-range";
import { BsCalendarDate } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {
  getAllRates,
  getAllRoomType,
  getRatePlan,
  getbookingRates,
  updateRates,
} from "../../../core/Services/Data/EaseRoom";
import Toggledays from "../../../shared/components/Toggledays/Toggledays";
import moment from "moment";
import { IoPerson } from "react-icons/io5";

const BulkUpdateRates = ({ user }) => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    startDate: new Date(),
    endDate: new Date(),
    roomType: 1,
    reportType: 1,
  });

  const [openDateDropdown, setOpenDateDropdown] = useState(false);

  const [eat, setEat] = useState([]);
  const [list, setList] = useState([]);
  const [rates, setRates] = useState([]);

  const [workingDays, setWorkingDays] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [workingDaysError, setWorkingDaysError] = useState(false);
  const [roomTypes, setRoomTypes] = useState([]);
  const groupByRoomType = (array) =>
    array.reduce((result, current) => {
      (result[current.RoomTypeName] = result[current.RoomTypeName] || []).push(
        current
      );
      return result;
    }, {});
  const groupedEat = groupByRoomType(eat);

  const handleInputChange = (index, field, value) => {
    const updatedList = [...list];
    updatedList[index][field] = value;
    setList(updatedList);
  };

  useEffect(() => {
    if (eat.length > 0) {
      const initialList = eat.map((el) => ({
        FourAdults: 0,
        ThreeAdults: 0,
        TwoAdults: 0,
        OneAdult: 0,
        ExtraAdult: 0,
        ExtraChild: 0,
        ExtraInfant: 0,
      }));
      setList(initialList);
    }
  }, [eat]);

  const getWorkingDays = (days) => {
    setWorkingDays((prevState) => {
      if (days.length === 0) {
        setWorkingDaysError((prevState) => {
          return true;
        });
      } else {
        setWorkingDaysError((prevState) => {
          return false;
        });
      }
      return days;
    });
  };
  const dateRangeHandler = (event) => {
    setFilters({
      ...filters,
      startDate: event.selection.startDate,
      endDate: event.selection.endDate,
    });
  };

  const handleSumbit = (e) => {
    e.preventDefault();

    let { startDate, endDate } = filters;
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    const date1 = moment(startDate).format("DD/MM/YYYY");
    const date2 = moment(endDate).format("DD/MM/YYYY");

    const selectedIndexes = list
      .map((el, index) =>
        el.OneAdult !== 0 ||
        el.TwoAdults !== 0 ||
        el.ThreeAdults !== 0 ||
        el.FourAdults !== 0 ||
        el.ExtraAdult !== 0 ||
        el.ExtraChild !== 0 ||
        el.ExtraInfant !== 0
          ? index
          : null
      )
      .filter((index) => index !== null);

    const payloadArray = selectedIndexes.map((index) => ({
      Header: {
        Username: user?.live?.easeRoom?.email,
        Password: user?.live?.easeRoom?.password,
      },
      HotelId: user?.live?.easeRoom?.secret,
      RoomTypeId: eat[index].RoomTypeId,
      RateplanId: eat[index].RateplanID,
      RateInfo: `${date1}|${date2}|${list[index].OneAdult},${
        list[index].TwoAdults
      },${list[index].ThreeAdults},${list[index].FourAdults},${
        list[index].ExtraAdult
      },${list[index].ExtraChild},0|${
        workingDays.length > 0 ? workingDays.sort().join() : "1,2,3,4,5,6,7"
      }`,
    }));

    Promise.all(
      payloadArray.map((payload, index) => {
        setTimeout(async () => {
          console.log(payload);
          await updateRates(payload);
        }, index * 300);
      })
    )
      .then((resp) => {
        console.log("Response:", resp);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });

    toast.dismiss();
    toast.success("Updated Successfully");
    setTimeout(() => {
      navigate("/rates-inventory");
    }, 500);
  };

  useEffect(() => {
    const payload = {
      Header: {
        Username: user?.live?.easeRoom?.email,
        Password: user?.live?.easeRoom?.password,
      },
      HotelId: user?.live?.easeRoom?.secret,
    };

    getAllRoomType(payload).then(({ data }) => {
      console.log(data, "roomType");
      setRoomTypes(data?.EaseRoomType);
    });

    getRatePlan(payload).then(({ data }) => {
      console.log(data, "ratePlan");
      setEat(data.RatePlan);
    });
  }, []);

  const dateRangeContainerRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (
      dateRangeContainerRef.current &&
      !dateRangeContainerRef.current.contains(event.target)
    ) {
      // Clicked outside the date range picker, update state or perform actions
      console.log("Clicked outside");
      setOpenDateDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const payload = {
      startDate: moment(filters.startDate).format("DD/MM/YYYY"),
      endDate: moment(filters.endDate).format("DD/MM/YYYY"),
    };

    getAllRates(payload).then(async ({ data }) => {
      setRates(data.rates);
    });
  }, [filters]);

  return (
    <div>
      <section className="card br-8 filters-section">
        <div className="filters">
          <div className="date filter">
            <label>Select Date*</label>
            <div
              className={openDateDropdown ? "dropdown active" : "dropdown"}
              onClick={() => setOpenDateDropdown(true)}
              ref={dateRangeContainerRef}
              style={{ cursor: "pointer" }}
            >
              <p>
                <span>
                  {moment(filters.startDate).format("DD MMMM Y")} -{" "}
                  {moment(filters.endDate).format("DD MMMM Y")}
                </span>
                <button>
                  <BsCalendarDate size={16} />
                </button>
              </p>

              <DateRangePicker
                className="date-range"
                ranges={[{ ...filters, key: "selection" }]}
                onChange={dateRangeHandler}
                minDate={new Date()}
              />
            </div>
          </div>
          <div className="parent-container">
            <div className="w-[70%] mt-5">
              {workingDays && (
                <Toggledays
                  handleWorkingDays={getWorkingDays}
                  workingDays={workingDays}
                  className="py-4"
                />
              )}
            </div>
            <div className="bulk-submit">
              {list.length > 0 && (
                <button className="bulk-btn" onClick={handleSumbit}>
                  Submit Rates
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="rates-header">
          <h1>Room Category</h1>
          <div className="type-header">
            <div className="types">
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "40px",
                }}
              >
                <span>4</span>&nbsp;
                <IoPerson />
              </p>
            </div>
            <div className="types">
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "40px",
                }}
              >
                <span>3</span>&nbsp;
                <IoPerson />
              </p>
            </div>
            <div className="types">
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "35px",
                }}
              >
                <span>2</span>&nbsp;
                <IoPerson />
              </p>
            </div>
            <div className="types">
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "30px",
                }}
              >
                <span>1</span>&nbsp;
                <IoPerson />
              </p>
            </div>

            <div className="types">
              <p>Extra Adult</p>
            </div>

            <div className="types">
              <p>Extra Child</p>
            </div>
          </div>
        </div>

        <hr />

        {eat &&
          eat.map((el, i) => (
            <div className="updaterates" key={i}>
              <h2 className="text-md">{el.RoomTypeName}</h2>
              <div className="inputs-header1 mt-1  ">
                <h1 className="text-md">
                  {el.RateplanName === "Room Only"
                    ? "EP"
                    : el.RateplanName === "With Breakfast"
                    ? "CP"
                    : el.RateplanName === "Breakfast and Dinner"
                    ? "MAP"
                    : el.RateplanName === "Breakfast, Lunch and Dinner"
                    ? "AP"
                    : ""}
                </h1>

                <div className="input-container1">
                  <div className="input-box1">
                    <input
                      type="number"
                      onChange={(e) =>
                        handleInputChange(i, "FourAdults", e.target.value)
                      }
                    />
                  </div>
                  <div className="input-box1">
                    <input
                      type="number"
                      onChange={(e) =>
                        handleInputChange(i, "ThreeAdults", e.target.value)
                      }
                    />
                  </div>
                  <div className="input-box1">
                    <input
                      type="number"
                      onChange={(e) =>
                        handleInputChange(i, "TwoAdults", e.target.value)
                      }
                    />
                  </div>
                  <div className="input-box1">
                    <input
                      type="number"
                      onChange={(e) =>
                        handleInputChange(i, "OneAdult", e.target.value)
                      }
                    />
                  </div>
                  <div className="input-box1">
                    <input
                      type="number"
                      onChange={(e) =>
                        handleInputChange(i, "ExtraAdult", e.target.value)
                      }
                    />
                  </div>
                  <div className="input-box1">
                    <input
                      type="number"
                      onChange={(e) =>
                        handleInputChange(i, "ExtraChild", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </section>

      {/* {"" + filters.startDate !== "" + filters.endDate && (
        <section className="card br-8"></section>
      )} */}
    </div>
  );
};

export default BulkUpdateRates;
