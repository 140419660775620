import { Modal } from "@mui/material";
import React, { useState, useCallback, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { RiGalleryLine } from "react-icons/ri";
import fileUpload from "../../core/Services/Utils/fileUploader";
import PulseSpinner from "react-spinners/PulseLoader";
import { IoIosAdd } from "react-icons/io";
import InputGrp from "../../Components/common/inputGRP";
import { addNewRoom } from "../../core/Services/Data/PropertyDetails";
function PropertyForm({ setTab }) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [property_type, setPropertyType] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const [propertyDetails, setpropertyDetails] = React.useState({
    info: "",
    roomType: "",
    totalRooms: "",
    description: "",
    roomView: "",
    bedType: "",
    images: {
      bedroom: [],
      otherImages: [],
    },
    dimension: "",
    length: 0,
    width: 0,
    adultBase: 0,
    adultMax: 0,
    guestMax: 0,
    childBase: 0,
    childMax: 0,
  });

  {
  }

  const navigate = useNavigate();
  //   const location = useLocation();
  //   let propertyDetailsDetails;
  //   if (location.state) {
  //     propertyDetailsDetails = location.state.data;
  //   }
  useEffect(() => {
    // if (propertyDetailsDetails) {
    //   console.log("LOCATION", propertyDetailsDetails);
    //   setpropertyDetails({
    //     propertyCode: propertyDetailsDetails.code,
    //     propertyDetailsName: propertyDetailsDetails.propertyDetailsName,
    //     propertyType: propertyDetailsDetails.typeofprop,
    //     firstName: propertyDetailsDetails.firstName,
    //     lastName: propertyDetailsDetails.lastName,
    //     propertyDetailsStarRating: propertyDetailsDetails.rating,
    //     totalRooms: propertyDetailsDetails.roomsNo,
    //     takingpropertyDetailssSince: propertyDetailsDetails.since,
    //     phoneNo: propertyDetailsDetails.phone,
    //     email: propertyDetailsDetails.email,
    //     password: propertyDetailsDetails.password,
    //     confirmPassword: propertyDetailsDetails.confirmPassword,
    //     submittedBy: propertyDetailsDetails.submittedBy,
    //     lattitude: propertyDetailsDetails.latitude,
    //     longitude: propertyDetailsDetails.longitude,
    //     locality: propertyDetailsDetails.locality,
    //     address: propertyDetailsDetails.address,
    //     city: propertyDetailsDetails.city,
    //     state: propertyDetailsDetails.state,
    //     pincode: propertyDetailsDetails.pincode,
    //     country: propertyDetailsDetails.country,
    //   });
    //   setChangeAble(true);
    // }
  }, []);

  const SubmitEvent = async (e) => {
    e.preventDefault();

    const data = { ...propertyDetails };

    addNewRoom(data)
      .then((res) => {
        navigate("/propertyDetails/Allrooms");
        toast.success(res.data.message);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  // const initialpropertyDetails =
  const handleUpload = async (e) => {
    e.preventDefault();

    // console.log({
    //   bedroom:
    //     document.getElementById("UploadingImageType").value === "bedroom"
    //       ? images
    //       : "",
    //   otherImages:
    //     document.getElementById("UploadingImageType").value === "otherImages"
    //       ? images
    //       : "",
    // });
    if (images.length > 0) {
      const imgLink = [];
      setLoader(true);
      const prpD = { ...propertyDetails };
      for (let i = 0; i < images.length; i++) {
        const { data } = await fileUpload(images[i]);
        imgLink.push(data.fileUrl);
      }
      prpD.images.bedroom = [...imgLink];
      setpropertyDetails({ ...prpD });
    }
    setLoader(false);
    setShowModal(false);
  };
  const handleChange = (e) => {
    e.preventDefault();
    // const err =
    const { currentTarget: input } = e;
    const demopropertyDetails = { ...propertyDetails };
    demopropertyDetails[input.name] = input.value;
    // console.log(propertyDetails);
    setpropertyDetails({ ...demopropertyDetails });
  };

  // const location = use

  return (
    <div className="bg-[#F9F9F9] p-4  ">
      <form className="w-full bg-[#ffffff] shadow-lg p-4 m-auto text-xl">
        <div className="grid grid-cols-2">
          <h3 class="mb-2 text-left font-bold">Room Details Form</h3>
        </div>
        <div class=" grid grid-cols-5 gap-8 pr-4">
          <InputGrp
            label="Display Info"
            name="info"
            className="col-span-2"
            placeHolder="Display Info"
            type="text"
            value={propertyDetails.info}
            setData={handleChange}
          />
          <InputGrp
            className="col-span-2"
            label="Room Type"
            name="roomType"
            placeHolder="Room Type"
            type="text"
            value={propertyDetails.roomType}
            setData={handleChange}
          />

          <InputGrp
            label="Total Rooms"
            name="totalRooms"
            placeHolder="Total Rooms"
            type="number"
            value={propertyDetails.totalRooms}
            setData={handleChange}
          />

          <div className={`text-start w-full m-4 col-span-2`}>
            <label
              htmlFor="description"
              className="block text-sm font-bold text-[#370D6F]"
            >
              Description
            </label>
            <textarea
              className="border border-slate-300 border-solid rounded-md mt-2 w-[100%] text-sm p-2"
              id="description"
              name="description"
              placeholder="description"
              aria-label="description"
              rows="5"
              cols="52"
              aria-describedby="basic-addon1"
              // classname="w-full bg-red-300"
              // disabled={disabled}

              required
              value={propertyDetails.description}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            {/* <p className="text-red-900 text-sm">{errors}</p> */}
          </div>

          <div className={`text-start w-full col-span-2`}>
            <InputGrp
              label="Room View"
              name="roomView"
              placeHolder="Room View"
              type="text"
              value={propertyDetails.roomView}
              setData={handleChange}
            />

            <InputGrp
              label="Bed Type"
              name="bedType"
              placeHolder="Bed Type"
              type="text"
              value={propertyDetails.bedType}
              setData={handleChange}
            />
          </div>

          {/* <InputGrp
            label="images"
            name="images"
            placeHolder="images"
            type="text"
            value={propertyDetails.images}
            setData={handleChange}
          /> */}
          <div className={`text-start w-full m-4 `}>
            <label
              htmlFor="description"
              className="block text-sm font-bold text-[#370D6F]"
            >
              Upload Image
            </label>
            <button
              className="text-6xl border border-solid border-slate-300 w-full h-full mt-2 block flex justify-center align-items-center  rounded-md"
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
            >
              <RiGalleryLine />
            </button>

            {/* <p className="text-red-900 text-sm">{errors}</p> */}
          </div>

          <Modal
            open={showModal}
            onClose={(e) => setShowModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="w-screen h-screen flex justify-center align-items-center ">
              <div className=" bg-[#ffffff] w-[60%] h-fit p-4 rounded-md">
                <div id="headOfModal">
                  <h1 className="text-slate-300 w-full text-center mt-4">
                    Select Image
                  </h1>
                </div>
                {loader && (
                  <div className="w-full h-56 flex justify-center align-items-center">
                    <PulseSpinner />
                  </div>
                )}
                {!loader && (
                  <div>
                    <div className="grid grid-cols-3 gap-4 p-4">
                      {[...images].map((img) => {
                        return (
                          <img
                            alt="not fount"
                            className="w-full h-36"
                            src={URL.createObjectURL(img)}
                          />
                        );
                      })}
                      {/* <button
                      className="w-full h-36 bg-slate-100 border border-dashed font-thin flex justify-center align-items-center text-8xl"
                      onClick={() => {
                        console.log("lol");
                      }}
                    >
                      <IoIosAdd />
                    </button> */}
                      <button
                        className="w-full h-36 bg-slate-100 border border-dashed font-thin flex justify-center align-items-center text-4xl"
                        onClick={(e) => {
                          // e.preventDefault();
                          document.getElementById("getFile").click();
                        }}
                      >
                        <input
                          type="file"
                          id="getFile"
                          className="hidden"
                          name="myImage"
                          onChange={(event) => {
                            console.log(images);
                            const arr = [...images];
                            arr.push(event.target.files[0]);
                            setImages(arr);
                          }}
                        />

                        <IoIosAdd />
                      </button>
                    </div>

                    <div>
                      <select
                        name="imageType"
                        id="UploadingImageType"
                        className="m-4"
                      >
                        <option value="bedroom">BedRoom</option>
                        <option value="otherImages">Other Images</option>
                      </select>
                    </div>
                    <div id="footerOfModal" className="flex justify-end">
                      <button
                        className=" mx-4 px-4 py-2 text-[#ffffff] text-lg rounded-lg"
                        type="button"
                        style={{
                          background:
                            "linear-gradient(90deg, rgba(37,3,124,1) 0%, rgba(6,6,157,1) 16%, rgba(179,0,255,1) 100%)",
                        }}
                        onClick={(e) => handleUpload(e)}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        </div>

        <div className="text-start w-full m-4 ">
          <label
            htmlFor="description"
            className="block text-sm font-bold text-[#370D6F]"
          >
            Dimension
          </label>
        </div>
        <div className="flex p-4 gap-4 ">
          <div className="w-64 flex gap-4">
            <button
              className={`p-4   border-solid rounded-md w-64  text-sm ${
                propertyDetails.dimension === "Size"
                  ? "text-[#370D6F] border-[#370D6F] border-2"
                  : "border-slate-300 border "
              }`}
              onClick={(e) => {
                e.preventDefault();
                const prop = { ...propertyDetails };
                prop.dimension = "Size";
                setpropertyDetails({ ...prop });
              }}
            >
              Size
            </button>
            <button
              className={`p-4   border-solid rounded-md w-64  text-sm ${
                propertyDetails.dimension === "Area"
                  ? "text-[#370D6F] border-[#370D6F] border-2"
                  : "border-slate-300 border "
              }`}
              onClick={(e) => {
                e.preventDefault();
                const prop = { ...propertyDetails };
                prop.dimension = "Area";
                setpropertyDetails({ ...prop });
              }}
            >
              Area
            </button>
          </div>
          <div className={`text-start  h-inherit ml-24`}>
            <input
              className="border border-slate-300 border-solid rounded-md text-center  h-full text-sm p-2"
              id="length"
              name="length"
              placeholder="Length"
              // classname="w-full bg-red-300"
              // disabled={disabled}
              required
              //   value={propertyDetails.length}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            {/* <p className="text-red-900 text-sm">{errors}</p> */}
          </div>
          <div className="h-inherit flex justify-center align-items-center ">
            <h1>X</h1>
          </div>
          <div className={`text-start  h-inherit `}>
            <input
              className="border border-slate-300 border-solid rounded-md text-center  h-full text-sm p-2"
              id="width"
              name="width"
              placeholder="Width"
              // classname="w-full bg-red-300"
              // disabled={disabled}
              required
              //   value={propertyDetails.width}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
          <div className={`text-start  h-inherit `}>
            <select
              name=""
              id=""
              className="px-8 text-center h-full text-sm font-bold text-[#370D6F]"
            >
              <option value="">Select</option>
              <option value="">Sq.ft</option>
              <option value="">Sq.mtr</option>
            </select>
          </div>
          {/* <p className="text-red-900 text-sm">{errors}</p> */}
        </div>
        <div className="text-start w-full m-4 ">
          <label
            htmlFor="description"
            className="block text-sm font-bold text-[#370D6F]"
          >
            Room Occupancy
          </label>
        </div>
        <div className="grid grid-cols-3">
          <div className={`text-start w-inherit m-4 `}>
            <label
              htmlFor="adultBase"
              className="block text-sm font-bold text-[#370D6F]"
            >
              Adult (Base)
            </label>
            <div className={`text-start  h-inherit `}>
              <select
                id="adultBase"
                name="adultBase"
                type="number"
                // value={propertyDetails.adultBase}
                setData={(e) => handleChange(e)}
                className=" w-full mt-2 rounded-md text-start h-full text-sm font-bold text-[#370D6F]"
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>

                <option value={3}>3</option>
              </select>
            </div>
          </div>
          <div className={`text-start w-inherit m-4 `}>
            <label
              htmlFor="adultMax"
              className="block text-sm font-bold text-[#370D6F]"
            >
              Adult (Max)
            </label>
            <div className={`text-start  h-inherit `}>
              <select
                id="adultMax"
                name="adultMax"
                placeHolder="Adult (Max)"
                // value={propertyDetails.adultMax}
                setData={(e) => handleChange(e)}
                className=" w-full mt-2 rounded-md text-start h-full text-sm font-bold text-[#370D6F]"
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>

                <option value={3}>3</option>
              </select>
            </div>
          </div>
          <div className={`text-start w-inherit m-4 `}>
            <label
              htmlFor="guestMax"
              className="block text-sm font-bold text-[#370D6F]"
            >
              Guest (Max)
            </label>
            <div className={`text-start  h-inherit `}>
              <select
                id="guestMax"
                name="guestMax"
                // value={propertyDetails.guestMax}
                setData={(e) => handleChange(e)}
                className=" w-full mt-2 rounded-md text-start h-full text-sm font-bold text-[#370D6F]"
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>

                <option value={3}>3</option>
              </select>
            </div>
          </div>
          <div className={`text-start w-inherit m-4 `}>
            <label
              htmlFor="childBase"
              className="block text-sm font-bold text-[#370D6F]"
            >
              Child (Base)
            </label>
            <div className={`text-start  h-inherit `}>
              <select
                id="childBase"
                name="childBase"
                // value={propertyDetails.childBase}
                setData={(e) => handleChange(e)}
                className=" w-full mt-2 rounded-md text-start h-full text-sm font-bold text-[#370D6F]"
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>

                <option value={3}>3</option>
              </select>
            </div>
          </div>
          <div className={`text-start w-inherit m-4 `}>
            <label
              htmlFor="childBase"
              className="block text-sm font-bold text-[#370D6F]"
            >
              Child (Max)
            </label>
            <div className={`text-start  h-inherit `}>
              <select
                id="childMax"
                name="childMax"
                // value={propertyDetails.childMax}
                setData={(e) => handleChange(e)}
                className=" w-full mt-2 rounded-md text-start h-full text-sm font-bold text-[#370D6F]"
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>

                <option value={3}>3</option>
              </select>
            </div>
          </div>
        </div>
        <div class="text-end pt-1 mb-5 pb-1">
          <button
            className=" mb-3 px-4 py-2 text-[#ffffff] text-lg rounded-lg"
            type="button"
            style={{
              background:
                "linear-gradient(90deg, rgba(37,3,124,1) 0%, rgba(6,6,157,1) 16%, rgba(179,0,255,1) 100%)",
            }}
            onClick={(e) => SubmitEvent(e)}
          >
            Add Property Details
          </button>
        </div>
      </form>
    </div>
  );
}

export default PropertyForm;
