import React, { memo, useState, useCallback, useEffect } from "react";
import { Line, Doughnut, Bar } from "react-chartjs-2";
import { Chart } from "primereact/chart";
import { BookingPerformanceChart } from "../../../core/Services/Data/performance";

// import "./dashboard.css";

const Charts = memo(() => {
  const [selectedOption, setSelectedOption] = useState(1);
  const [perform, setPerform] = useState({
    label: [],
    result: [],
    resultgoibibo: [],
    resultexpedia: [],
    resulttravelguru: [],
    resulteasemytrip: [],
    resultAgoda: [],
    resulthappyeaseGo: [],
    resultvia: [],
    resulthobse: [],
    resulthostelworld: [],
    resultcleartrip: [],
    resultbookingcom: [],
    resultairbnb: [],
    resultMmt: [],
    checkin: "",
    checkout: "",
    resultYatra: [],
  });
  const [cond, setCond] = useState();

  // console.log("---------->users",user)
  // console.log("---------->notes",user.notes)

  const options = [
    {
      id: "month",
      name: "This month",
    },
    // {
    //   id: "week",
    //   name: "This week",
    // },
    {
      id: "year",
      name: "This year",
    },
    // {
    //   id: "today",
    //   name: "Today",
    // },
  ];
  const userData = {
    labels: perform && perform.label,
    datasets: [
      {
        label: "Off line Booking",
        data: perform && perform.result,
        fill: "start",
        lineTension: 0.5,
        borderWidth: 3,
        // backgroundColor: (context) => {
        //   const ctx = context.chart.ctx;
        //   const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        //   gradient.addColorStop(0, "orange");
        //   gradient.addColorStop(1, "rgba(255, 255, 255, 0.16)");
        //   return gradient;
        // },

        borderColor: "orange",
        borderJoinStyle: "round",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "transparent",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      {
        label: "Goibibo",
        data: perform && perform.resultgoibibo,
        fill: "start",
        lineTension: 0.5,
        borderWidth: 3,
        // backgroundColor: (context) => {
        //   const ctx = context.chart.ctx;
        //   const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        //   gradient.addColorStop(0, "red");
        //   gradient.addColorStop(1, "rgb(209 213 219)");
        //   return gradient;
        // },

        borderColor: "rgb(209 213 219)",
        borderJoinStyle: "round",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "transparent",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgb(209 213 219)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      {
        label: "Expedia",
        data: perform && perform.resultexpedia,
        fill: "start",
        lineTension: 0.5,
        borderWidth: 3,
        // backgroundColor: (context) => {
        //   const ctx = context.chart.ctx;
        //   const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        //   gradient.addColorStop(0, "red");
        //   gradient.addColorStop(1, "rgb(252 165 165)");
        //   return gradient;
        // },

        borderColor: "rgb(252 165 165)",
        borderJoinStyle: "round",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "transparent",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgb(252 165 165)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      {
        label: "Travelguru",
        data: perform && perform.resulttravelguru,
        fill: "start",
        lineTension: 0.5,
        borderWidth: 3,
        // backgroundColor: (context) => {
        //   const ctx = context.chart.ctx;
        //   const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        //   gradient.addColorStop(0, "red");
        //   gradient.addColorStop(1, "rgb(148 163 184)");
        //   return gradient;
        // },

        borderColor: "rgb(148 163 184)",
        borderJoinStyle: "round",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "transparent",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgb(148 163 184)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      {
        label: "Easemytrip",
        data: perform && perform.resulteasemytrip,
        fill: "start",
        lineTension: 0.5,
        borderWidth: 3,
        // backgroundColor: (context) => {
        //   const ctx = context.chart.ctx;
        //   const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        //   gradient.addColorStop(0, "red");
        //   gradient.addColorStop(1, "rgb(253 224 71)");
        //   return gradient;
        // },

        borderColor: "rgb(253 224 71)",
        borderJoinStyle: "round",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "transparent",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgb(253 224 71)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      {
        label: "Agoda",
        data: perform && perform.resultAgoda,
        fill: "start",
        lineTension: 0.5,
        borderWidth: 3,
        // backgroundColor: (context) => {
        //   const ctx = context.chart.ctx;
        //   const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        //   gradient.addColorStop(0, "red");
        //   gradient.addColorStop(1, "rgb(190 242 100)");
        //   return gradient;
        // },

        borderColor: "rgb(190 242 100)",
        borderJoinStyle: "round",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "transparent",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgb(190 242 100)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      {
        label: "Happyeasygo",
        data: perform && perform.resulthappyeaseGo,
        fill: "start",
        lineTension: 0.5,
        borderWidth: 3,
        // backgroundColor: (context) => {
        //   const ctx = context.chart.ctx;
        //   const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        //   gradient.addColorStop(0, "red");
        //   gradient.addColorStop(1, "rgb(134 239 172)");
        //   return gradient;
        // },

        borderColor: "rgb(134 239 172)",
        borderJoinStyle: "round",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "transparent",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgb(134 239 172)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      {
        label: "Via",
        data: perform && perform.resultvia,
        fill: "start",
        lineTension: 0.5,
        borderWidth: 3,
        // backgroundColor: (context) => {
        //   const ctx = context.chart.ctx;
        //   const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        //   gradient.addColorStop(0, "red");
        //   gradient.addColorStop(1, "rgb(94 234 212)");
        //   return gradient;
        // },

        borderColor: "rgb(94 234 212)",
        borderJoinStyle: "round",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "transparent",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgb(94 234 212)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      {
        label: "Hobse",
        data: perform && perform.resulthobse,
        fill: "start",
        lineTension: 0.5,
        borderWidth: 3,
        // backgroundColor: (context) => {
        //   const ctx = context.chart.ctx;
        //   const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        //   gradient.addColorStop(0, "red");
        //   gradient.addColorStop(1, "#FFC84E");
        //   return gradient;
        // },

        borderColor: "#FFC84E",
        borderJoinStyle: "round",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "transparent",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "#FFC84E",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      {
        label: "Hostelworld",
        data: perform && perform.resulthostelworld,
        fill: "start",
        lineTension: 0.5,
        borderWidth: 3,
        // backgroundColor: (context) => {
        //   const ctx = context.chart.ctx;
        //   const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        //   gradient.addColorStop(0, "red");
        //   gradient.addColorStop(1, "rgb(103 232 249)");
        //   return gradient;
        // },

        borderColor: "rgb(103 232 249)",
        borderJoinStyle: "round",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "transparent",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgb(103 232 249)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      {
        label: "Cleartrip",
        data: perform && perform.resultcleartrip,
        fill: "start",
        lineTension: 0.5,
        borderWidth: 3,
        // backgroundColor: (context) => {
        //   const ctx = context.chart.ctx;
        //   const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        //   gradient.addColorStop(0, "red");
        //   gradient.addColorStop(1, "rgb(238 242 255)");
        //   return gradient;
        // },

        borderColor: "rgb(238 242 255)",
        borderJoinStyle: "round",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "transparent",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgb(238 242 255)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      {
        label: "Booking.com",
        data: perform && perform.resultbookingcom,
        fill: "start",
        lineTension: 0.5,
        borderWidth: 3,
        // backgroundColor: (context) => {
        //   const ctx = context.chart.ctx;
        //   const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        //   gradient.addColorStop(0, "red");
        //   gradient.addColorStop(1, "rgb(196 181 253)");
        //   return gradient;
        // },

        borderColor: "rgb(196 181 253)",
        borderJoinStyle: "round",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "transparent",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgb(196 181 253)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      {
        label: "Airbnb",
        data: perform && perform.resultairbnb,
        fill: "start",
        lineTension: 0.5,
        borderWidth: 3,
        // backgroundColor: (context) => {
        //   const ctx = context.chart.ctx;
        //   const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        //   gradient.addColorStop(0, "red");
        //   gradient.addColorStop(1, "rgb(216 180 254)");
        //   return gradient;
        // },

        borderColor: "rgb(216 180 254)",
        borderJoinStyle: "round",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "transparent",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgb(216 180 254)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      {
        label: "MMT",
        data: perform && perform.resultMmt,
        fill: "start",
        lineTension: 0.5,
        borderWidth: 3,
        // backgroundColor: (context) => {
        //   const ctx = context.chart.ctx;
        //   const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        //   gradient.addColorStop(0, "red");
        //   gradient.addColorStop(1, "#6F0D6B");
        //   return gradient;
        // },

        borderColor: "#6F0D6B",
        borderJoinStyle: "round",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "transparent",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "#6F0D6B",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      {
        label: "Yatra",
        data: perform && perform.resultYatra,
        fill: "start",
        lineTension: 0.5,
        borderWidth: 3,
        // backgroundColor: (context) => {
        //   const ctx = context.chart.ctx;
        //   const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        //   gradient.addColorStop(0, "rgba(230, 32, 239, 1)");
        //   gradient.addColorStop(1, "rgba(255, 255, 255, 0.16)");
        //   return gradient;
        // },

        borderColor: "#370D6F",
        borderJoinStyle: "round",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "transparent",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      // {
      //   label: "WAU",
      //   data: [33, 25, 35, 51, 54, 76, 33, 25, 35, 51, 54, 76],
      //   fill: true,
      //   lineTension: 0.1,
      //   borderWidth: 1,
      //   backgroundColor: (context) => {
      //     const ctx = context.chart.ctx;
      //     const gradient = ctx.createLinearGradient(0, 0, 0, 300);
      //     gradient.addColorStop(0, "#000");
      //     gradient.addColorStop(1, "rgba(255, 255, 255, 0.16)");
      //     return gradient;
      //   },
      //   borderColor: "#000",
      //   borderJoinStyle: "round",
      //   borderCapStyle: "butt",
      //   borderDash: [],
      //   borderDashOffset: 0.0,
      //   pointBorderColor: "rgba(75,192,192,1)",
      //   pointBackgroundColor: "#fff",
      //   pointBorderWidth: 1,
      //   pointHoverRadius: 5,
      //   pointHoverBackgroundColor: "rgba(75,192,192,1)",
      //   pointHoverBorderColor: "rgba(220,220,220,1)",
      //   pointHoverBorderWidth: 2,
      //   pointRadius: 0,
      //   pointHitRadius: 10,
      // },
      // {
      //   label: "DAU",
      //   data: [23, 35, 35, 61, 54, 76, 23, 25, 35, 51, 54, 76],
      //   fill: true,
      //   lineTension: 0.1,
      //   borderWidth: 1,
      //   backgroundColor: (context) => {
      //     const ctx = context.chart.ctx;
      //     const gradient = ctx.createLinearGradient(0, 0, 0, 300);
      //     gradient.addColorStop(0, "#EF173E");
      //     gradient.addColorStop(1, "rgba(255, 255, 255, 0.16)");
      //     return gradient;
      //   },
      //   borderColor: "#EF173E",
      //   borderJoinStyle: "round",
      //   borderCapStyle: "butt",
      //   borderDash: [],
      //   borderDashOffset: 0.0,
      //   pointBorderColor: "rgba(75,192,192,1)",
      //   pointBackgroundColor: "#fff",
      //   pointBorderWidth: 1,
      //   pointHoverRadius: 5,
      //   pointHoverBackgroundColor: "rgba(75,192,192,1)",
      //   pointHoverBorderColor: "rgba(220,220,220,1)",
      //   pointHoverBorderWidth: 2,
      //   pointRadius: 0,
      //   pointHitRadius: 10,
      // },
    ],
  };
  const selectedOptionHandler = useCallback((id) => {
    setSelectedOption(id);
  }, []);
  useEffect(() => {
    BookingPerformanceChart(cond ? cond : "year").then((res) => {
      console.log("res of here", res);
      setPerform({
        label: res.data.easeroom.label,
        result: res.data.easeroom.result,
        resultgoibibo: res.data.easeroom.resultgoibibo,
        resultexpedia: res.data.easeroom.resultexpedia,
        resulttravelguru: res.data.easeroom.resulttravelguru,
        resulteasemytrip: res.data.easeroom.resulteasemytrip,
        resultAgoda: res.data.easeroom.resultAgoda,
        resulthappyeaseGo: res.data.easeroom.resulthappyeaseGo,
        resultvia: res.data.easeroom.resultvia,
        resulthobse: res.data.easeroom.resulthobse,
        resulthostelworld: res.data.easeroom.resulthostelworld,
        resultcleartrip: res.data.easeroom.resultcleartrip,
        resultbookingcom: res.data.easeroom.resultbookingcom,
        resultairbnb: res.data.easeroom.resultairbnb,
        resultMmt: res.data.easeroom.resultMmt,
        resultYatra: res.data.yatra.resultYatra,
        checkin: res.checkIn,
        checkout: res.checkOut,
      });
    });
  }, [cond]);
  console.log("Performance", perform);

  return (
    <div className="bookings card">
      {/* <div className = "block md:flex items-center "> <h1 className = "font-bold text-2xl text-[#23496B]">Reservation Statistic</h1> <div className = "flex md:ml-[30%] justify-between w-[50%]"><h1 className = "font-bold text-2xl "> 549 checkin</h1> <h1 className = "font-bold text-2xl "> 327 checkout</h1> </div>
      </div> */}
      <div className="card-header">
        <span className="font-bold text-2xl text-[#23496B]">
          Reservation Statistic
        </span>
        {/* <span>
          <h1 className="font-bold text-xl">{perform && perform.checkin} checkin</h1>
        </span>
        <span>
          <h1 className="font-bold text-xl">{perform && perform.checkout} checkout</h1>
        </span> */}

        <select
          onChange={(e) => {
            setCond(e.target.value);
          }}
        >
          {options.map((option) => (
            <option value={option.id} key={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full h-fit flex flex-wrap gap-y-4 gap-2">
        <div className="flex gap-4 mx-2 cursor-pointer ">
          <div className="w-4 h-4 rounded-lg bg-orange-300"></div>
          <div className="">Offline</div>
        </div>
        <div className="flex gap-4 mx-2 cursor-pointer ">
          <div className="w-4 h-4 rounded-lg bg-[#370D6F]"></div>
          <div className="">Yatra</div>
        </div>
        <div className="flex gap-4 mx-2 cursor-pointer">
          <div className="w-4 h-4 rounded-lg bg-[#6F0D6B]"></div>
          <div className="">MMT</div>
        </div>
        <div className="flex gap-4 mx-2 cursor-pointer">
          <div className="w-4 h-4 rounded-lg bg-gray-300"></div>
          <div className="">Goibibo</div>
        </div>
        <div className="flex gap-4 mx-2 cursor-pointer">
          <div className="w-4 h-4 rounded-lg bg-red-300"></div>
          <div className="">Expedia</div>
        </div>
        <div className="flex gap-4 mx-2 cursor-pointer">
          <div className="w-4 h-4 rounded-lg bg-slate-500"></div>
          <div className="">Travelguru</div>
        </div>
        <div className="flex gap-4 mx-2 cursor-pointer">
          <div className="w-4 h-4 rounded-lg bg-yellow-300"></div>
          <div className="">Easemytrip</div>
        </div>
        <div className="flex gap-4 mx-2 cursor-pointer">
          <div className="w-4 h-4 rounded-lg bg-lime-300"></div>
          <div className="">Agoda</div>
        </div>
        <div className="flex gap-4 mx-2 cursor-pointer">
          <div className="w-4 h-4 rounded-lg bg-green-300"></div>
          <div className="">Happyeasygo</div>
        </div>
        <div className="flex gap-4 mx-2 cursor-pointer">
          <div className="w-4 h-4 rounded-lg bg-teal-300"></div>
          <div className="">Via</div>
        </div>
        <div className="flex gap-4 mx-2 cursor-pointer">
          <div className="w-4 h-4 rounded-lg bg-[#FFC84E]"></div>
          <div className="">Hobse</div>
        </div>
        <div className="flex gap-4 mx-2 cursor-pointer">
          <div className="w-4 h-4 rounded-lg bg-cyan-300"></div>
          <div className="">Hostelworld</div>
        </div>
        <div className="flex gap-4 mx-2 cursor-pointer">
          <div className="w-4 h-4 rounded-lg bg-indigo-300"></div>
          <div className="">Cleartrip</div>
        </div>
        <div className="flex gap-4 mx-2 cursor-pointer">
          <div className="w-4 h-4 rounded-lg bg-violet-300"></div>
          <div className="">Booking.com</div>
        </div>
        <div className="flex gap-4 mx-2 cursor-pointer">
          <div className="w-4 h-4 rounded-lg bg-purple-300"></div>
          <div className="">Airbnb</div>
        </div>
      </div>
      <div className="w-full h-fit">
        <Chart
          id="user-chart"
          sx="shadow-md"
          type="line"
          style={{ position: "relative", height: "70% ", width: "100%" }}
          data={userData}
          options={{
            responsive: true,
            plugins: { legend: false },
            scales: {
              yAxis: [
                { ticks: { stepSize: 20 }, gridLines: { display: false } },
              ],
            },
          }}
        />
      </div>
    </div>
  );
});

export default Charts;
