import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NavbarBook from "../../Components/Reports/NavbarBook";
import AllBookings from "./AllBookings";
import { Link } from "react-router-dom";
import CancelledBooking from "./Cancelled";
import GetUpcomingData from "./Upcoming";
import Calender from "./calender/Calender";
import Past from "./past";
import {
  getAllBookingFromOta,
  getmanagerBookingPush,
} from "../../core/Services/Data/EaseRoom";
import { getyatraBookingfromOta } from "../../core/Services/Data/YatraService";

// import Rates from './Rates';
// import Offers from './Offers';
// import Bookings from './Bookings';
// import Invoices from './Invoices';
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import TodayCheckin from "./TodayCheckin";

const Bookings = ({ user, setCurrentTabIndex }) => {
  useEffect(() => {
    setCurrentTabIndex(1);
  }, []);

  const [data, setData] = useState();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let result = [];
    console.log("usersss", user);
    if (user?.live?.easeRoom) {
      await getAllBookingFromOta(user?.live?.easeRoom?.secret).then((res) => {
        result = [
          ...result,
          ...res.BookingList.map((ele) => ({ ...ele, type: "easeroom" })),
        ];
        // setData(res.BookingList)
      });
    }
    await getyatraBookingfromOta().then((res) => {
      result = [
        ...result,
        ...res.getYatraBooking.map((ele) => ({ ...ele, type: "Yatra" })),
      ];
    });
    await getmanagerBookingPush("", user?.live?.easeRoom?.secret).then(
      (res) => {
        result = [
          ...result,
          ...res.data.map((el) => ({ ...el, type: "offline" })),
        ];
      }
    );
    await result.forEach((item) => {
      item.GuestName = item.GuestName.toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    });
    setData(result);
  };

  const path = useLocation().pathname;
  return (
    <div className="wrapper reports">
      <div className="grid grid-cols-6">
        <NavbarBook />
        <div className="w-full bg-[#ffffff] rounded-r-md shadow-sm flex justify-center p-2">
          <Link
            to="/bookings/add"
            className="w-full mx-12 text-center h-full text-[#ffffff] text-lg rounded-lg"
            type="button"
            style={{
              background:
                "linear-gradient(90deg, rgba(37,3,124,1) 0%, rgba(6,6,157,1) 16%, rgba(179,0,255,1) 100%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            + Add
          </Link>
        </div>
      </div>
      {path.includes("/bookings/Allbookings") && <AllBookings user={user} data1={data} />}
      {path.includes("/bookings/upcoming") && (
        <GetUpcomingData
          data={
            data &&
            data.filter((el) => {
              var rightNow = new Date();
              const today = new Date(rightNow).getTime();
              const date = new Date(el.Checkin).getTime();
              return today - date <= 0 && el.BookingStatus !== "Cancelled";
            })
          }
        />
      )}
      {path.includes("/bookings/canceled") && (
        <CancelledBooking
          data={
            data &&
            data.filter((el) => {
              // console.log("cancel",el)
              return el.BookingStatus == "Cancelled";
            })
          }
        />
      )}
      {path.includes("/bookings/past") && (
        <Past
          data={
            data &&
            data.filter((el) => {
              var rightNow = new Date();
              const today = new Date(rightNow).getTime();
              const date = new Date(el.Checkout).getTime();
              return today - date >= 0 && el.BookingStatus !== "Cancelled";
              // console.log("el bc",res-el.Checkout.replace(/-/g,""))
            })
          }
        />
      )}
      {path.includes("/bookings/calender") && <Calender />}
      {/* {path.includes('/reports/bookings') && <Bookings/>} */}
      {/* {path.includes('/reports/offers') && <Offers/>} */}
      {/* {path.includes('/reports/invoices') && <Invoices/>} */}
      {path.includes("/bookings/todaycheckins") && (
        <TodayCheckin user={user}
          data2={
            data &&
            data.filter((el) => {
              const today = moment(new Date()).format('DD-MMM-YYYY');
              const checkinDate = moment(el.Checkin).format('DD-MMM-YYYY');
              return today === checkinDate && el.BookingStatus !== "Cancelled";
            })
          }
        />
      )}
      {path.includes("/bookings/todaycheckouts") && (
        <TodayCheckin user={user}
          data2={
            data &&
            data.filter((el) => {
              const today = moment(new Date()).format('DD-MMM-YYYY');
              const checkOutDate = moment(el.Checkout).format('DD-MMM-YYYY');
              return today === checkOutDate && el.BookingStatus !== "Cancelled";
            })
          }
        />
      )}
    </div>
  );
};

export default Bookings;
