import React, { useState, useCallback } from "react";
// import { Doughnut } from "react-chartjs-2";
import { Chart } from "primereact/chart";
import { Bookingcomparision } from "../../core/Services/Data/performance";
import { useEffect } from "react";
import { display } from "@mui/system";

const Bookings = () => {
  const [comparision, setComparision] = useState();

  const data = {
    labels: [
      "Yatra",
      "MMT",
      "Goibibo",
      "Expedia",
      "Travel Guru",
      "EaseMyTrip",
      "Agoda",
      "HappyEaseGo",
      "Via",
      "Hobse",
      "HostelWorld",
      "ClearTrip",
      "Booking.com",
      "Airbnb",
    ],
    datasets: [
      {
        data: comparision && [
          comparision.yatracomp,
          comparision.gommt,
          comparision.goibibo,
          comparision.expedia,
          comparision.travelguru,
          comparision.easemytrip,
          comparision.agoda,
          comparision.happyeasego,
          comparision.via,
          comparision.hobse,
          comparision.hostelworld,
          comparision.cleartrip,
          comparision.bookingcom,
          comparision.airbnb,
        ],
        backgroundColor: [
          "#370D6F",
          "#6F0D6B",
          "#e2e8f0",
          "#feb2b2",
          "rgb(100 116 139)",
          "rgb(253 224 71)",
          "rgb(190 242 100)",
          "rgb(94 234 212)",
          "rgb(103 232 249)",
          "#FFC84E",
          "rgb(165 180 252)",
          "rgb(196 181 253)",
          "rgb(216 180 254)",
          "rgb(216 180 254)",
        ],
        // hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"]
      },
    ],

    // plugins: {
    //   labels: {
    //     render: "percentage",
    //     fontColor: ["green", "white", "red"],
    //     precision: 2
    //   },
    // },
    text: "23%",
  };
  const [selectedOption, setSelectedOption] = useState(1);
  const [cond, setCond] = useState();

  const options = [
    {
      id: "month",
      name: "This month",
    },
    // {
    //     id: "week",
    //     name: 'This week'
    // },
    {
      id: "year",
      name: "This year",
    },
    // {
    //     id: "today",
    //     name: 'Today'
    // },
  ];

  const selectedOptionHandler = useCallback((id) => {
    setSelectedOption(id);
  }, []);
  console.log("connddd", cond);
  useEffect(() => {
    Bookingcomparision(cond ? cond : "month").then((res) => {
      // console.log("performance   res  booking  res", res);
      setComparision(res);
    });
  }, [cond]);
  console.log("comp", comparision);

  return (
    <section className="bookings card">
      <div className="card-header">
        <span className="font-semibold text-base">Booking Comparison</span>
        <select
          onChange={(e) => {
            setCond(e.target.value);
          }}
        >
          {options.map((option) => (
            <option value={option.id} key={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
      <div className="flex items-center gap-9 ">
        <Chart
          id="user-chart"
          sx="shadow-md"
          type="doughnut"
          style={{ position: "relative", height: "100% ", width: "30%",  }}
          data={data}
        />
        <div className="card-content ">
          <ul>
            <li className="color-[#370D6F]">
              <span>Yatra</span>
              <span>{comparision && comparision.yatracomp}%</span>
            </li>
            <li>
              <span>MMT</span>
              <span>{comparision && comparision.gommt}%</span>
            </li>
            <li>
              <span>Goibibo</span>
              <span>{comparision && comparision.goibibo}%</span>
            </li>
            <li>
              <span>Expedia</span>
              <span>{comparision && comparision.expedia}%</span>
            </li>
            <li>
              <span>Travelguru</span>
              <span>{comparision && comparision.travelguru}%</span>
            </li>
            <li>
              <span>Easemytrip</span>
              <span>{comparision && comparision.easemytrip}%</span>
            </li>
            <li>
              <span>Agoda</span>
              <span>{comparision && comparision.agoda}%</span>
            </li>
            <li>
              <span>Happyeasygo</span>
              <span>{comparision && comparision.happyeasego}%</span>
            </li>
            <li>
              <span>Via</span>
              <span>{comparision && comparision.via}%</span>
            </li>
            <li>
              <span>Hobse</span>
              <span>{comparision && comparision.hobse}%</span>
            </li>
            <li>
              <span>Hostelworld</span>
              <span>{comparision && comparision.hostelworld}%</span>
            </li>
            {/* <li>
                    <span>GoogleIBE</span>
                    <span>{comparision && comparision.goibibo}%</span>
                </li> */}
            <li>
              <span>Cleartrip</span>
              <span>{comparision && comparision.cleartrip}%</span>
            </li>
            <li>
              <span>Booking.com</span>
              <span>{comparision && comparision.bookingcom}%</span>
            </li>
            <li>
              <span>Airbnb</span>
              <span>{comparision && comparision.airbnb}%</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Bookings;
