import React , {useEffect, useState} from "react"
import { getRooms } from "../../core/Services/Data/PropertyDetails";


const AllRooms = ()=>{
    const [data , setData] = useState()
    useEffect(()=>{
        getRooms().then((res)=>{
            console.log("checkkkk", res.data)
            setData(res.data)
        })
    },[])
    // console.log("res of get Rooms",data[0].images.bedroom[0])
    return (
        <div>
           {data && data.map((el)=>(
           <div className = "rounded-md flex gap-6 h-40 bg-[#ffffff] p-2">
            <div className = "rounded-lg w-[15%] h-full overflow-hidden">
                <img className = "h-full" src = {el?.images?.bedroom[0]}/>
            </div>
            <div className = "w-[20%]" >
                <h1 className = "font-bold text-xl text-[#370d6f]">Description</h1>
                <p className = "mt-2">{el?.description} </p>
            </div>
            <div className = "w-[20%]" >
                <h1 className = "font-bold text-xl text-[#370d6f]">Type</h1>
                <p className = "mt-2">{el?.roomType?.toUpperCase()}</p>
                {/* <p>Ac/ 2 Bed</p> */}
            </div>
            <div className = "w-[20%]" >
                <h1 className = "font-bold text-xl text-[#370d6f]">Room View</h1>
                {/* <h2 >EP</h2> */}
                <p className = "mt-2">{el?.roomType?.toUpperCase()}</p>
                <p>{el?.roomView?.toUpperCase()}</p>
                {/* <a className = "mt-2">Edit Details</a> */}
            </div>
            <div className = "w-[20%]" >
                <h1 className = "font-bold text-xl text-[#370d6f]">Actions</h1>
                <p className = "mt-2">Total rooms -{el.totalRooms}</p>
                <p>{el.bedType}</p>
            </div>
            </div>))}
        </div>
    )
}
export default AllRooms