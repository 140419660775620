import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NavbarBulkUpdate from "../../../Components/Reports/NavbarBulkUpdate";
import BulkInventory from "./BulkInventory";
import BulkUpdateRates from "./BulkUpdateRates";
import Restrictions from "./restriction/Restrictions";
const BulkUpdateInventory = ({ user, setCurrentTabIndex }) => {
  const path = useLocation().pathname;
  React.useEffect(() => {
    setCurrentTabIndex(2);
  }, []);
  // React.useEffect(() => {
  //     setCurrentTabIndex(1);
  //   }, []);
  // console.log("userrrrrr", user);
  return (
    <div className="wrapper reports">
      <NavbarBulkUpdate />
      {path.includes("rates-inventory/bukUpdate") && (
        <BulkInventory user={user} />
      )}
      {path.includes("rates-inventory/bulkUpdateRates") && (
        <BulkUpdateRates user={user} />
      )}
      {path.includes("rates-inventory/restrictions") && (
        <Restrictions user={user} />
      )}
    </div>
  );
};
export default BulkUpdateInventory;
