import React from "react";
import { useLocation } from "react-router-dom";
import AllRooms from "./Allrooms";
import Policies from "./policies/Policies";
import { Link } from "react-router-dom";
import NavbarProperty from "../../Components/Reports/NavbarProperty";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./property.css";

const PropertyDetails = ({ setCurrentTabIndex }) => {
  const path = useLocation().pathname;
  React.useEffect(() => {
    setCurrentTabIndex(3);
  }, []);
  return (
    <div className="wrapper reports">
      <div className="grid grid-cols-6">
        <NavbarProperty />
        <div className="w-full h-full bg-[#ffffff] rounded-r-md shadow-sm flex justify-center p-2">
          <Link
            to="/propertyDetails/addRoom"
            className="w-full  mx-12 text-center h-full text-[#ffffff] text-lg rounded-lg"
            type="button"
            style={{
              background:
                "linear-gradient(90deg, rgba(37,3,124,1) 0%, rgba(6,6,157,1) 16%, rgba(179,0,255,1) 100%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            + Add Room
          </Link>
        </div>
      </div>

      {/* sjncjk */}
      {path.includes("/propertyDetails/Allrooms") && <AllRooms />}
      {path.includes("/propertyDetails/policies") && <Policies />}
      {/* {path.includes('/reports/inventory') && <Inventory/>}
      {path.includes('/reports/rates') && <Rates/>}
      {path.includes('/reports/bookings') && <Bookings/>}
      {path.includes('/reports/offers') && <Offers/>}
      {path.includes('/reports/invoices') && <Invoices/>} */}
    </div>
  );
};

export default PropertyDetails;
