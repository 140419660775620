import { BrowserRouter } from "react-router-dom";
import Root from "./Root";
import "./reset.css";
import "./style.css";

function App() {
  return (
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  );
}

export default App;
