import React, {Fragment} from 'react';
import { useLocation } from "react-router-dom";
import {TextField} from "@mui/material";
import {useFormik} from "formik";
import {useNavigate} from "react-router-dom";
import {matchOtp} from "../../core/Services/User/login/login";
import * as yup from "yup";
import {toast} from "react-toastify";

const ResentmailSend = () => {
    const navigate = useNavigate();
    const Validation = yup.object({
        otp: yup.string("OTP is required").required("OTP is required"),
    })
    const { state } = useLocation();
    // console.log("State",state.email)
    // const param 
    const forgotPasswordForm = useFormik({
        initialValues: {
            otp : ''
        },
        validationSchema: Validation,
        onSubmit: (values) => {
            matchOtp(state.email,values.otp).then((res)=>{
                if(res){
                   toast.success(res.message)
                    navigate("/password-Change",{
                        state : {
                            otptoken : res.token
                        }
                    })
                }
            })
            // navigate("/password-Change")
            // console.log('email', values);
           
        }
    })
    return (
        // <Fragment>
        <div className = "flex justify-center w-[50vw] mx-auto mt-32 border-solid text-center">
            <div>
                <div className="py-5">
                    <h1 className="text-4xl font-bold">Check your email</h1>
                    <h3 className="text-lg my-3 text-center text-gray-400 max-w-sm">
                        We sent a password reset link to- <br/>
                        aka@gmail.com
                    </h3>
                </div>
                <form onSubmit={forgotPasswordForm.handleSubmit}>
                    {/* <div className="my-5">
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="email"
                            name="email"
                            label="Enter your email"
                            value={forgotPasswordForm.values.email}
                            onChange={forgotPasswordForm.handleChange}
                            onBlur={forgotPasswordForm.handleBlur}
                            helperText={forgotPasswordForm.touched.email && Boolean(forgotPasswordForm.errors.email) ? 'Email is required' : ''}
                            error={forgotPasswordForm.touched.email && Boolean(forgotPasswordForm.errors.email)}
                        />
                    </div> */}
                    <div className="my-5">
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="email"
                            name="otp"
                            label="Enter your OTP"
                            value={forgotPasswordForm.values.otp}
                            onChange={forgotPasswordForm.handleChange}
                            onBlur={forgotPasswordForm.handleBlur}
                            helperText={forgotPasswordForm.touched.otp && Boolean(forgotPasswordForm.errors.otp) ? 'OTP is required' : ''}
                            error={forgotPasswordForm.touched.otp && Boolean(forgotPasswordForm.errors.otp)}
                        />
                    </div>
                    {/* <button
                        type="submit"
                        className="text-white rounded w-full my-4 uppercase app-button focus:outline-none focus:ring-4
                     focus:ring-gray-300 font-medium text-sm px-5 py-2.5 mr-2 mb-2">
                        Reset Password
                    </button> */}
                    <button type="submit" className='auth-button d-block m-auto'>Reset Password</button>
                </form>
            </div>
            </div>
        // </Fragment>
    );
};

export default ResentmailSend;
