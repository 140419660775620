import { useEffect, useState } from "react";
// const { validate } = require("../../validator/hotel.validator");
const InputGrp = ({
  label,
  name,
  placeHolder,
  type,
  value,
  setData,
  errors,
  disabled,
  className,
  inputClass,
  minDate,
  readOnly
}) => {
  // const [errors, setErrors] = useState({});
  useEffect(() => {}, []);
  return (
    <div className={`text-start w-full m-4 ${className}`}>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-bold text-[#370D6F]"
        >
          {label}
        </label>
      )}
      <input
        className={`border border-slate-300 border-solid rounded-md mt-2 w-[100%] text-sm p-2 ${inputClass}`}
        id={name}
        name={name}
        placeholder={placeHolder}
        aria-label={name}
        aria-describedby="basic-addon1"
        // classname="w-full bg-red-300"
        // disabled={disabled}
        type={type}
        required
        value={value}
        readOnly={readOnly}
        onChange={(e) => {
          setData(e);
        }}
        min = {minDate}
      />
      <p className="text-red-900 text-sm">{errors}</p>
    </div>
  );
};

export default InputGrp;