import httpservice from "../HttpService/httpService";
import { toast } from "react-toastify";

export async function getALlPropertyDetails(hotelCode) {
  try {
    // console.log("hotelCode", hotelCode);
    const { data: details } = await httpservice.get(
      `utils/performance?hotelCode=${hotelCode}`
    );
    return details;
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}

export async function BookingPerformanceChart(cond) {
  try {
    const { data: details } = await httpservice.get(
      `utils/performance/chart?filter=${cond}`
    );
    return details;
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}

export async function Bookingcomparision(cond) {
  try {
    const { data: details } = await httpservice.get(
      `utils/bookingcomparision?filter=${cond}`
    );
    return details;
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}

export async function AnalyticsTable(cond) {
  try {
    const { data: details } = await httpservice.get(
      `utils/analyticsTable?filter=${cond}`
    );
    return details;
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}

export async function GetRoomTypePercentage(cond) {
  try {
    const { data: details } = await httpservice.get(
      `utils/getRoomTypePercentage?filter=${cond}`
    );
    return details;
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}
export async function GetRatePlanPercentage(cond) {
  try {
    const { data: details } = await httpservice.get(
      `utils/getRatePlanPercentage?filter=${cond}`
    );
    return details;
  } catch (err) {
    if (err.response && err.response.status === 400) {
      toast.error(err.response.data);
    }
  }
}
