import React, { useEffect } from "react";

const InputGroup = ({
  label,
  name,
  placeHolder,
  type,
  value,
  setData,
  errors,
  disabled,
  className,
  inputClass,
  minDate,
  readOnly
}) => {
  
  const labelColor = readOnly ? "[#370D6F]" : "[#6f7378]";
  
  const padding = readOnly? 'p-0':'p-2';
  const inputClassName = readOnly ? `${inputClass} border-none` : `${inputClass} border border-slate-300 border-solid rounded-md`;
  
  useEffect(() => {}, []);
  
  return (
    <div className={`text-start m-4 ${className}`}>
      {label && (
        <label
          htmlFor={name}
          className={`block text-sm font-bold text-${labelColor}`}
        >
          {label}
        </label>
      )}
      <input
        className={`mt-2 w-[100%] text-sm ${padding} ${inputClassName}`}
        id={name}
        name={name}
        placeholder={placeHolder}
        aria-label={name}
        aria-describedby="basic-addon1"
        type={type}
        value={value}
        readOnly={readOnly}
        onChange={(e) => {
          setData(e);
        }}
        min={minDate}
      />
      <p className="text-red-900 text-sm">{errors}</p>
    </div>
  );
};

export default InputGroup;