import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";

const NavbarBook = () => {
  const path = useLocation().pathname;

  if ((path.includes("todaycheckins")) || (path.includes("todaycheckouts"))) {
    return (
      <div className="card rounded-r-none br-8 reports-navbar col-span-5">
        <div className="card-content">
          <Link
            to="/bookings/todaycheckins"
            className={path.includes("todaycheckins") ? "active" : ""}
          >
            Today Check Ins
          </Link>
          <Link
            to="/bookings/todaycheckouts"
            className={path.includes("todaycheckouts") ? "active" : ""}
          >
            Today Check Outs
          </Link>

        </div>
      </div>
    )
  }
  else {
    return (
      <div className="card rounded-r-none br-8 reports-navbar col-span-5">
        <div className="card-content">
          <Link
            to="/bookings/Allbookings"
            className={path.includes("Allbookings") ? "active" : ""}
          >
            All Bookings
          </Link>
          <Link
            to="/bookings/upcoming"
            className={path.includes("upcoming") ? "active" : ""}
          >
            Upcoming
          </Link>
          <Link
            to="/bookings/past"
            className={path.includes("past") ? "active" : ""}
          >
            Past
          </Link>
          <Link
            to="/bookings/canceled"
            className={path.includes("canceled") ? "active" : ""}
          >
            Cancelled
          </Link>
          {/* <Link
          to="/bookings/payAthotel"
          className={path.includes("payAthotel") ? "active" : ""}
        >
          Pay at hotel
        </Link> */}
          {/* <Link
          to="/bookings/calender"
          className={path.includes("calender") ? "active" : ""}
        >
          Calender
        </Link> */}
        </div>
      </div>
    );
  }
};

export default NavbarBook;
