import React, { useRef, useState, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import zonzo from '../../Assests/images/zonzologof3.png';

const CancelledBooking = ({ data }) => {
  console.log("Cancell", data);
  //     const [cancel, setCancel] = useState(data.filter((el)=>el.status === "Cancelled"))
  //    console.log(cancel)
  const navigate = useNavigate();
  const gridRef = useRef(null);
  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };

  const handleNavigate = (id, type) => {
    if (type === "easeroom") {
      navigate("/bookings/Allbookings/easeroomdetails/" + id);
    }
    if (type === "Yatra") {
      navigate("/bookings/Allbookings/yatradetails/" + id);
    }
    if (type == "offline") {
      navigate("/bookings/Allbookings/offline/" + id);
    }
  };
  const Imagerenderer = (props) => {
    if (props?.data?.BookingSource?.toLowerCase() === "makemytrip") {
      return (
        <img
          src={"https://s3-symbol-logo.tradingview.com/makemytrip--600.png"}
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "booking.com") {
      return (
        <img
          src={
            "https://logos-world.net/wp-content/uploads/2021/08/Booking-Symbol.png"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "agoda") {
      return (
        <img
          src={
            "/Assets/Images/agoda.png"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "goibibo") {
      return (
        <img
          src={
            "https://aniportalimages.s3.amazonaws.com/media/details/Goibibo_IWaL00BWWWW.jpg"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "expedia") {
      return (
        <img
          src={
            "https://logowik.com/content/uploads/images/expedia-new-20233800.logowik.com.webp"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "travelguru") {
      return (
        <img
          src={
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMpVUvmvQ8_tGWhyDTnQX07dzB4Ap5yoh4SFrYPT8b&s"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "easemytrip") {
      return (
        <img
          src={
            "https://upload.wikimedia.org/wikipedia/commons/1/13/Easemytrip.jpg"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "happyeasygo") {
      return (
        <img
          src={
            "https://www.pngkey.com/png/detail/259-2599780_happyeasygo-happyeasygo-logo.png"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "via") {
      return (
        <img
          src={
            "https://www.newzealand.com/assets/Tourism-NZ/Other/c7e312de67/img-1541873987-8187-27595-0D7C344A-D9EF-561E-65E090FFAB7A6362__aWxvdmVrZWxseQo_CropResizeWzQ4MCxudWxsLDkwLCJwbmciXQ.png"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "hobse") {
      return (
        <img
          src={"https://www.hobse.com/images/hobse-logo.png"}
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "hostelworld") {
      return (
        <img
          src={
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmA0D1EFpTrkeIOy_Zy266JA-Cgopae36nBkE7pjws&s"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "cleartrip") {
      return (
        <img
          src={"https://etimg.etb2bimg.com/photo/94049186.cms"}
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.BookingSource?.toLowerCase() === "airbnb") {
      return (
        <img
          src={
            "https://www.digital.ink/wp-content/uploads/airbnb_logo_detail.jpg"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }
    if (props?.data?.type?.toLowerCase() === "yatra") {
      return (
        <img
          src={
            "https://ik.imgkit.net/3vlqs5axxjf/PCWW/uploadedImages/Articles/Interviews/2022/July/yatra%20logo.png?tr=w-270%2Ch-270%2Cfo-auto"
          }
          className="h-8 w-8"
          alt="photo"
        />
      );
    }

    if (props?.data?.type == "offline") {
      return (
       
        <img
        src={zonzo}
        className="h-5 w-5 mt-2"
        alt="photo"
        />
        
      );
    }
  };
  const [columnDefs] = useState([
    {
      headerName: "Channel",
      suppressMenu: true,
      resizable: true,
      field: "BookingSource",
      cellRenderer: Imagerenderer,
    },
    {
      headerName: "Room Type",
      suppressMenu: true,
      resizable: true,
      field: "RoomTypeName",
    },
    {
      headerName: "Guest name",
      suppressMenu: true,
      resizable: true,
      field: "GuestName",
    },
    {
      headerName: "Booking Date",
      suppressMenu: true,
      resizable: true,
      field: "BookingDate",
      valueGetter: (e) => {
        return moment(e.data.BookingDate).format("DD-MMM-YYYY");
      },
      sort: "desc",
    },
    {
      headerName: "Check In - Check Out",
      suppressMenu: true,
      resizable: true,
      field: "Checkin",
      valueGetter: (e) => {
        // console.log("dates",e.data.Checkin)

        const start = new Date(e.data.Checkin);
        const end = new Date(e.data.Checkout);
        const final = moment(start).format("DD-MMM-YYYY") + " - " + moment(end).format("DD-MMM-YYYY");
        // return(
        //  `${start.toLocaleDateString('en-US', {
        //     year: 'numeric',
        //     month: 'long',
        //     day: 'numeric',
        // })} - ${end.toLocaleDateString('en-US', {
        //     year: 'numeric',
        //     month: 'long',ls

        //     day: 'numeric',
        // })}`
        // )
        return final;
      },
    },
    {
      headerName: "Status",
      suppressMenu: true,
      resizable: true,
      field: "BookingStatus",
    },
  ]);
  const Data = [
    {
      id: 1,
      channel: "/Assets/Images/Dashboard/yatra.png",
      roomtype: "AC ROOM HERE",
      guestname: "AKASH KUMAR PRAJAPATI",
      booking: "23-Dec-2022",
      checkincheckout: "Dec 24(Sat) - Dec 25(Sun)",
      status: "Rejected",
    },
    {
      id: 2,
      channel: "/Assets/Images/Dashboard/doc.png",
      roomtype: "AC ROOM HERE",
      guestname: "AKASH KUMAR ",
      booking: "26-Dec-2022",
      checkincheckout: "Dec 24(Sat) - Dec 25(Sun)",
      status: "Rejected",
    },
  ];
  return (
    <div className="mt-5 ">
      <div
        className="ag-theme-alpine"
        style={{ height: "600px", width: "100%" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={data}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={10}
          rowSelection="multiple"
          onRowClicked={(e) => {
            // console.log("id",e)
            handleNavigate(e.data._id, e.data.type);
          }}
        ></AgGridReact>
      </div>
    </div>
  );
};
export default CancelledBooking;
