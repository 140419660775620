import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaBars, FaUser } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { RiNotification2Fill } from "react-icons/ri";
import { AiTwotoneSetting } from "react-icons/ai";
import { BsCalendar2Date } from "react-icons/bs";
import moment from "moment";
import { openSidebarRequest, closeSidebarRequest } from "../../Actions/sidebar";
import "./header.css";
import {
  ChangeHotel,
  getMeuser,
  getUserHotels,
} from "../../core/Services/User/login/login";

import Popper from "@mui/material/Popper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import { ClickAwayListener } from "@mui/base";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import {
  getNotifications,
  updateNotifications,
} from "../../core/Services/Data/notification";
import Button from "@mui/material/Button";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import httpService from "../../core/Services/HttpService/httpService";

import Cookies from "universal-cookie";
const cookies = new Cookies()


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  poper: {
    top: "10px !important",
    zIndex: "100",
    left: "-30px !important",
    maxWidth: "300px",
  },
  poper1: {
    top: "10px !important",
    zIndex: "100",
    left: " -305px !important",
    maxWidth: "300px",
  },
  item: {
    fontSize: "14px",
    padding: "12px 20px",
    "& p": {
      margin: 0,
      marginLeft: "10px",
    },
  },
}));

const Header = ({ mainuser, isActive, openSidebar, closeSidebar }) => {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const classes = useStyles();
  const [loading] = React.useState(false);
  const [user, setUser] = useState();
  const [selectedHotel, setSelectedHotel] = useState(0);
  const [openHotelDropdown, setOpenHotelDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [anchorE3, setAnchorE3] = React.useState(null);
  const [anchorE4, setAnchorE4] = React.useState(null);
  const [preview, setPreview] = useState();
  const [notifications, setNotifications] = useState({ notifications: [] });
  const [openModal, setOpenModal] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [clickedItems, setClickedItems] = useState(JSON.parse(localStorage.getItem('clickedItems')) || {});
  const [unreadNotification, setunreadNotification] = useState(parseInt(localStorage.getItem('unreadNotification')) || 0);

  useEffect(() => {
    const clickedItems = JSON.parse(localStorage.getItem('clickedItems'));
    if (clickedItems) {
      setClickedItems(clickedItems);
      setNotificationCount(null, clickedItems)
    }
  }, []);

  function setNotificationCount(data, clickitem) {
    console.log(notifications.notifications, "sfsui", clickedItems)
    const datas = data ? data : notifications;
    const click = clickitem ? clickitem : clickedItems
    let unreadCount = datas.notifications.reduce((count, notification) => {
      // Increment count if is_read is false or undefined (assuming undefined as unread)
      if (!notification.read && !click[notification._id]) {
        return count + 1;
      } else {
        return count;
      }
    }, 0);
    setunreadNotification(unreadCount);
    console.log(unreadCount, "unread notification count")
    localStorage.setItem('unreadNotification', unreadCount.toString());
    return unreadCount;
  }

  useEffect(() => {
    getNotifications().then(({ data }) => {
      setNotifications(data);
      setNotificationCount(data);
    });
    getUserHotels().then((data) => {
      setHotels(data);
    });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClicknot = (event) => {
    console.log(event.currentTarget);
    setAnchorE2(anchorE2 ? null : event.currentTarget);
  };
  const handleClickE4 = (event) => {
    setAnchorE4(anchorE4 ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorE2(null);
  };
  const handleClickAway2 = () => {
    setAnchorEl(null);
  };
  const handleClickAway3 = () => {
    setAnchorE3(null);
  };
  const handleClickAway4 = () => {
    setAnchorE4(null);
  };
  const hanldeClickpreview = (event) => {
    setAnchorE3(anchorE3 ? null : event.currentTarget);
  };


  function clickMessage(id) {
    if (!clickedItems[id]) {
      const updatedClickedItems = { ...clickedItems };
      updatedClickedItems[id] = true;
      setClickedItems(updatedClickedItems);
      localStorage.setItem('clickedItems', JSON.stringify(updatedClickedItems));
      setNotificationCount(null, updatedClickedItems);

    }
  }
  const handleMarkAsRead = async () => {
    updateNotifications()
      .then((response) => {
        console.log("success delete response message", response);
        setNotifications([]);
      })
      .catch((error) => {
        console.log("error response message", error);
      });
  };

  const open = Boolean(anchorEl);
  const openN = Boolean(anchorE2);
  const openP = Boolean(anchorE3);
  const open4 = Boolean(anchorE4);

  const openSidebarHandler = useCallback(() => {
    if (isActive) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }, [openSidebar, closeSidebar, isActive]);

  const hotelDropdownHandler = useCallback(() => {
    setOpenHotelDropdown(!openHotelDropdown);
  }, [openHotelDropdown]);
  const logout = () => {
    // localStorage.clear();
    cookies.remove("token")
    navigate("/", { replace: true });
    window.location.reload();
    // window.location.replace("/login")
  };

  useEffect(() => {
    getMeuser().then((res) => {
      setUser(res);
    });
    if (mainuser?.preview) {
      let arr = [];
      Object.entries(mainuser?.preview).forEach(([key, value]) => {
        arr.push({ key, value });
        // setPreview({key})
        //  console.log("key", key, "value" , value)
      });
      setPreview(arr);
    }
  }, [mainuser]);

  const handleChangeHotel = (userId) => {
    ChangeHotel({ userId }).then((res) => {
      console.log("res", res);
      const tokenkey = "token";
      httpService.setJWT(
        `Bearer ${cookies.get("token")}`
      );
      // localStorage.setItem(tokenkey, JSON.stringify(res?.token));
      cookies.set("token", res?.token)
      window.location.reload();
    });
  };

  const open1 = Boolean(anchorE2);
  const id = openN ? "simple-popover1" : undefined;

  useEffect(() => {
    // Delay popover display until after the initial render.
    const timer = setTimeout(() => {
      setShowPopover(true);
      setAnchorE2(null);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  // console.log(hotels);

  return (
    <>
      {path !== "/" &&
        path !== "/ota" &&
        path !== "/ota/verification-success" &&
        path !== "/forgot" &&
        path !== "/reset" &&
        path !== "/password-Change" &&
        path !== "/done" && (
          <nav className={isActive ? "header active" : "header"}>
            <button
              type="button"
              onClick={openSidebarHandler}
              className="open-button"
            >
              <FaBars size={20} />
            </button>
            <section className="hotels">
              <div
                className="selected-hotel cursor-pointer "
                onClick={handleClickE4}
                aria-describedby="simple-popper4"
              >
                {user?.imgUrl ? (
                  <img
                    src={user.imgUrl}
                    alt="Profile"
                    className="rounded-full w-24 h-24 justify-self-end mr-0"
                  />
                ) : (
                  <FaUser className="header-profile-image" />
                )}

                {/* <img
                  src={
                    user?.imgUrl
                      ? user?.imgUrl
                      : "/Assets/Images/Header/riverwood.png"
                  }
                  alt=""
                /> */}
                <span>{user?.hotelName}</span>
                {hotels.length > 1 && (
                  <button type="button">
                    <FiChevronDown size={20} />
                  </button>
                )}
              </div>
              <Popper
                id={open4 ? "simple-popper4" : undefined}
                open={open4}
                anchorEl={anchorE4}
                transition
                className={classes.poper1}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <ClickAwayListener onClickAway={handleClickAway4}>
                      <Paper>
                        <div className="w-64 flex flex-col gap-2  bg-white">
                          {hotels?.length > 1 &&
                            hotels?.map((hotel, index) => (
                              <div
                                key={`hotel-${index}`}
                                className={
                                  index === selectedHotel ? "active" : ""
                                }
                                onClick={() => handleChangeHotel(hotel?._id)}
                              >
                                <button
                                  type="button "
                                  className="flex gap-2 p-2 items-center "
                                >
                                  <img
                                    src={
                                      hotel?.imgUrl
                                        ? hotel?.imgUrl
                                        : "/Assets/Images/Header/riverwood.png"
                                    }
                                    alt=""
                                    className="w-10 h-10 rounded-full"
                                  />
                                  <span className="text-lg font-medium  ">
                                    {hotel.hotelName}
                                  </span>
                                </button>
                              </div>
                            ))}
                        </div>
                      </Paper>
                    </ClickAwayListener>
                  </Fade>
                )}
              </Popper>
            </section>
            <section className="platforms">
              <span>Preview on</span>
              <button type="button">
                <FiChevronDown size={20} onClick={hanldeClickpreview} id="2" />
              </button>
              {showPopover && (
                <Popper
                  id={openP ? "simple-popper2" : undefined}
                  open={openP}
                  anchorEl={anchorE3}
                  transition
                  className={classes.poper}
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <ClickAwayListener onClickAway={handleClickAway3}>
                        <Paper>
                          <div>
                            {preview &&
                              preview.map((option) => {
                                let abs = "";
                                if (option.key.toLowerCase() == "agoda") {
                                  abs = "/Assets/Images/agoda.png";
                                }
                                if (option.key.toLowerCase() == "mmt") {
                                  abs = "/OtaImg/mmt.png";
                                }
                                if (option.key.toLowerCase() == "goibibo") {
                                  abs = "/OtaImg/goibibo.png";
                                }
                                if (option.key.toLowerCase() == "expedia") {
                                  abs = "/OtaImg/expedia.png";
                                }
                                if (option.key.toLowerCase() == "travelguru") {
                                  abs = "/OtaImg/travel.png";
                                }
                                if (option.key.toLowerCase() == "easemytrip") {
                                  abs = "/OtaImg/easemytrip.png";
                                }
                                if (option.key.toLowerCase() == "happyeasygo") {
                                  abs = "/OtaImg/happyeasygo.png";
                                }
                                if (option.key.toLowerCase() == "via") {
                                  abs = "/OtaImg/via.png";
                                }
                                if (option.key.toLowerCase() == "hobse") {
                                  abs = "/OtaImg/hobse.png";
                                }
                                if (option.key.toLowerCase() == "hostelworld") {
                                  abs = "/OtaImg/hostelworld.png";
                                }
                                if (option.key.toLowerCase() == "cleartrip") {
                                  abs = "/OtaImg/cleartrip.png";
                                }
                                if (option.key.toLowerCase() == "booking.com") {
                                  abs = "/OtaImg/Booking.png";
                                }
                                if (option.key.toLowerCase() == "yatra") {
                                  abs = "/OtaImg/Yatra.png";
                                }
                                if (option.key.toLowerCase() == "airbnb") {
                                  abs = "/OtaImg/airbnb.png";
                                }

                                return (
                                  <MenuItem
                                    key={option.key}
                                    value={option.value}
                                  >
                                    <a
                                      href={option.value}
                                      target="_blank "
                                      rel="noreferrer"
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "14px",
                                        color: "#370D6F",
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        // border : "2px solid red",
                                        height: "2rem",
                                        padding: "20px",
                                      }}
                                    >
                                      <img
                                        src={abs}
                                        style={{ width: "60px" }}
                                      />
                                      {/* {option.key.toUpperCase()} */}
                                    </a>
                                  </MenuItem>
                                );
                              })}
                          </div>
                        </Paper>
                      </ClickAwayListener>
                    </Fade>
                  )}
                </Popper>
              )}
            </section>
            <section className="date">
              <BsCalendar2Date size={15} />
              <span>{moment().format("MMMM DD")}</span>
              <button type="button">{/* <FiChevronDown size={12} /> */}</button>
            </section>

            <button
              type="button"
              aria-describedby={id}
              id={id}
              onClick={handleClicknot}
            >
              <Badge badgeContent={unreadNotification} color="secondary">
                <RiNotification2Fill size={16} id="3" aria-describedby={id} />
              </Badge>
            </button>
            <Popper
              // id={openN ? "simple-popover1" : ""}
              id={openN ? "simple-popover1" : " "}
              open={openN}
              anchorEl={anchorE2}
              transition
              className={classes.poper}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Paper>
                      <div className="text-xs font-semibold text-slate-400 w-[19rem] pt-1.5 pb-2 px-4 overflow-scroll h-[320px]">
                        <b className="text-black">NOTIFICATIONS</b>
                        <ul>
                          {notifications.notifications &&
                            notifications.notifications.map((el, index) => {
                              return (
                                <li
                                  key={el._id}
                                  className="border-b border-slate-200 last:border-0 mt-4 cursor-pointer hover:bg-[#f1f5f9]"
                                  onClick={() => {
                                    console.log(el)
                                    el.bookingDocumentId &&
                                      navigate(
                                        `/bookings/Allbookings/easeroomdetails/${el.bookingDocumentId}`,
                                        { replace: true }
                                      );
                                    //   // window.location.reload();

                                  }}
                                  style={{ background: clickedItems[el._id] || el.read ? '#F2F6FC' : 'white' }}
                                >
                                  <span className="block text-sm mb-2" onClick={() => clickMessage(el._id)} >
                                    📣{" "}
                                    <span className="font-small text-slate-800" key={index} style={{ color: clickedItems[el._id] || el.read ? 'black' : 'black', fontWeight: clickedItems[el._id] || el.read ? 'normal' : 'bold' }} >
                                      {el?.message}
                                    </span>{" "}
                                  </span>
                                  <span className="block text-xs font-medium text-slate-400">
                                    {moment(el.createdAt).format("DD/MM/YYYY")}
                                    <span className="mr-2"></span>
                                    {moment(el.createdAt).format("HH:mm:ss")}
                                  </span>
                                </li>
                              );
                            })}

                          {/* <li className="border-b border-slate-200 last:border-0 mt-4 ">
                              <span className="block text-sm mb-2">📣 <span className="font-medium text-slate-800">AKASH</span> message</span>
                              <span className="block text-xs font-medium text-slate-400">03/Feb/2023</span>
                      </li>
                      <li className="border-b border-slate-200 last:border-0 mt-4 ">
                              <span className="block text-sm mb-2">📣 <span className="font-medium text-slate-800">AKASH</span> message</span>
                              <span className="block text-xs font-medium text-slate-400">04/Feb/2023</span>
                      </li>
                      <li className="border-b border-slate-200 last:border-0  mt-4">
                              <span className="block text-sm mb-2">📣 <span className="font-medium text-slate-800">AKASH</span> message</span>
                              <span className="block text-xs font-medium text-slate-400">02/Feb/2023</span>
                      </li>
                      <li className="border-b border-slate-200 last:border-0 mt-4 ">
                              <span className="block text-sm mb-2">📣 <span className="font-medium text-slate-800">AKASH</span> message</span>
                              <span className="block text-xs font-medium text-slate-400">03/Feb/2023</span>
                      </li>
                      <li className="border-b border-slate-200 last:border-0 mt-4 ">
                              <span className="block text-sm mb-2">📣 <span className="font-medium text-slate-800">AKASH</span> message</span>
                              <span className="block text-xs font-medium text-slate-400">04/Feb/2023</span>
                      </li> */}

                          {/* Need to review this logic */}

                          {/* <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "1rem",
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={handleMarkAsRead}
                            >
                              Mark as read
                            </Button>
                          </div> */}
                        </ul>
                      </div>
                    </Paper>
                  </ClickAwayListener>
                </Fade>
              )}
            </Popper>

            <button type="button">
              <AiTwotoneSetting onClick={handleClick} size={16} />
            </button>
            <Popper
              id={open ? "simple-popper" : undefined}
              open={open}
              anchorEl={anchorEl}
              transition
              className={classes.poper}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <ClickAwayListener onClickAway={handleClickAway2}>
                    <Paper>
                      <List>
                        <ListItem
                          button
                          className={classes.item}
                          onClick={() => {
                            handleClick();
                            navigate("/profile/property");
                          }}
                        >
                          {loading ? (
                            <React.Fragment></React.Fragment>
                          ) : (
                            <PersonRoundedIcon
                              style={{
                                fontSize: "20px",
                                color: "#370D6F",
                              }}
                            />
                          )}
                          <p style={{ color: "black" }}>Profile</p>
                        </ListItem>

                        <ListItem
                          button
                          className={classes.item}
                          onClick={logout}
                        >
                          {loading ? (
                            <React.Fragment></React.Fragment>
                          ) : (
                            <ExitToAppRoundedIcon
                              style={{
                                fontSize: "20px",
                                color: "#370D6F",
                              }}
                            />
                          )}
                          <p style={{ color: "black" }}>Logout</p>
                        </ListItem>
                      </List>
                    </Paper>
                  </ClickAwayListener>
                </Fade>
              )}
            </Popper>
          </nav>
        )}
    </>
  );
};



const mapStateToProps = (state) => ({
  isActive: state.sidebar,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openSidebar: openSidebarRequest,
      closeSidebar: closeSidebarRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
