import { LOAD_USER, LOGOUT } from "../Utils/constants"

const initialState = {
    isAuthenticated : false
}

const User = (state = initialState, {type, payload}) => {
    switch(type) {
        case LOAD_USER : {
            return {
                isAuthenticated : true
            }
        }
        case LOGOUT : {
            return {
                isAuthenticated : false
            }
        }
        default : {
            return state;
        }
    }
}

export default User;