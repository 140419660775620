import httpservice from "../HttpService/httpService";
import {toast} from 'react-toastify'


export function getAllroomRateandRoomplan(){
try {
    const {data : rooms} = httpservice.post("yatra/getAllRoomAndRatePlan",{
        startDate : "2022-07-01",
        endDate : "2030-12-31",
        hotelCode : "00009544"
    })
    return rooms
} catch (err) {
    if (err.response && err.response.status === 400) {
        toast.error(err.response.data)
    }
}
}

export async function getyatraBookingfromOta(){
    try {
        const {data : response} = await httpservice.get("yatra/getBookingyatrafromOta")
        return response
    } catch (err) {
      if (err.response && err.response.status === 400) {
        toast.error(err.response.data)
    }  
    }
}
export async function getSingleyatraBookingfromOta(id){
    try {
        const {data : response} = await httpservice.get("yatra/getSingleyatraBooking/" + id)
        return response
    } catch (err) {
      if (err.response && err.response.status === 400) {
        toast.error(err.response.data)
    }  
    }
}