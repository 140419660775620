import React, { useState, useEffect } from "react";
import { FaBed } from "react-icons/fa";
import { BsArrowLeftSquareFill, BsArrowRightSquareFill } from "react-icons/bs";
import { getALlPropertyDetails } from "../../core/Services/Data/performance";
import { useNavigate } from "react-router-dom";
const Tabs = ({ user }) => {
  const navigate = useNavigate();
  const [property, setProperty] = useState();
  useEffect(() => {
    // console.log("gf",user.live.easeRoom.secret );
    getALlPropertyDetails(user.live?.easeRoom?.secret).then((res) => {
      setProperty(res.data);
    });

    console.log("Property:", property);
  }, []);

  console.log("Property", property);
  return (
    <section className="tabs">
      <ul>
        <li onClick={() => navigate("/bookings/Allbookings?today=true")}>
          <ul>
            <li>
              <div className="icon">
                <FaBed size={24} />
              </div>
              {/* <span>Last 7 days</span> */}
            </li>
            <li>
              <span>Today's Bookings</span>
            </li>
            <li>
              <span>{property && property.totalBooking}</span>
              <span>
                {/* <img src='/Assets/Images/Dashboard/graph.png' alt=''/>
                            3.15% */}
              </span>
              {/* <span>Last 7 days</span> */}
            </li>
          </ul>
        </li>

        <li onClick={() => navigate("/bookings/todaycheckins")}>
          <ul>
            <li>
              <div className="icon">
                <BsArrowLeftSquareFill size={24} />
              </div>
            </li>
            <li>
              <span>Today's Check Ins</span>
            </li>
            <li>
              <span>{property && property.checkin}</span>
              <span></span>
            </li>
          </ul>
        </li>

        <li onClick={() => navigate("/bookings/todaycheckouts")}>
          <ul>
            <li>
              <div className="icon">
                <BsArrowRightSquareFill size={24} />
              </div>
            </li>
            <li>
              <span>Today's Check Outs</span>
            </li>
            <li>
              <span>{property && property.checkout}</span>
              <span></span>
            </li>
          </ul>
        </li>

        <li onClick={() => navigate("/bookings/Allbookings?today=true")}>
          <ul>
            <li>
              <div className="icon">
                <img src="/Assets/Images/Dashboard/ellipse.png" alt="" />
              </div>
              {/* <span>Last 7 days</span> */}
            </li>
            <li>
              <span>Today's Revenue</span>
            </li>
            <li>
              {property && property.totalmoney ? (
                <span>{(property && property.totalmoney) / 1000}k</span>
              ) : (
                <span>{(property && property.totalmoney) / 1000}</span>
              )}

              <span>
                {/* <img src='/Assets/Images/Dashboard/graph.png' alt=''/>
                            7.65% */}
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </section>
  );
};

export default Tabs;
