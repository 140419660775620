import React, {Fragment,useState} from 'react';
import { useLocation } from "react-router-dom";
import {TextField} from "@mui/material";
import {useFormik} from "formik";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import {Changepassword} from "../../core/Services/User/login/login";
import {toast} from "react-toastify";
import {FiEye,FiEyeOff} from 'react-icons/fi';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

const ResetPassword = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [type,setType] = useState("password")

    const Validation = yup.object({
        password: yup.string("New Password is required").required("New Password is required"),
        confirm: yup.string("Confirm Password is required").required("Confirm Password is required"),
    })
    //  console.log("State",state.email)
    const forgotPasswordForm = useFormik({
        initialValues: {
            password: '',
            confirm : ""
        },
        validationSchema: Validation,
        onSubmit: (values) => {
            // navigate("/done")
            Changepassword(state.otptoken,values.password).then((res)=>{
                if(res){
                    toast.success(res.message)
                    navigate("/done")
                }
            })
            // console.log('email', values);
           
        }
    })
    
    React.useEffect(()=>{
        // if(!(state && state.otpToken)){
           
        //     toast.error("Please send otp first!!")
        // }
    },[])
    return (
        // <Fragment>
        <div className = "flex justify-center w-[50vw] mx-auto mt-32 border-solid text-center">
            <div>
                <div className="py-5">
                    <h1 className="text-4xl font-bold">Set new password</h1>
                    <h3 className="text-lg my-3 text-center text-gray-400 max-w-sm">
                         Your new password must be different to previously used passwords
                    </h3>
                </div>
                <form onSubmit={forgotPasswordForm.handleSubmit}>
                    <div className="my-5">
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="password"
                            name="password"
                            type = {type}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                        onClick={() => setType(type == "text" ? "password" : "text")}
                                        >
                                        {type == "text" ? <FiEye size ={16} /> : <FiEyeOff size ={16} />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                            label="Password"
                            value={forgotPasswordForm.values.password}
                            onChange={forgotPasswordForm.handleChange}
                            onBlur={forgotPasswordForm.handleBlur}
                            helperText={forgotPasswordForm.touched.password && Boolean(forgotPasswordForm.errors.password) ? 'New Password is required' : ''}
                            error={forgotPasswordForm.touched.password && Boolean(forgotPasswordForm.errors.password)}
                        />
                    </div>
                    <div className="my-5">
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="confirm"
                            name="confirm"
                            label="Confirm password"
                            type = {type}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                        onClick={() => setType(type == "text" ? "password" : "text")}
                                        >
                                        {type == "text" ? <FiEye size ={16} /> : <FiEyeOff size ={16} />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                            value={forgotPasswordForm.values.confirm}
                            onChange={forgotPasswordForm.handleChange}
                            onBlur={forgotPasswordForm.handleBlur}
                            helperText={forgotPasswordForm.touched.confirm && Boolean(forgotPasswordForm.errors.confirm) ? 'Confirm Password is required' : ''}
                            error={forgotPasswordForm.touched.confirm && Boolean(forgotPasswordForm.errors.confirm)}
                        />
                    </div>
                    {/* <button
                        type="submit"
                        className="text-white rounded w-full my-4 uppercase app-button focus:outline-none focus:ring-4
                     focus:ring-gray-300 font-medium text-sm px-5 py-2.5 mr-2 mb-2">
                        Reset Password
                    </button> */}
                    <button type="submit" className='auth-button d-block m-auto'>Reset Password</button>
                </form>
            </div>
            </div>
        // </Fragment>
    );
};

export default ResetPassword;
