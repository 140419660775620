import React, {Fragment} from 'react';
import {TextField} from "@mui/material";
import {useFormik} from "formik";
import {useNavigate} from "react-router-dom";

const ResetLast = () => {
    const navigate = useNavigate();
    const forgotPasswordForm = useFormik({
        // initialValues: {
        //     email: ''
        // },
        onSubmit: (values) => {
            console.log('email', values);
           
        }
    })
    return (
        // <Fragment>
        <div className = "flex justify-center w-[50vw] mx-auto mt-32 border-solid text-center">
            <div>
                <div className="py-5">
                    <h1 className="text-4xl font-bold">Password reset</h1>
                    <h3 className="text-lg my-3 text-center text-gray-400 max-w-sm">
                        Your password has been successfully reset. <br/>
                        Click below to log in magically 
                    </h3>
                </div>
                <form onSubmit={forgotPasswordForm.handleSubmit}>
                    {/* <div className="my-5">
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="email"
                            name="email"
                            label="Enter your email"
                            value={forgotPasswordForm.values.email}
                            onChange={forgotPasswordForm.handleChange}
                            onBlur={forgotPasswordForm.handleBlur}
                            helperText={forgotPasswordForm.touched.email && Boolean(forgotPasswordForm.errors.email) ? 'Email is required' : ''}
                            error={forgotPasswordForm.touched.email && Boolean(forgotPasswordForm.errors.email)}
                        />
                    </div> */}
                    {/* <button
                        type="submit"
                        className="text-white rounded w-full my-4 uppercase app-button focus:outline-none focus:ring-4
                     focus:ring-gray-300 font-medium text-sm px-5 py-2.5 mr-2 mb-2">
                        Reset Password
                    </button> */}
                    <button type="submit" className='auth-button d-block m-auto'>Continue</button>
                </form>
            </div>
            </div>
        // </Fragment>
    );
};

export default ResetLast;
