import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";

const Navbar = () => {
  const path = useLocation().pathname;
  return (
    <div className="card br-8 reports-navbar">
      <div className="card-content">
        <Link
          to="/reports/inventory"
          className={path.includes("inventory") ? "active" : ""}
        >
          Inventory
        </Link>
        <Link
          to="/reports/rates"
          className={path.includes("rates") ? "active" : ""}
        >
          Rates
        </Link>
        <Link
          to="/reports/bookings"
          className={path.includes("bookings") ? "active" : ""}
        >
          Bookings
        </Link>
        {/* <Link
          to="/reports/invoices"
          className={path.includes("invoices") ? "active" : ""}
        >
          Invoices
        </Link>
        <Link
          to="/reports/offers"
          className={path.includes("offers") ? "active" : ""}
        >
          Offers
        </Link> */}
      </div>
    </div>
  );
};

export default Navbar;
