import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../Components/Reports/Navbar";
import Inventory from "./Inventory";
import Rates from "./Rates";
import Offers from "./Offers";
import Bookings from "./Bookings";
import Invoices from "./Invoices";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./reports.css";

const Reports = ({ user, setCurrentTabIndex }) => {
  const path = useLocation().pathname;
  React.useEffect(() => {
    setCurrentTabIndex(4);
  }, []);
  return (
    <div className="wrapper reports">
      <Navbar />
      {path.includes("/reports/inventory") && <Inventory user={user} />}
      {path.includes("/reports/rates") && <Rates user={user} />}
      {path.includes("/reports/bookings") && <Bookings user={user} />}
      {path.includes("/reports/offers") && <Offers />}
      {path.includes("/reports/invoices") && <Invoices />}
    </div>
  );
};

export default Reports;
