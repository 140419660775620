import React, { useCallback, useState } from "react";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import { BsCalendarDate } from "react-icons/bs";
import { AiOutlineCaretDown } from "react-icons/ai";

const Invoices = () => {
  const roomTypes = [
    {
      id: 1,
      name: "Commission",
    },
    {
      id: 2,
      name: "Performance Link Bonus",
    }
  ];

  const reportTypes = [
    {
      id: 1,
      name: "GoIbibo",
    },
    {
      id: 2,
      name: "Yatra",
    },
    {
      id: 3,
      name: "ClearTrip",
    },
    {
      id: 4,
      name: "MakeMyTrip",
    }
  ];

  const [filters, setFilters] = useState({
    startDate: new Date(),
    endDate: new Date(),
    roomType: 1,
    reportType: 1,
  });

  const [openDateDropdown, setOpenDateDropdown] = useState(false);
  const [openRoomTypeDropdown, setOpenRoomTypeDropdown] = useState(false);
  const [openReportTypeDropdown, setOpenReportTypeDropdown] = useState(false);
  const dateRangeHandler = useCallback(
    (event) => {
      setFilters({
        ...filters,
        startDate: event.selection.startDate,
        endDate: event.selection.endDate,
      });
    },
    [filters]
  );

  const dateDropdownHandler = useCallback(() => {
    setOpenDateDropdown(!openDateDropdown);
    setOpenRoomTypeDropdown(false);
    setOpenReportTypeDropdown(false);
  }, [openDateDropdown]);

  const roomTypeDropdownHandler = useCallback(() => {
    setOpenRoomTypeDropdown(!openRoomTypeDropdown);
    setOpenDateDropdown(false);
    setOpenReportTypeDropdown(false);
  }, [openRoomTypeDropdown]);

  const reportTypeDropdownHandler = useCallback(() => {
    setOpenReportTypeDropdown(!openReportTypeDropdown);
    setOpenDateDropdown(false);
    setOpenRoomTypeDropdown(false);
  }, [openReportTypeDropdown]);

  return (
    <>
      <section className="card br-8 filters-section">
        <div className="filters">
          <div className="date filter">
            <label>Select Date*</label>
            <div className={openDateDropdown ? "dropdown active" : "dropdown"}>
              <p>
                <span>
                  {moment(filters.startDate).format("DD MMMM Y")} -{" "}
                  {moment(filters.endDate).format("DD MMMM Y")}
                </span>
                <button onClick={dateDropdownHandler}>
                  <BsCalendarDate size={16} />
                </button>
              </p>
              <DateRangePicker
                className="date-range"
                ranges={[{ ...filters, key: "selection" }]}
                onChange={dateRangeHandler}
                showMonthAndYearPickers={false}
              />
            </div>
          </div>
          <div className="filter">
            <label>Room Type</label>
            <div
              className={openRoomTypeDropdown ? "dropdown active" : "dropdown"}
            >
              <p>
                <span>{roomTypes[filters.roomType - 1].name}</span>
                <button onClick={roomTypeDropdownHandler}>
                  <AiOutlineCaretDown size={16} />
                </button>
              </p>
              <ul>
                {roomTypes.map((room) => (
                  <li key={room.id}>
                    <button
                      className={room.id === filters.roomType ? "active" : ""}
                    >
                      <div>
                        <img alt="" src="/Assets/Images/Reports/room.png" />
                      </div>
                      <span>{room.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="filter">
            <label>Report Type</label>
            <div
              className={
                openReportTypeDropdown ? "dropdown active" : "dropdown"
              }
            >
              <p>
                <span>{reportTypes[filters.reportType - 1].name}</span>
                <button onClick={reportTypeDropdownHandler}>
                  <AiOutlineCaretDown size={16} />
                </button>
              </p>
              <ul>
                {reportTypes.map((report) => (
                  <li key={report.id}>
                    <button
                      className={
                        report.id === filters.reportType ? "active" : ""
                      }
                    >
                      <div>
                        <img alt="" src="/Assets/Images/Reports/report.png" />
                      </div>
                      <span>{report.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {"" + filters.startDate === "" + filters.endDate ? (
          <p className="no-data">
            No results are available for the selected date
          </p>
        ) : (
          <div className="filters-actions">
            <button>Reset All</button>
            <button className="active">Download</button>
          </div>
        )}
      </section>
      {"" + filters.startDate !== "" + filters.endDate && (
        <section className="card br-8">
          <div className="card-header">
            <span>Result</span>
            <p>
              {moment(filters.startDate).format("DD MMMM Y")} -{" "}
              {moment(filters.endDate).format("DD MMMM Y")}
            </p>
          </div>
          <div className="table">
            <table className="reports-table">
              <thead>
                <tr>
                  <th>Booking ID</th>
                  <th>Check-In</th>
                  <th>Check-Out</th>
                  <th>Booked On</th>
                  <th>Customer Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
                <tr>
                  <td>#0002</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                  <td>Table Cell</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      )}
    </>
  );
};

export default Invoices;
