import React, { useEffect, useState } from "react";
import {
  getSingleBookingFromOta,
  getSingleoffline,
} from "../../core/Services/Data/EaseRoom";
import { getSingleyatraBookingfromOta } from "../../core/Services/Data/YatraService";
import {  useParams, useLocation } from "react-router-dom";
import moment from "moment";

const BookingDetails = ({ user }) => {
  const [guest, setGuest] = useState();
  const params = useParams();
  const location = useLocation();
  
  useEffect(() => {
    if (location.pathname.split("/")[3] === "yatradetails") {
      getSingleyatraBookingfromOta(params.id).then((res) => {
        setGuest(res);
      });
    }
    if (location.pathname.split("/")[3] === "easeroomdetails") {
      getSingleBookingFromOta(params.id).then((res) => {
        setGuest(res);
      });
    }
    if (location.pathname.split("/")[3] === "offline") {
      getSingleoffline(params.id, user?.live?.easeRoom?.secret).then((res) => {
        const data = res.data[0];
        console.log("Offline Booking data => ", data);
        setGuest(data);
      });
    }
  }, [location]);

  return (
    <div className="flex">
      {guest && (
        <div className="w-[70%] bg-[#ffffff] p-5">
          <h1 className="font-bold text-indigo-900 text-2xl tracking-wide">
            Booking Details
          </h1>
          <hr className="mt-2" />
          <div className="mt-4 -2  grid grid-cols-4 gap-4">
            <div className="gap-5">
              <h1 className="font-bold text-lg my-2 text-indigo-900">
                Check in Date
              </h1>
              <p>
                {location.pathname.split("/")[3] === "yatradetails"
                  ? moment(guest.Checkin).format("ddd MMM DD YYYY")
                  : moment(guest.Checkin).format("ddd MMM DD YYYY")}{" "}
              </p>
            </div>
            <div>
              <h1 className="font-bold text-lg my-2 text-indigo-900">
                Check out Date
              </h1>
              <p>
                {location.pathname.split("/")[3] === "yatradetails"
                  ? moment(guest.Checkout).format("ddd MMM DD YYYY")
                  : moment(guest.Checkout).format("ddd MMM DD YYYY")}{" "}
              </p>
            </div>
            <div>
              <h1 className="font-bold text-lg my-2 text-indigo-900">
                Booking Date
              </h1>
              <p>
                {location.pathname.split("/")[3] === "yatradetails"
                  ? new Date(guest.BookingDate).toString().slice(0, 15)
                  : new Date(guest.BookingDate).toString().slice(0, 15)}{" "}
              </p>
            </div>
            <div>
              <h1 className="font-bold text-lg my-2 text-indigo-900">
                Stay Duration
              </h1>
              <p>
                {(new Date(guest.Checkout).getTime() -
                  new Date(guest.Checkin).getTime()) /
                  (1000 * 60 * 60 * 24)}{" "}
              </p>
            </div>
            <div>
              <h1 className="font-bold text-lg my-2 text-indigo-900">
                Booking ID
              </h1>
              <p>{guest.BookingId} </p>
            </div>
            <div>
              <h1 className="font-bold text-lg my-2 text-indigo-900">
                Booking Source
              </h1>
              <p>
                {guest.BookingSource
                  ? guest.BookingSource.charAt(0).toUpperCase() +
                    guest.BookingSource.slice(1).toLowerCase()
                  : "Offline"}{" "}
              </p>
            </div>
            <div>
              <h1 className="font-bold text-lg my-2 text-indigo-900">
                Special Note
              </h1>
              <p>
                {guest.SpecialNote
                  ? guest.SpecialNote.charAt(0).toUpperCase() +
                    guest.SpecialNote.slice(1).toLowerCase()
                  : "NA"}
              </p>
            </div>
            <div>
              <h1 className="font-bold text-lg my-2 text-indigo-900">
                Booking Status
              </h1>
              <p>
                {guest.BookingStatus == "Booked" ? "Confirmed" : "Cancelled"}{" "}
              </p>
            </div>
          </div>
          {/* var d1 = new Date("2023-02-01");
            var d2 = new Date("2023-02-28");
            var diff = d2.getTime() - d1.getTime();
            var daydiff = diff / (1000 * 60 * 60 * 24);
           console.log(daydiff) */}

          {/* <span>
                <p>Payment Mode</p>
                <h1 className = "font-bold text-lg mt-2">{"NA"} </h1>
               </span> */}
          {/* <span>
                <p>Transaction ID</p>
                <h1 className = "font-bold text-lg mt-2">{"NA"} </h1>
               </span> */}

          {/* <br/> */}
          <div className="my-12">
            <h1 className="font-bold text-indigo-900 text-2xl tracking-wide">
              Guest Details
            </h1>
            <hr className="mt-2" />
            <div className="mt-4 grid grid-cols-4 gap-4">
              <div>
                <h1 className="font-bold text-lg my-2 text-indigo-900">
                  Guest Name
                </h1>
                <p>{guest.GuestName} </p>
              </div>
              <div>
                <h1 className="font-bold text-lg my-2 text-indigo-900">
                  Adult Count
                </h1>
                <p>{guest.AdultCount ? guest.AdultCount : "NA"} </p>
              </div>
              <div>
                <h1 className="font-bold text-lg my-2 text-indigo-900">
                  Child Count
                </h1>
                <p>{guest.ChildCount} </p>
              </div>
              <div>
                <h1 className="font-bold text-lg my-2 text-indigo-900">
                  Number of Guests
                </h1>
                <p>
                  {parseInt(guest?.AdultCount) + parseInt(guest?.ChildCount)}
                </p>
              </div>
              <div>
                <h1 className="font-bold text-lg my-2 text-indigo-900">
                  E-mail
                </h1>
                <p>
                  {guest.GuestEmail
                    ? guest.GuestEmail
                    : guest.Email
                    ? guest.Email
                    : "NA"}{" "}
                </p>
              </div>
              <div>
                <h1 className="font-bold text-lg my-2 text-indigo-900">
                  Contact Number
                </h1>
                <p>
                  {guest.GuestMobile
                    ? guest.GuestMobile
                    : guest.Phone
                    ? guest.Phone
                    : "NA"}{" "}
                </p>
              </div>
              <div>
                <h1 className="font-bold text-lg my-2 text-indigo-900">City</h1>
                <p>
                  {/* {guest.City ? guest.City.toUpperCase() : "NA"}{" "} */}
                  {guest.City
                    ? guest.City.charAt(0).toUpperCase() +
                      guest.City.slice(1).toLowerCase()
                    : "NA"}
                </p>
              </div>
              <div>
                <h1 className="font-bold text-lg my-2 text-indigo-900">
                  State
                </h1>
                <p>
                  {/* {guest.State ? guest.State.toUpperCase() : "NA"}{" "} */}
                  {guest.State
                    ? guest.State.charAt(0).toUpperCase() +
                      guest.State.slice(1).toLowerCase()
                    : "NA"}
                </p>
              </div>
              {/* <span> */}
              {/* <p>Id Proof</p>
                <h1 className = "font-bold text-lg mt-2">Aadhar Card </h1> */}
              {/* </span> */}
            </div>
            {/* <span>
                <p>Contact Number</p>
                <h1 className = "font-bold text-lg mt-2">355555676 </h1>
                </span>
                <span>
                <p>Id Proof</p>
                <h1 className = "font-bold text-lg mt-2">Aadhar Card </h1>
                </span> */}
          </div>
          <div>
            <h1 className="font-bold text-indigo-900 text-2xl tracking-wide">
              Room & Payment Details
            </h1>
            <hr className="mt-2" />
            <div className="mt-4 grid grid-cols-4 gap-4">
              <div>
                <h1 className="font-bold text-lg my-2 text-indigo-900">
                  Room Type Name
                </h1>
                <p>{guest.RoomTypeName} </p>
              </div>
              <div>
                <h1 className="font-bold text-lg my-2 text-indigo-900">
                  Total Rooms
                </h1>
                <p>{guest.TotalRooms ? guest.TotalRooms : "NA"}</p>
              </div>
              <div className="gap-5">
                <h1 className="font-bold text-lg my-2 text-indigo-900">
                  Plan Name
                </h1>
                <p>{guest.Rateplanname ? guest.Rateplanname : guest.Meal} </p>
              </div>
              <div>
                <h1 className="font-bold text-lg my-2 text-indigo-900">
                  Net Rate
                </h1>
                <p>
                  ₹{" "}
                  {guest.NetRate
                    ? guest.NetRate
                    : guest?.BookingAmount
                    ? Number(guest?.BookingAmount / 1.18).toFixed(0)
                    : 0}
                  /-{" "}
                </p>
              </div>
              <div>
                <h1 className="font-bold text-lg my-2 text-indigo-900">
                  Taxes
                </h1>
                <p>
                  ₹{" "}
                  {guest.TaxAmount
                    ? guest.TaxAmount
                    : guest?.BookingAmount
                    ? Number(guest.BookingAmount) -
                      Number(guest?.BookingAmount / 1.18).toFixed(0)
                    : 0}
                  /-{" "}
                </p>
              </div>
              <div>
                <h1 className="font-bold text-lg my-2 text-indigo-900">
                  Total Amount{" "}
                </h1>
                <p>
                  {/* ₹ {parseInt(guest.NetRate) + parseInt(guest.TaxAmount)}/- */}
                  ₹{" "}
                  {guest.NetRate
                    ? parseInt(guest.NetRate) + parseInt(guest.TaxAmount)
                    : guest?.BookingAmount
                    ? guest?.BookingAmount
                    : 0}
                  /-
                </p>
              </div>
              <div>
                <h1 className="font-bold text-lg my-2 text-indigo-900">
                  Advance Payment
                </h1>
                <p>{guest.AdvancePayment ? guest.AdvancePayment : "NA"}</p>
              </div>
              <div>
                <h1 className="font-bold text-lg my-2 text-indigo-900">
                  Balance Left
                </h1>
                <p>
                  {/* {guest.AdvancePayment ? parseInt(guest.BookingAmount) - parseInt(guest.AdvancePayment) : guest.BookingAmount} */}
                  {guest.BalanceLeft}
                </p>
              </div>
              <div className="gap-5">
                <h1 className="font-bold text-lg my-2 text-indigo-900">
                  Payment Status
                </h1>
                <p>
                  {/* {guest?.PayAtHotel?.toLowerCase() == "yes"
                    ? "Pay At Hotel"
                    : "Paid"}{" "} */}

                  {/* {guest.BalanceLeft == guest.BookingAmount || guest.BalanceLeft == 0 ? "Paid" : "Partially Paid"} */}

                  {guest?.PayAtHotel?.toLowerCase() === "yes"
                    ? "Pay At Hotel"
                    : guest.BalanceLeft === guest.BookingAmount ||
                      guest.BalanceLeft === 0
                    ? "Paid"
                    : "Partially Paid"}
                </p>
              </div>

              {/* <span>
                <p>Discounts</p>
                <h1 className = "font-bold text-lg mt-2">{"NA"}</h1>
                </span> */}
            </div>
          </div>
        </div>
      )}
      <div className=" w-[28%] ml-[2%] ">
        <img
          className="w-[100%] h-[100%]"
          src="https://media.istockphoto.com/id/483599491/photo/luxury-house-with-swimming-pool-illuminated-at-night.jpg?b=1&s=170667a&w=0&k=20&c=xBnULBoPfr7qdM-c2Ej9AzmqQKlUDPh8OcFmE8_VuM8="
        />
      </div>
    </div>
  );
};
export default BookingDetails;
