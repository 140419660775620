import React, {useState, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import './ota.css';

const Ota = () => {

  const navigate = useNavigate();

  const [selectedOta, setSelectedOta] = useState([]);

  const otaHandler = useCallback((index) => {
    const selectedOtaData = selectedOta;
    const otaIndex = selectedOtaData.indexOf(index);
    if(otaIndex !== -1) {
      selectedOtaData.splice(otaIndex, 1)
    } else {
      selectedOtaData.push(index)
    }
    selectedOtaData.sort();
    setSelectedOta([...selectedOtaData]);
  }, [selectedOta])

  const submitHandler = useCallback((e) => {
    e.preventDefault();
    console.log(selectedOta.length)
    if(selectedOta.length > 0) {
      navigate('/ota/verification-success');
    }
  }, [selectedOta, navigate])

  return (
    <div className='auth-container onboarding-grids ota'>
      <section>
        <img alt='' src='/Assets/Images/Onboarding/ota.png' />
      </section>
      <section className='d-flex flex-column justify-content-center align-items-center'>
        <h2>Choose Your OTAs</h2>
        <form onSubmit={submitHandler}>
        <ul className='d-grid'>
          <li className={selectedOta.indexOf(0) !== -1 ? 'selected' : ''}>
            <button type='button' onClick={() => otaHandler(0)}>
              <img src='/Assets/Images/Onboarding/booking-1.png' alt=''/>
            </button>
          </li>
          <li className={selectedOta.indexOf(1) !== -1 ? 'selected' : ''}>
            <button type='button' onClick={() => otaHandler(1)}>
              <img src='/Assets/Images/Onboarding/goibibo.png' alt=''/>
            </button>
          </li>
          <li className={selectedOta.indexOf(2) !== -1 ? 'selected' : ''}>
            <button type='button' onClick={() => otaHandler(2)}>
              <img src='/Assets/Images/Onboarding/airbnb.png' alt=''/>
            </button>
          </li>
          <li className={selectedOta.indexOf(3) !== -1 ? 'selected' : ''}>
            <button type='button' onClick={() => otaHandler(3)}>
              <img src='/Assets/Images/Onboarding/expedia.png' alt=''/>
            </button>
          </li>
          <li className={selectedOta.indexOf(4) !== -1 ? 'selected' : ''}>
            <button type='button' onClick={() => otaHandler(4)}>
              <img src='/Assets/Images/Onboarding/agoda.png' alt=''/>
            </button>
          </li>
          <li className={selectedOta.indexOf(5) !== -1 ? 'selected' : ''}>
            <button type='button' onClick={() => otaHandler(5)}>
              <img src='/Assets/Images/Onboarding/cleartrip.png' alt=''/>
            </button>
          </li>
        </ul>
        <button type='submit' className={selectedOta.length > 0 ? 'auth-button d-block m-auto' : 'auth-button disabled d-block m-auto'}>Verify OTA</button>
        </form>
      </section>
    </div>
  )
}

export default Ota