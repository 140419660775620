import { combineReducers } from "redux";
import Sidebar from "./Sidebar";
import User from "./User";

const RootReducer = combineReducers({
  sidebar: Sidebar,
  user: User,
});

export default RootReducer;
