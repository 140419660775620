import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../Pages/Login/Login";
import Logout from "../Pages/logOut/LogOut";
import Forgotpassword from "../Pages/forgot/Forgotpassword";
import ResentmailSend from "../Pages/resetMailsend/ResentmailSend";
import ResetLast from "../Pages/resetLast/ResetLast";
import ResetPassword from "../Pages/resetPass/ResetPass";
import Ota from "../Pages/Ota/Ota";
import OtaVerificationSuccess from "../Pages/OtaVerificationSuccess/OtaVerificationSuccess";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Reports from "../Pages/Reports/Reports";
import RatesAndInventory from "../Pages/RatesAndInventory/RatesAndInventory";
import Bookings from "../Pages/Bookings/Bookings";
import BookingDetails from "../Pages/Bookings/BookingDetails";
import PropertyDetails from "../Pages/propertyDetails/Property";
import PropertyForm from "../Pages/propertyDetails/addProperty";
import BookingForm from "../Pages/Bookings/addBooking";
import Analytics from "../Pages/Analytics2/Analytics";
import Support from "../Pages/Support/Support";
import BulkUpdateInventory from "../Pages/RatesAndInventory/bulkUpdate/BulkUpdateInventory";
import Profile from "../Pages/profile/profile";
import EditProfile from "../Pages/profile/editProfile";
import EditBookingForm from "./common/EditBooking";
import Cookies from "universal-cookie";
const cookies = new Cookies()


const AllRoutes = ({ user, setCurrentTabIndex }) => {
  // const token = localStorage.getItem("token");
  const token = cookies.get("token");
  // console.log("Users", user);
  return (
    <Routes>
      {user && user._id && (
        <>
          <Route
            exact
            path="/dashboard"
            element={
              <Dashboard user={user} setCurrentTabIndex={setCurrentTabIndex} />
            }
          />
          {/* <Route exact path="/profile" element={<Profile />} /> */}
          <Route path="/profile/:section" element={<Profile />} />

          <Route exact path="/profile/property" element={<Profile />} />
          <Route exact path="/profile/address" element={<Profile />} />
          <Route exact path="/profile/personal" element={<Profile />} />

          <Route exact path="/EditProfile" element={<EditProfile />} />

          <Route
            exact
            path="/reports/inventory"
            element={
              <Reports user={user} setCurrentTabIndex={setCurrentTabIndex} />
            }
          />
          <Route
            exact
            path="/reports/rates"
            element={
              <Reports user={user} setCurrentTabIndex={setCurrentTabIndex} />
            }
          />
          <Route
            exact
            path="/reports/bookings"
            element={
              <Reports user={user} setCurrentTabIndex={setCurrentTabIndex} />
            }
          />
          <Route
            exact
            path="/reports/invoices"
            element={<Reports setCurrentTabIndex={setCurrentTabIndex} />}
          />
          <Route
            exact
            path="/reports/offers"
            element={<Reports setCurrentTabIndex={setCurrentTabIndex} />}
          />
          <Route
            exact
            path="/rates-inventory"
            element={
              <RatesAndInventory
                user={user}
                setCurrentTabIndex={setCurrentTabIndex}
              />
            }
          />
          <Route
            exact
            path="/rates-inventory/:id"
            element={<RatesAndInventory />}
          />
          <Route
            exact
            path="/rates-inventory/bukUpdate"
            element={
              <BulkUpdateInventory
                user={user}
                setCurrentTabIndex={setCurrentTabIndex}
              />
            }
          />
          <Route
            exact
            path="/rates-inventory/bulkUpdateRates"
            element={
              <BulkUpdateInventory
                user={user}
                setCurrentTabIndex={setCurrentTabIndex}
              />
            }
          />
          <Route
            exact
            path="/rates-inventory/restrictions"
            element={
              <BulkUpdateInventory
                user={user}
                setCurrentTabIndex={setCurrentTabIndex}
              />
            }
          />
          <Route
            exact
            path="/bookings/add"
            element={<BookingForm user={user} />}
          />
          <Route
            exact
            path="/bookings/editbooking/:id"
            element={<EditBookingForm user={user} />}
          />
          <Route
            exact
            path="/bookings/Allbookings"
            element={
              <Bookings user={user} setCurrentTabIndex={setCurrentTabIndex} />
            }
          />
          <Route
            exact
            path="/bookings/Allbookings/easeroomdetails/:id"
            element={<BookingDetails setCurrentTabIndex={setCurrentTabIndex} />}
          />
          <Route
            exact
            path="/bookings/Allbookings/yatradetails/:id"
            element={<BookingDetails setCurrentTabIndex={setCurrentTabIndex} />}
          />
          <Route
            exact
            path="/bookings/Allbookings/offline/:id"
            element={
              <BookingDetails
                user={user}
                setCurrentTabIndex={setCurrentTabIndex}
              />
            }
          />
          <Route
            exact
            path="/bookings/upcoming"
            element={<Bookings setCurrentTabIndex={setCurrentTabIndex} />}
          />
          <Route
            exact
            path="/bookings/past"
            element={<Bookings setCurrentTabIndex={setCurrentTabIndex} />}
          />
          <Route
            exact
            path="/bookings/canceled"
            element={<Bookings setCurrentTabIndex={setCurrentTabIndex} />}
          />
          <Route
            exact
            path="/bookings/payAthotel"
            element={<Bookings setCurrentTabIndex={setCurrentTabIndex} />}
          />
          <Route
            exact
            path="/bookings/:id"
            element={<Bookings setCurrentTabIndex={setCurrentTabIndex} />}
          />
          <Route
            exact
            path="/bookings/todaycheckins"
            element={<Bookings user={user} setCurrentTabIndex={setCurrentTabIndex} />}
          />
          <Route
            exact
            path="/bookings/todaycheckouts"
            element={<Bookings user={user} setCurrentTabIndex={setCurrentTabIndex} />}
          />
          <Route
            exact
            path="/propertyDetails/Allrooms"
            element={
              <PropertyDetails setCurrentTabIndex={setCurrentTabIndex} />
            }
          />

          <Route
            exact
            path="/propertyDetails/addRoom"
            element={<PropertyForm setCurrentTabIndex={setCurrentTabIndex} />}
          />

          <Route
            exact
            path="/propertyDetails/active"
            element={
              <PropertyDetails setCurrentTabIndex={setCurrentTabIndex} />
            }
          />
          <Route
            exact
            path="/propertyDetails/blocked"
            element={
              <PropertyDetails setCurrentTabIndex={setCurrentTabIndex} />
            }
          />
          <Route
            exact
            path="/propertyDetails/amenities-service"
            element={
              <PropertyDetails setCurrentTabIndex={setCurrentTabIndex} />
            }
          />
          <Route
            exact
            path="/propertyDetails/policies"
            element={
              <PropertyDetails setCurrentTabIndex={setCurrentTabIndex} />
            }
          />
          <Route
            exact
            path="/Analytics"
            element={<Analytics setCurrentTabIndex={setCurrentTabIndex} />}
          />

          <Route
            exact
            path="/Support/active"
            element={<Support setCurrentTabIndex={setCurrentTabIndex} />}
          />
          <Route
            exact
            path="/Support/closed"
            element={<Support setCurrentTabIndex={setCurrentTabIndex} />}
          />

        </>
      )}

      <Route exact path="/forgot" element={<Forgotpassword />} />
      <Route exact path="/reset" element={<ResentmailSend />} />
      <Route exact path="/password-Change" element={<ResetPassword />} />
      <Route exact path="/done" element={<ResetLast />} />
      <Route exact path="/" element={<Login />} />
      <Route exact path="/ota" element={<Ota />} />
      <Route
        exact
        path="/ota/verification-success"
        element={<OtaVerificationSuccess />}
      />
      <Route exact path="/logout" element={<Logout />} />
      {/* <Route exact path="*" element={<Navigate to="/" />} /> */}
    </Routes>
  );
};

export default AllRoutes;
