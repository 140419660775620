import { toast } from "react-toastify";
// import jwtDecode from "jwt-decode";
import httpservice from "../../HttpService/httpService";
import Cookies from "universal-cookie";

const tokenkey = "token";
const cookies = new Cookies()

export async function login(email, password) {
  try {
    const { data: token } = await httpservice.post("/users/login", {
      email,
      password,
    });
    console.log("Token here", token.user.userRole);
    if (token.user.userRole === "HOTEL_MANAGER") {
      // localStorage.setItem(tokenkey, JSON.stringify(token.token));
      cookies.set("token", token.token)
      httpservice.setJWT();
      return token;
    } else {
      toast.error("You are not Authorised to used it");
    }
  } catch (ex) {
    if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
      // console.log("ALASH error", ex.response.data.message)
      toast.error(ex.response.data.message);
    }
  }
}

export async function sendOtp(email) {
  try {
    const { data: send } = await httpservice.post("/users/forgot", {
      email: email,
    });
    return send;
  } catch (ex) {
    if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
      // console.log("ALASH error", ex.response.data.message)
      toast.error(ex.response.data.message);
    }
  }
}

export async function matchOtp(email, otp) {
  try {
    const { data: result } = await httpservice.post(
      "/users/checkOtp",

      {
        email: email,
        otp: otp,
      }
    );
    return result;
  } catch (ex) {
    if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
      // console.log("ALASH error", ex.response.data.message)
      toast.error(ex.response.data.message);
    }
  }
}

export async function Changepassword(otpToken, password) {
  try {
    const { data: result } = await httpservice.post(
      "/users/changepassword",
      {
        password: password,
      },
      { headers: { Authorization: "Bearer " + otpToken } }
    );

    return result;
  } catch (ex) {
    if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
      // console.log("ALASH error", ex.response.data)
      toast.error(ex.response.data.errors[0].msg);
    }
  }
}
export async function changePasswordCall( password) {
  try {
    const { data: result } = await httpservice.post(
      "/users/changepassword",
      {
        password: password,
      },
      { headers: { Authorization: "Bearer " + cookies.get("token") } }
    );

    return result;
  } catch (ex) {
    if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
      // console.log("ALASH error", ex.response.data)
      toast.error(ex.response.data.errors[0].msg);
    }
  }
}

httpservice.setJWT(`Bearer ${cookies.get("token")}`);
// httpservice.setJWT(`Bearer ${localStorage.getItem(tokenkey)}`);

export function logout() {
  // localStorage.removeItem(tokenkey);
  cookies.remove("token")
}

export async function findUser(id) {
  try {
    const result = await httpservice.get(`/users/${id}`);
    return result.data;
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      console.log(ex.response.data);
    }
  }
}
export async function getMeuser() {
  try {
    const { data: result } = await httpservice.get("/users/me");
    return result;
  } catch (error) {}
}
export async function getAddressViaPincode(address) {
  try {
    const data = await httpservice.get(
      `users/getLocationByPincode?address=${address}`
    );
    return data;
  } catch (error) {}
}

export async function getUserandUpdate(id, payload) {
  try {
    const { data: result } = await httpservice.put(`/users/${id}`, payload);
    return result;
  } catch (error) {}
}
// export function getCurrentUser() {
//     const token = localStorage.getItem(tokenkey);
//     const result = jwtDecode(token, 'Password6654yrht4764');
//     return result.id
// }
export async function getUserHotels() {
  try {
    const { data: result } = await httpservice.get("/users/getUserHotels");
    return result;
  } catch (error) {
    throw new Error(error);
  }
}

export async function ChangeHotel(payload) {
  try {
    const { data: result } = await httpservice.post(
      "/users/changeHotel",
      payload
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
}