import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";

const NavbarProperty = () => {
  const path = useLocation().pathname;
  return (
    <div className="card rounded-r-none br-8 reports-navbar col-span-5">
      <div className="card-content">
        <Link
          to="/propertyDetails/Allrooms"
          className={path.includes("Allrooms") ? "active" : ""}
        >
          All Rooms
        </Link>
        {/* <Link
          to="/propertyDetails/active"
          className={path.includes("active") ? "active" : ""}
        >
          Active
        </Link>
        <Link
          to="/propertyDetails/blocked"
          className={path.includes("blocked") ? "active" : ""}
        >
          Blocked
        </Link>
        <Link
          to="/propertyDetails/amenities-service"
          className={path.includes("amenities-service") ? "active" : ""}
        >
          Amenities & Services
        </Link>
        <Link
          to="/propertyDetails/policies"
          className={path.includes("policies") ? "active" : ""}
        >
          Policies
        </Link> */}
      </div>
    </div>
  );
};

export default NavbarProperty;
