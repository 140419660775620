import { toast } from "react-toastify";
import { logout } from "../../core/Services/User/login/login";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  logout();
  toast.success("Loged Out!!");
  window.location.reload();
  // navigate("/login", { replace: true });

  return null;
};

export default Logout;
