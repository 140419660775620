import React, { useCallback, useState, useEffect } from "react";
import "./ratesandinventory.css";
import {
  AiFillPlusCircle,
  AiFillMinusCircle,
  AiOutlineConsoleSql,
} from "react-icons/ai";
import { IoCalendarNumberOutline } from "react-icons/io5";
import {
  BsDot,
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
} from "react-icons/bs";
import {
  getAllRateplanEaseRoom,
  getAllRates,
  updateSingleInventory,
  updateSingleRate,
} from "../../core/Services/Data/EaseRoom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { ContactSupportOutlined } from "@material-ui/icons";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { IoPerson } from "react-icons/io5";

const RatesAndInventory = ({ user, setCurrentTabIndex }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    setCurrentTabIndex(2);
  }, []);

  const [fieldData, setFieldData] = useState({});
  const [ratePlan, setRatePlan] = useState();
  const [ratesData, setRatesData] = useState();
  const [count, setCount] = useState(0);
  const [begin, setBegin] = useState(null);
  const [inventPayload, setInventPayload] = useState([]);
  const [ratesPayload, setRatesPayload] = useState([]);
  const [currentDate, setCurrentDate] = useState(moment());
  const [isActive, setIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState({});

  const toggleActive = (id) => {
    setActiveIndex((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  useEffect(() => {
    setBegin(
      moment(currentDate)
        .add(count * 7, "days")
        .format("YYYY-MM-DD")
    );
  }, [count, currentDate]);

  useEffect(() => {
    getAllRateplanEaseRoom(user)
      .then((res) => {
        const newRatePlans = {};

        res.RatePlan.forEach((room) => {
          if (newRatePlans[room.RoomTypeId] === undefined) {
            newRatePlans[room.RoomTypeId] = {
              RoomTypeId: room.RoomTypeId,
              RoomTypeName: room.RoomTypeName,
              RateInfo: [],
            };
          }
          newRatePlans[room.RoomTypeId].RateInfo.push({
            RateplanID: room.RateplanID,
            RatePlanName: room.RateplanName,
          });
        });

        setRatePlan(Object.values(newRatePlans));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //function to store changed inventories
  const handleInput = (e, key, roomTypeId) => {
    console.log("here in", e);
    const getDate = new Date(begin);
    getDate.setDate(getDate.getDate() + key);

    setRatesData((prevRatesData) => {
      const updatedRatesData = [...prevRatesData];

      if (updatedRatesData[key] === 0) {
        updatedRatesData[key] = {
          hotelId: `${user?.live?.easeRoom?.secret}`,
          rateDate: getDate,
          roomType: [
            {
              roomTypeId: roomTypeId,
              numberOfRooms: e.target.value,
              rateInfo: [],
            },
          ],
        };
      }

      const findRateIndex = updatedRatesData[key].roomType.findIndex(
        (item) => item.roomTypeId == roomTypeId
      );

      if (findRateIndex != -1) {
        updatedRatesData[key].roomType[findRateIndex].numberOfRooms =
          e.target.value;
      } else if (findRateIndex == -1) {
        updatedRatesData[key].roomType.push({
          roomTypeId: roomTypeId,
          numberOfRooms: e.target.value,
          rateInfo: [],
        });
      }

      return updatedRatesData;
    });

    setInventPayload((prevInventPayload) => {
      const newPayload = [...prevInventPayload];
      const index = newPayload.findIndex(
        (input) =>
          input.roomId === roomTypeId &&
          input.rateDate === moment(getDate).format("DD/MM/YYYY")
      );

      if (index !== -1) {
        newPayload[index].rooms = e.target.value;
      } else {
        newPayload.push({
          roomId: roomTypeId,
          rateDate: moment(getDate).format("DD/MM/YYYY"),
          rooms: e.target.value,
        });
      }

      return newPayload;
    });
  };

  //function to store changed rates
  const handleRates = (e, key, field, rTypeId, rPlanId) => {
    const getDate = new Date(begin);
    getDate.setDate(getDate.getDate() + key);

    setRatesData((prevData) => {
      const newRatesData = [...prevData];

      if (newRatesData[key] === 0) {
        newRatesData[key] = {
          hotelId: `${user?.live?.easeRoom?.secret}`,
          rateDate: getDate,
          roomType: [
            {
              roomTypeId: rTypeId,
              numberOfRooms: "",
              rateInfo: [
                {
                  ratePlanId: rPlanId,
                  [field]: e.target.value,
                },
              ],
            },
          ],
        };
      }

      const findRateIndex = newRatesData[key].roomType.findIndex(
        (input) => input.roomTypeId == rTypeId
      );

      if (findRateIndex != -1) {
        const entryIndex = newRatesData[key].roomType[
          findRateIndex
        ].rateInfo.findIndex((input) => input.ratePlanId == rPlanId);

        if (entryIndex != -1) {
          newRatesData[key].roomType[findRateIndex].rateInfo[entryIndex][
            field
          ] = e.target.value;
        } else if (entryIndex == -1) {
          newRatesData[key].roomType[findRateIndex].rateInfo.push({
            ratePlanId: rPlanId,
            [field]: e.target.value,
          });
        }
      } else if (findRateIndex == -1) {
        newRatesData[key].roomType.push({
          roomTypeId: rTypeId,
          numberOfRooms: "",
          rateInfo: [
            {
              ratePlanId: rPlanId,
              [field]: e.target.value,
            },
          ],
        });
      }

      return newRatesData;
    });

    setRatesPayload((prevRatesPayload) => {
      const newRates = [...prevRatesPayload];
      const rateIndex = newRates.findIndex(
        (input) =>
          input.roomId === rTypeId &&
          input.rateDate ===
            moment(ratesData[key].rateDate).format("DD/MM/YYYY") &&
          input.rateId === rPlanId
      );

      if (rateIndex !== -1) {
        newRates[rateIndex][field] = e.target.value;
      } else {
        newRates.push({
          roomId: rTypeId,
          rateDate: moment(getDate).format("DD/MM/YYYY"),
          rateId: rPlanId,
          [field]: e.target.value,
        });
      }

      return newRates;
    });
  };

  const handleSubmit = () => {
    // update inventories
    updateSingleInventory(inventPayload)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));

    //update rates
    updateSingleRate(ratesPayload)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));

    toast.success("Submited successfully");
  };

  useEffect(() => {
    if (begin) {
      const payload = {
        startDate: moment(begin).format("DD/MM/YYYY"),
        endDate: moment(begin).add(6, "days").format("DD/MM/YYYY"),
      };

      getAllRates(payload)
        .then(async ({ data }) => {
          let weekArray = [];

          for (let i = 0; i < 7; i++) {
            let currentDate = new Date(begin);
            currentDate.setDate(currentDate.getDate() + i);

            const result = data.rates.findIndex(
              (entry) =>
                moment(entry.rateDate).format("YYYY-MM-DD") ===
                moment(currentDate).format("YYYY-MM-DD")
            );
            const index = result !== -1 ? result : null;

            if (index !== null) {
              weekArray.push(data.rates[index]);
            } else {
              weekArray.push(0);
            }
          }

          setRatesData(weekArray);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [begin]);

  return (
    <div className="rates-inventory px-4 pt-2 pb-8 blue br-8">
      <section>
        <div className="property-header">
          <h2>Rate and Inventory Status</h2>
          <div className="actions">
            <div className="dropdown">
              <button onClick={() => navigate("/rates-inventory/bukUpdate")}>
                Bulk update
              </button>
            </div>

            <div className="calender">
              <button>
                <BsChevronDoubleLeft
                  size={16}
                  onClick={() => {
                    const fields = { ...fieldData };
                    const obj = {};
                    Object.entries(fields).forEach(([key, value]) => {
                      if (key) {
                        Object.entries(fields[key]).forEach(
                          ([innerKey, innerValue]) => {
                            if (innerKey) {
                              obj[key] = { ...obj[key] };
                              obj[key][innerKey] = "";
                            }
                          }
                        );
                      }
                    });
                    setFieldData({ ...obj });
                    if (count <= 0) {
                      setCount(0);
                    } else {
                      setCount(count - 1);
                    }
                  }}
                />
              </button>
              <IoCalendarNumberOutline size={16} />
              <button>
                <BsChevronDoubleRight
                  size={16}
                  onClick={() => {
                    const fields = { ...fieldData };
                    const obj = {};
                    Object.entries(fields).forEach(([key, value]) => {
                      if (key) {
                        Object.entries(fields[key]).forEach(
                          ([innerKey, innerValue]) => {
                            if (innerKey) {
                              obj[key] = { ...obj[key] };
                              obj[key][innerKey] = "";
                            }
                          }
                        );
                      }
                    });

                    setFieldData({ ...obj });
                    if (count >= 11) {
                      setCount(11);
                    } else {
                      setCount(count + 1);
                    }
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="dates">
          <div class="rooms">
            <h3>Rooms</h3>
          </div>

          <div className="days">
            <h5>
              {moment(begin).format("ddd")}
              <BsDot size={25} />
              {moment(begin).format("DD/MM")}
            </h5>
            <h5>
              {moment(begin).add(1, "days").format("ddd")}
              <BsDot size={25} />
              {moment(begin).add(1, "days").format("DD/MM")}
            </h5>
            <h5>
              {moment(begin).add(2, "days").format("ddd")}
              <BsDot size={25} />
              {moment(begin).add(2, "days").format("DD/MM")}
            </h5>
            <h5>
              {moment(begin).add(3, "days").format("ddd")}
              <BsDot size={25} />
              {moment(begin).add(3, "days").format("DD/MM")}
            </h5>
            <h5>
              {moment(begin).add(4, "days").format("ddd")}
              <BsDot size={25} />
              {moment(begin).add(4, "days").format("DD/MM")}
            </h5>
            <h5>
              {moment(begin).add(5, "days").format("ddd")}
              <BsDot size={25} />
              {moment(begin).add(5, "days").format("DD/MM")}
            </h5>
            <h5>
              {moment(begin).add(6, "days").format("ddd")}
              <BsDot size={25} />
              {moment(begin).add(6, "days").format("DD/MM")}
            </h5>
          </div>
        </div>

        {ratePlan &&
          ratePlan.map((plan) => (
            <div className="rates-container" key={plan.RoomTypeId}>
              <div className="room-type">
                <div>
                  <h3>{plan.RoomTypeName}</h3>
                </div>

                <div className="inventories">
                  {ratesData &&
                    ratesData.map((e, i) => {
                      if (e === 0) {
                        return (
                          <input
                            value={""}
                            key={i}
                            onChange={(e) => handleInput(e, i, plan.RoomTypeId)}
                          />
                        );
                      }
                      const roomInfo = e.roomType.find(
                        (room) => room.roomTypeId == plan.RoomTypeId
                      );
                      console.log("roomInfo", roomInfo);
                      if (roomInfo) {
                        return (
                          <input
                            onChange={(e) => handleInput(e, i, plan.RoomTypeId)}
                            value={roomInfo.numberOfRooms}
                            key={i}
                          />
                        );
                      }
                      return (
                        <input
                          value={""}
                          onChange={(e) => handleInput(e, i, plan.RoomTypeId)}
                          key={i}
                        />
                      );
                    })}
                </div>
              </div>

              {plan.RateInfo.map((meal) => (
                <div className="meal-data" key={meal.RateplanID}>
                  <div className="meal-section">
                    <div className="meal-plan">
                      <div className="meal">
                        <h3>
                          {meal.RatePlanName === "Room Only"
                            ? "EP"
                            : meal.RatePlanName === "With Breakfast"
                            ? "CP"
                            : meal.RatePlanName === "Breakfast and Dinner"
                            ? "MAP"
                            : meal.RatePlanName ===
                              "Breakfast, Lunch and Dinner"
                            ? "AP"
                            : ""}
                        </h3>
                        <div>
                          <button
                            onClick={() => toggleActive(meal.RateplanID)}
                            style={{ transition: "all 5s ease" }}
                          >
                            {isActive && activeIndex === meal.RateplanID ? (
                              <IoIosArrowDropupCircle
                                className="active"
                                style={{ marginLeft: "8px", marginTop: "5px" }}
                              />
                            ) : (
                              <IoIosArrowDropdownCircle
                                className={
                                  isActive && activeIndex === meal.RateplanID
                                    ? "active"
                                    : ""
                                }
                                style={{ marginLeft: "8px", marginTop: "5px" }}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="adults">
                        <h4
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "59px",
                          }}
                        >
                          <span>1</span>&nbsp;
                          <IoPerson />
                        </h4>
                        <h4
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "59px",
                          }}
                        >
                          <span>2</span>&nbsp;
                          <IoPerson />
                        </h4>

                        <div
                          className={`adultdrop ${
                            activeIndex[meal.RateplanID] ? "active" : ""
                          }`}
                          style={{ transition: "all 0.3s ease", }}
                        >
                          <h4
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "59px",
                            }}
                          >
                            <span>3</span>&nbsp;
                            <IoPerson />
                          </h4>
                          <h4
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "59px",
                            }}
                          >
                            <span>4</span>&nbsp;
                            <IoPerson />
                          </h4>
                          <h4 style={{ display: "flex", alignItems: "center" }}>
                            <span>Extra Adult</span>
                          </h4>
                          <h4 style={{ display: "flex", alignItems: "center" }}>
                            <span>Extra Child</span>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="rates">
                      {ratesData &&
                        ratesData.map((data, index) => {
                          if (data === 0) {
                            return (
                              <div className="rates-div" key={index}>
                                <input
                                  value={""}
                                  onChange={(e) =>
                                    handleRates(
                                      e,
                                      index,
                                      "single",
                                      plan.RoomTypeId,
                                      meal.RateplanID
                                    )
                                  }
                                />
                                <input
                                  value={""}
                                  onChange={(e) =>
                                    handleRates(
                                      e,
                                      index,
                                      "double",
                                      plan.RoomTypeId,
                                      meal.RateplanID
                                    )
                                  }
                                />

                                <div
                                  className={`dropdowns ${
                                    activeIndex[meal.RateplanID] ? "active" : ""
                                  }`}
                                >
                                  <input
                                    value={""}
                                    onChange={(e) =>
                                      handleRates(
                                        e,
                                        index,
                                        "triple",
                                        plan.RoomTypeId,
                                        meal.RateplanID
                                      )
                                    }
                                  />
                                  <input
                                    value={""}
                                    onChange={(e) =>
                                      handleRates(
                                        e,
                                        index,
                                        "quad",
                                        plan.RoomTypeId,
                                        meal.RateplanID
                                      )
                                    }
                                  />
                                  <input
                                    value={""}
                                    onChange={(e) =>
                                      handleRates(
                                        e,
                                        index,
                                        "extraAdult",
                                        plan.RoomTypeId,
                                        meal.RateplanID
                                      )
                                    }
                                  />
                                  <input
                                    value={""}
                                    onChange={(e) =>
                                      handleRates(
                                        e,
                                        index,
                                        "extraChild",
                                        plan.RoomTypeId,
                                        meal.RateplanID
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            );
                          }

                          const rateInfo = data.roomType.find(
                            (room) => room.roomTypeId == plan.RoomTypeId
                          );

                          if (rateInfo) {
                            const rate = rateInfo.rateInfo.find(
                              (rate) => rate.ratePlanId == meal.RateplanID
                            );

                            if (rate) {
                              return (
                                <div className="rates-div" key={index}>
                                  <input
                                    value={rate.single}
                                    onChange={(e) =>
                                      handleRates(
                                        e,
                                        index,
                                        "single",
                                        plan.RoomTypeId,
                                        meal.RateplanID
                                      )
                                    }
                                  />
                                  <input
                                    value={rate.double}
                                    onChange={(e) =>
                                      handleRates(
                                        e,
                                        index,
                                        "double",
                                        plan.RoomTypeId,
                                        meal.RateplanID
                                      )
                                    }
                                  />

                                  <div
                                    className={`dropdowns ${
                                      activeIndex[meal.RateplanID]
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <input
                                      value={rate.triple}
                                      onChange={(e) =>
                                        handleRates(
                                          e,
                                          index,
                                          "triple",
                                          plan.RoomTypeId,
                                          meal.RateplanID
                                        )
                                      }
                                    />
                                    <input
                                      value={rate.quad}
                                      onChange={(e) =>
                                        handleRates(
                                          e,
                                          index,
                                          "quad",
                                          plan.RoomTypeId,
                                          meal.RateplanID
                                        )
                                      }
                                    />
                                    <input
                                      value={rate.extraAdult}
                                      onChange={(e) =>
                                        handleRates(
                                          e,
                                          index,
                                          "extraAdult",
                                          plan.RoomTypeId,
                                          meal.RateplanID
                                        )
                                      }
                                    />
                                    <input
                                      value={rate.extraChild}
                                      onChange={(e) =>
                                        handleRates(
                                          e,
                                          index,
                                          "extraChild",
                                          plan.RoomTypeId,
                                          meal.RateplanID
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            }
                          }
                          return (
                            <div className="rates-div" key={index}>
                              <input
                                value={""}
                                onChange={(e) =>
                                  handleRates(
                                    e,
                                    index,
                                    "single",
                                    plan.RoomTypeId,
                                    meal.RateplanID
                                  )
                                }
                              />
                              <input
                                value={""}
                                onChange={(e) =>
                                  handleRates(
                                    e,
                                    index,
                                    "double",
                                    plan.RoomTypeId,
                                    meal.RateplanID
                                  )
                                }
                              />

                              <div
                                className={`dropdowns ${
                                  activeIndex[meal.RateplanID] ? "active" : ""
                                }`}
                              >
                                <input
                                  value={""}
                                  onChange={(e) =>
                                    handleRates(
                                      e,
                                      index,
                                      "triple",
                                      plan.RoomTypeId,
                                      meal.RateplanID
                                    )
                                  }
                                />
                                <input
                                  value={""}
                                  onChange={(e) =>
                                    handleRates(
                                      e,
                                      index,
                                      "quad",
                                      plan.RoomTypeId,
                                      meal.RateplanID
                                    )
                                  }
                                />

                                <input
                                  value={""}
                                  onChange={(e) =>
                                    handleRates(
                                      e,
                                      index,
                                      "extraAdult",
                                      plan.RoomTypeId,
                                      meal.RateplanID
                                    )
                                  }
                                />
                                <input
                                  value={""}
                                  onChange={(e) =>
                                    handleRates(
                                      e,
                                      index,
                                      "extraChild",
                                      plan.RoomTypeId,
                                      meal.RateplanID
                                    )
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <hr />
                </div>
              ))}
            </div>
          ))}

        <div className="w-full bg-white  fixed bottom-0 left-0 py-2 flex justify-end mt-8 px-8 text-white">
          <button
            className="rounded-lg text-center p-3 bg-[#370D6F]"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </section>
    </div>
  );
};

export default RatesAndInventory;
