import React, { useState } from "react";
import styles from "./policies.module.css";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import CancelIcon from "@material-ui/icons/Cancel";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
const Policies = () => {
  const [state, setState] = useState(0);
  return (
    <div>
      <div className={styles.canc}>
        <h1>Cancellation Policies</h1>
        {state == 1 &&  (
          <ExpandLessRoundedIcon
            style={{ cursor: "pointer" }}
            onClick={() => setState(0)}
          />
        )} {state == 0 && (
          <ExpandMoreRoundedIcon
            style={{ cursor: "pointer" }}
            onClick={() => setState(1)}
          />
        )}
      </div>
      {state === 1 && (
        <div>
          <div className={styles.freecanc}>
            <h1>Free Cancellation Rules</h1>
            <div className={styles.tablex}>
              <table>
                <thead>
                  <th>Book Date From</th>
                  <th>Book Date Until</th>
                  <th>Associated With</th>
                  <th>Cancellation Policy</th>
                  <th></th>
                </thead>
                <tbody>
                  <th>03 Feb, 2023</th>
                  <th>27 Feb, 2023</th>
                  <th>Hotel</th>
                  <th>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </th>
                  <th>
                    <CancelIcon />
                  </th>
                </tbody>
              </table>
            </div>
          </div>
          <div className={styles.freecanc}>
            <h1>Existing Cancellation Rules</h1>
            <div className={styles.tablex}>
              <table>
                <thead>
                  <th>Book Date From</th>
                  <th>Book Date Until</th>
                  <th>Associated With</th>
                  <th>Cancellation Policy</th>
                  <th></th>
                </thead>
                <tbody>
                  <th>03 Feb, 2023</th>
                  <th>27 Feb, 2023</th>
                  <th>Hotel</th>
                  <th>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </th>
                  <th>
                    <CancelIcon />
                  </th>
                </tbody>
              </table>
            </div>
          </div>
          {/* <div className = {styles.exexcanc}></div> */}
        </div>
      )}
      <div className={styles.canc} style ={{marginTop : "2rem"}}>
        <h1>Property Rules</h1>
        <ExpandLessRoundedIcon />
      </div>
      <div className={styles.prcrole} >
        <div>
          <h1>Guest Profile</h1>    
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt rem
            quaerat dolor sequi aspernatur? Nulla, nisi dolore quae harum fuga
            ullam nostrum? Dolores cum optio reiciendis veniam voluptas quod,
            dolore perspiciatis magnam, obcaecati magni cupiditate, tempore
            delectus aspernatur veritatis? Ut dolorem neque repudiandae
            voluptate itaque sapiente tenetur, quam nisi ducimus, modi,
            voluptatibus adipisci? Autem dolores voluptate quia quis maxime fuga
            temporibus facilis incidunt, assumenda non quos neque vel
            consectetur recusandae voluptatem, voluptatibus sequi aspernatur nam
            odio! Porro aspernatur saepe odio optio placeat cupiditate illo
            laborum facilis quaerat, molestiae maiores nisi eum corrupti
            expedita excepturi recusandae similique voluptate eveniet ut.
            Provident.
          </p>
        </div>
        <div>
          <h1>Guest Profile</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt rem
            quaerat dolor sequi aspernatur? Nulla, nisi dolore quae harum fuga
            ullam nostrum? Dolores cum optio reiciendis veniam voluptas quod,
            dolore perspiciatis magnam, obcaecati magni cupiditate, tempore
            delectus aspernatur veritatis? Ut dolorem neque repudiandae
            voluptate itaque sapiente tenetur, quam nisi ducimus, modi,
            voluptatibus adipisci? Autem dolores voluptate quia quis maxime fuga
            temporibus facilis incidunt, assumenda non quos neque vel
            consectetur recusandae voluptatem, voluptatibus sequi aspernatur nam
            odio! Porro aspernatur saepe odio optio placeat cupiditate illo
            laborum facilis quaerat, molestiae maiores nisi eum corrupti
            expedita excepturi recusandae similique voluptate eveniet ut.
            Provident.
          </p>
        </div>
        <div>
          <h1>Guest Profile</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt rem
            quaerat dolor sequi aspernatur? Nulla, nisi dolore quae harum fuga
            ullam nostrum? Dolores cum optio reiciendis veniam voluptas quod,
            dolore perspiciatis magnam, obcaecati magni cupiditate, tempore
            delectus aspernatur veritatis? Ut dolorem neque repudiandae
            voluptate itaque sapiente tenetur, quam nisi ducimus, modi,
            voluptatibus adipisci? Autem dolores voluptate quia quis maxime fuga
            temporibus facilis incidunt, assumenda non quos neque vel
            consectetur recusandae voluptatem, voluptatibus sequi aspernatur nam
            odio! Porro aspernatur saepe odio optio placeat cupiditate illo
            laborum facilis quaerat, molestiae maiores nisi eum corrupti
            expedita excepturi recusandae similique voluptate eveniet ut.
            Provident.
          </p>
        </div>
        <div>
          <h1>Guest Profile</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt rem
            quaerat dolor sequi aspernatur? Nulla, nisi dolore quae harum fuga
            ullam nostrum? Dolores cum optio reiciendis veniam voluptas quod,
            dolore perspiciatis magnam, obcaecati magni cupiditate, tempore
            delectus aspernatur veritatis? Ut dolorem neque repudiandae
            voluptate itaque sapiente tenetur, quam nisi ducimus, modi,
            voluptatibus adipisci? Autem dolores voluptate quia quis maxime fuga
            temporibus facilis incidunt, assumenda non quos neque vel
            consectetur recusandae voluptatem, voluptatibus sequi aspernatur nam
            odio! Porro aspernatur saepe odio optio placeat cupiditate illo
            laborum facilis quaerat, molestiae maiores nisi eum corrupti
            expedita excepturi recusandae similique voluptate eveniet ut.
            Provident.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Policies;
