import React from "react"
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import NavbarSupport from '../../Components/Reports/NavbarSupport';
import Active from "./active/Active"
import Closed from "./closed/Closed"
const Support = ({setCurrentTabIndex})=>{
    React.useEffect(()=>{
        setCurrentTabIndex(6)
    },[]);
    const path = useLocation().pathname;
    return(
       <div>
        <h1 className = "mb-2 text-center font-bold text-indigo-900 text-4xl tracking-wide ">How Can We Help You ?</h1>
        <p className="text-center">Please enter the details of your request. A member of our support staff will respond as soon as possible.</p>
        <hr className="my-8" />
        <div className = "mt-4"><NavbarSupport/></div>
        {path.includes("/Support/active") && <Active />}
        {path.includes("/Support/closed") && <Closed />}

       </div>
    )
}

export default Support