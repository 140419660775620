import React, { useState } from "react";
import moment from "moment";
import {
  BsDot,
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
} from "react-icons/bs";

const Calender = () => {
  const [currentDate, setCurrentDate] = useState(moment());
  return (
    <div className="table">
      <table>
        <thead>
          <tr>
            <th>Rooms</th>
            <th>
              <span>
                {moment(currentDate).format("ddd")}
                <BsDot size={30} />
                {moment(currentDate).format("DD/MM")}
              </span>
            </th>
            <th>
              <span>
                {moment(currentDate).add(1, "days").format("ddd")}
                <BsDot size={30} />
                {moment(currentDate).add(1, "days").format("DD/MM")}
              </span>
            </th>
            <th>
              <span>
                {moment(currentDate).add(2, "days").format("ddd")}
                <BsDot size={30} />
                {moment(currentDate).add(2, "days").format("DD/MM")}
              </span>
            </th>
            <th>
              <span>
                {moment(currentDate).add(3, "days").format("ddd")}
                <BsDot size={30} />
                {moment(currentDate).add(3, "days").format("DD/MM")}
              </span>
            </th>
            <th>
              <span>
                {moment(currentDate).add(4, "days").format("ddd")}
                <BsDot size={30} />
                {moment(currentDate).add(4, "days").format("DD/MM")}
              </span>
            </th>
            <th>
              <span>
                {moment(currentDate).add(5, "days").format("ddd")}
                <BsDot size={30} />
                {moment(currentDate).add(5, "days").format("DD/MM")}
              </span>
            </th>
            <th>
              <span>
                {moment(currentDate).add(6, "days").format("ddd")}
                <BsDot size={30} />
                {moment(currentDate).add(6, "days").format("DD/MM")}
              </span>
            </th>
          </tr>
        </thead>
      </table>
    </div>
  );
};
export default Calender;
