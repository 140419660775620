import React from 'react';

const Outlinedbluebutton = (props) => {
    return (
        <button type="button"
                onClick={props.download}
                className="inline-block w-28 px-6 py-2 border-2 font-bold border-sky-600 text-sky-500 text-xs
                             leading-tight uppercase rounded-md hover:text-sky-700 hover:bg-opacity-5 focus:outline-none
                              focus:ring-0 transition duration-150 ease-in-out">
            Export
        </button>
    )
};

export default Outlinedbluebutton;
