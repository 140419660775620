import React, { useState, useEffect } from "react";
import styles from "./closed.module.css";
import { getAllsupport } from "../../../core/Services/Data/support";
import moment from "moment";

const Closed = () => {
  const [data, setData] = useState();
  useEffect(() => {
    getAllsupport().then((res) => {
      setData(res.data);
    });
  }, []);
  return (
    <div>
      <div>
        {data &&
          data
            .filter((e) => e.status == "closed")
            .map((el) => {
              return (
                <div className={styles.tickets}>
                  <div>
                    <h1>{el.title}</h1>
                    <h2>{"Ticket No - " + el.ticketNum}</h2>
                    <h2>{moment(el.createdAt).format("DD-MMM-YYYY")}</h2>
                  </div>
                  <p>{el.description}</p>
                  {/* <p style={{color : "green"}}>cdnjofdvio</p> */}
                </div>
              );
            })}
      </div>
    </div>
  );
};
export default Closed;
