import httpService from "../HttpService/httpService";
import { toast } from "react-toastify";

export async function addSupport(data){
    // console.log(data,"frm")
    try {
        const support = await httpService.post("/support",data)
        return support
    } catch (err) {
        if (err.response && err.response.status === 400) {
            toast.error(err.response.data);
          }
    }
}
export async function getAllsupport(){
    try {
        const support = await httpService.get("/getUsersupport")
        return support  
    } catch (err) {
        if (err.response && err.response.status === 400) {
            toast.error(err.response.data);
          }
    }
}