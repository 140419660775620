import moment from "moment";
import React from "react";
import { useCallback } from "react";
import { DateRangePicker } from "react-date-range";
import { useState } from "react";
import { BsCalendarDate } from "react-icons/bs";
import Toggledays from "../../../../shared/components/Toggledays/Toggledays";
import styles from "./restriction.module.css";
import Checkbox from "@mui/material/Checkbox";
import { AiOutlineCaretDown } from "react-icons/ai";

const Restrictions = () => {
  const [openDateDropdown, setOpenDateDropdown] = useState(false);
  const [openRoomTypeDropdown, setOpenRoomTypeDropdown] = useState(false);
  const [openReportTypeDropdown, setOpenReportTypeDropdown] = useState(false);
  const [workingDays, setWorkingDays] = useState([]);
  const [workingDaysError, setWorkingDaysError] = useState(false);
  const [filters, setFilters] = useState({
    startDate: new Date(),
    endDate: new Date(),
    roomType: 1,
    reportType: 1,
  });
  const [text, setText] = useState([
    { name: "At Midnight" },
    { name: "Before Midnight" },
    { name: "After Midnight" },
  ]);
  const dateRangeHandler = useCallback(
    (event) => {
      setFilters({
        ...filters,
        startDate: event.selection.startDate,
        endDate: event.selection.endDate,
      });
      setOpenDateDropdown(false);
    },
    [filters]
  );
  const getWorkingDays = (days) => {
    setWorkingDays((prevState) => {
      if (days.length === 0) {
        setWorkingDaysError((prevState) => {
          return true;
        });
      } else {
        setWorkingDaysError((prevState) => {
          return false;
        });
      }
      return days;
    });
  };

  const dateDropdownHandler = useCallback(() => {
    setOpenDateDropdown(!openDateDropdown);
    setOpenRoomTypeDropdown(false);
    setOpenReportTypeDropdown(false);
  }, [openDateDropdown]);

  const roomTypeDropdownHandler = useCallback(() => {
    setOpenRoomTypeDropdown(!openRoomTypeDropdown);
    setOpenDateDropdown(false);
    setOpenReportTypeDropdown(false);
  }, [openRoomTypeDropdown]);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <>
      <section className="card br-8 filters-section">
        <div className="filters">
          <div className="date filter ">
            <label>Select Date*</label>
            <div className={openDateDropdown ? "dropdown active" : "dropdown"}>
              <p>
                <span>
                  {moment(filters.startDate).format("DD MMMM Y")} -{" "}
                  {moment(filters.endDate).format("DD MMMM Y")}
                </span>
                <button onClick={dateDropdownHandler}>
                  <BsCalendarDate size={16} />
                </button>
              </p>
              <DateRangePicker
                className="date-range"
                ranges={[{ ...filters, key: "selection" }]}
                onChange={dateRangeHandler}
                showMonthAndYearPickers={false}
                minDate={new Date()}
              />
            </div>
          </div>

          <div className="w-[50%]">
            {/* <Toggledays handleWorkingDays={getWorkingDays} workingDays={workingDays}/> */}
            {workingDays && (
              <Toggledays
                handleWorkingDays={getWorkingDays}
                workingDays={workingDays}
              />
            )}
          </div>
        </div>
        <div className={styles.updatedrestrictions}>
          <h1>UPDATE RESTRICTIONS FOR ENTIRE HOTEL</h1>
          <span>
            <p>
              Please note, any restrictions you apply from here will be applied
              on all rooms and rates.
            </p>
          </span>
          <div className={styles.blockbox}>
            <div>
              <div className={styles.inner_bx}>
                <Checkbox {...label} />
                <p>Block Inventory</p>
              </div>
              <div className={styles.inner_bx}>
                <Checkbox {...label} />
                <p>Unblock Inventory</p>
              </div>
            </div>
            <div>
              <div className={styles.inner_bx}>
                <Checkbox {...label} />
                <p>Close to Arrival (CTA)</p>
              </div>
              <div className={styles.inner_bx}>
                <Checkbox {...label} />
                <p>Inactivate CTA</p>
              </div>
            </div>
            <div>
              <div className={styles.inner_bx}>
                <Checkbox {...label} />
                <p>Close to Departure</p>
              </div>
              <div className={styles.inner_bx}>
                <Checkbox {...label} />
                <p>Inactivate CTD</p>
              </div>
            </div>
          </div>
          <div class="filters" >
            <div className="filter ">
              <label>Set Minimum length of stay</label>
              <div className="dropdown"><input style={{width : '100%'}} type = "number" min = "0"/> </div>
            </div>
              <div className="filter ">
                <label>Set Cutoff</label>
                <div
                  className={
                    openRoomTypeDropdown ? "dropdown active" : "dropdown"
                  }
                >
                  <p>
                    <span></span>
                    <button onClick={roomTypeDropdownHandler}>
                      <AiOutlineCaretDown size={16} />
                    </button>
                  </p>
                  <ul>
                    {text &&
                      text.map((room) => (
                        <li key={room.id}>
                          <button
                            className={
                              room.id === filters.roomType ? "active" : ""
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              // const state = { ...states };
                              // state.roomType = room;
                              // console.log(states);
                              // setStates({ ...state });
                              // setOpenRoomTypeDropdown(false);
                            }}
                          >
                            <div>
                              <img
                                alt=""
                                src="/Assets/Images/Reports/room.png"
                              />
                            </div>
                            <span>{room.name}</span>
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="filter ">
                <label style={{visibility : "hidden"}}>s</label>
                <div
                  className={
                    openRoomTypeDropdown ? "dropdown active" : "dropdown"
                  }
                >
                  <p>
                    <span></span>
                    <button onClick={roomTypeDropdownHandler}>
                      <AiOutlineCaretDown size={16} />
                    </button>
                  </p>
                  <ul>
                    {text &&
                      text.map((room) => (
                        <li key={room.id}>
                          <button
                            className={
                              room.id === filters.roomType ? "active" : ""
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              // const state = { ...states };
                              // state.roomType = room;
                              // console.log(states);
                              // setStates({ ...state });
                              // setOpenRoomTypeDropdown(false);
                            }}
                          >
                            <div>
                              <img
                                alt=""
                                src="/Assets/Images/Reports/room.png"
                              />
                            </div>
                            <span>{room.name}</span>
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
          </div>
        </div>
      </section>
      {"" + filters.startDate !== "" + filters.endDate && (
        <section className="card br-8"></section>
      )}
    </>
  );
};
export default Restrictions;
