import React from 'react';
import Tabs from './Tabs';
import Bookings from './Bookings';
import AllBookings from './AllBookings';
import Charts from "./Chart/Chart"
import './dashboard.css';

const Dashboard = ({setCurrentTabIndex, user}) => {
  React.useEffect(()=>{
    setCurrentTabIndex(0)
  },[])
  return (
    <div className='wrapper dashboard'>
      <Tabs user = {user}/>
      <Charts user ={user} />
      <Bookings user ={user} />
      <AllBookings user= {user} />
    </div>
  )
}

export default Dashboard