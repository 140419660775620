import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";

const NavbarBook = () => {
  const path = useLocation().pathname;
  return (
    <div className="card rounded-r-none br-8 reports-navbar col-span-5">
      <div className="card-content">
        <Link
          to="/rates-inventory/bukUpdate"
          className={path.includes("bukUpdate") ? "active" : ""}
        >
          Bulk Update Inventory
        </Link>
        <Link
          to="/rates-inventory/bulkUpdateRates"
          className={path.includes("bulkUpdateRates") ? "active" : ""}
        >
          Bulk Update Rate
        </Link>
        {/* <Link
          to="/rates-inventory/restrictions"
          className={path.includes("restrictions") ? "active" : ""} 
        >
         Bulk Update Restrictions
        </Link> */}
        
      </div>
    </div>
  );
};

export default NavbarBook;
