import React,{useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './otaVerificationSuccess.css';

const OtaVerficationSuccess = () => {
  const navigate = useNavigate();
  const [time, setTime] = useState(15);

  useEffect(() => {
    const interval = setInterval(() => {
      if(time === 0) {
        navigate('/dashboard');
      } else {
        setTime(time - 1);
      }
      clearInterval(interval);
    }, 1000)
  }, [time, navigate])

  return (
    <div className='auth-container onboarding-grids ota-success'>
      <section>
        <img alt='' src='/Assets/Images/Onboarding/ota.png' />
      </section>
      <section className='d-flex align-items-center justify-content-center flex-column'>
        <div className='image-wrapper'>
            <img alt='' src = '/Assets/Images/Onboarding/verified-icon.png'/>
        </div>
        <h2>OTAs Verified!</h2>
        <p>You will be redirected to login page in 00:{time < 10 ? `0${time}` : `${time}`} seconds!</p>
      </section>
    </div>
  )
}

export default OtaVerficationSuccess